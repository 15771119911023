const LANG_EN_TRANS = {
  SelectTheVoiceYouWantToUpdateWith: "Select the voice you want to update with",
  Phonetic: "Phonetic",
  OpenFile: "Open file",
  NewProject: "New project",
  SaveTheProject: "Save the project",
  AudioSettings: "Audio settings",
  Phonetics: "Phonetics",
  Download: "Download",
  SubtitlesVtt: "Subtitles (vtt)",
  SubtitlesSrt: "Subtitles (srt)",
  TranslateAllInANewLanguage: "Translate all texts in a new language",
  UpdateAllVoices: "Update all voices",
  LogIn: "Log In",
  LogOut: "Log Out",
  CharsAvailable: "Available chars:",
  AboutChars: "About",
  CharsWillBeDeductedAfterTheVoiceoverHasBeenGenerated: "chars will be deducted after the voiceover has been generated",
  CharsWillBeDeductedAfterTheTranslationHasBeenGenerated: "chars will be deducted after the translation has been generated",
  SelectTheLanguageInWhichYouWantToPerformTheTranslation: "Select the language in which you want to perform the translation",
  SaveTheProjectBeforePerformingTheTranslationYouCanThenSaveTheNewProjectForTheNewLanguage: "Save the project before performing the translation, you can then save the new project for the new language",
  SaveTheProjectBeforeGeneratingTheVoices: "Save the project before generating the voices",
  GENERATEALLVOICES: "GENERATE ALL VOICES",
  TRANSLATEALLTEXTS: "TRANSLATE ALL TEXTS",
  GenerateTheVoiceover: "Generate the voiceover",
  SAVEBEFORELEAVING: "SAVE BEFORE LEAVING",
  SAVE: "SAVE",
  NO: "NO",
  SaveTheProjectBeforeLeavingThePageSoAsNotToLoseTheModifications: "Save the project before leaving the page so as not to lose the modifications",
  TheProjectWillBeSavedLocallyToTheDestinationOfYourDownloads: "The project will be saved locally to the destination of your downloads",
  ConnectionFailedCheckYourCredentials: "Connection failed. Check your credentials.",
  AnEmailHasBeenSentToYouWithYourPassword: "An email has been sent to you with your password.",
  ThisEmailHasNotBeenIdentified: "This email has not been identified.",
  LOGIN: "LOG IN",
  Email: "E-mail",
  Password: "Password",
  ForgotYourPassword: "Forgot your password",
  RememberMyLogin: "Remember my password",
  TotalChars: "Total chars of the project: ",
  EditTextEnterText: "Enter the text for this block...",
  HeightMustBeGreaterThan: "Height must be greater than 600px",
  SafariIncompatible: "SAFARI is not compatible with this web application !",
};

const LANG_FR_TRANS = {
  SelectTheVoiceYouWantToUpdateWith: "Sélectionnez la voix avec laquelle vous souhaitez faire la mise à jour",
  Phonetic: "Phonétique",
  OpenFile: "Ouvrir un fichier",
  NewProject: "Nouveau projet",
  SaveTheProject: "Enregistrer le projet",
  AudioSettings: "Audio configurations",
  Phonetics: "Phonétiques",
  Download: "Téléchargements",
  SubtitlesVtt: "Sous-titres (vtt)",
  SubtitlesSrt: "Sous-titres (srt)",
  TranslateAllInANewLanguage: "Traduire tous les textes dans une nouvelle langue",
  UpdateAllVoices: "Mettre à jour toutes les voix",
  LogIn: "Se connecter",
  LogOut: "Se déconnecter",
  CharsAvailable: "Caractères disponibles:",
  AboutChars: "Environ",
  CharsWillBeDeductedAfterTheVoiceoverHasBeenGenerated: "caractères seront déduits une fois la voix off générée",
  CharsWillBeDeductedAfterTheTranslationHasBeenGenerated: "caractères seront déduits une fois la traduction générée",
  SelectTheLanguageInWhichYouWantToPerformTheTranslation: "Sélectionnez la langue dans laquelle vous souhaitez effectuer la traduction",
  SaveTheProjectBeforePerformingTheTranslationYouCanThenSaveTheNewProjectForTheNewLanguage: "Enregistrez le projet avant d'effectuer la traduction, vous pourrez ensuite enregistrer le nouveau projet pour la nouvelle langue",
  SaveTheProjectBeforeGeneratingTheVoices: "Enregistrez le projet avant de générer les voix",
  GENERATEALLVOICES: "GÉNÉRER TOUTES LES VOIX",
  TRANSLATEALLTEXTS: "TRADUIRE TOUS LES TEXTES",
  GenerateTheVoiceover: "Générer la voix off",
  SAVEBEFORELEAVING: "ENREGISTRER AVANT DE QUITTER",
  SAVE: "ENREGISTRER",
  NO: "NON",
  SaveTheProjectBeforeLeavingThePageSoAsNotToLoseTheModifications: "Enregistrez le projet avant de quitter la page pour ne pas perdre les modifications",
  TheProjectWillBeSavedLocallyToTheDestinationOfYourDownloads: "Le projet sera enregistré localement vers la destination de vos téléchargements",
  ConnectionFailedCheckYourCredentials: "La connexion a échoué. Vérifiez vos informations d'identification.",
  AnEmailHasBeenSentToYouWithYourPassword: "Un email vous a été envoyé avec votre mot de passe.",
  ThisEmailHasNotBeenIdentified: "Cet email n'a pas été identifié.",
  LOGIN: "SE CONNECTER",
  Email: "E-mail",
  Password: "Mot de passe",
  ForgotYourPassword: "Mot de passe oublié",
  RememberMyLogin: "Mémoriser mon mot de passe",
  TotalChars: "Nombre total de caractères dans le projet: ",
  EditTextEnterText: "Saisissez le texte pour ce bloc...",
  HeightMustBeGreaterThan: "La hauteur doit être supérieure à 600px",
  SafariIncompatible: "SAFARI n'est pas compatible avec cet application web !",
};

const LANGUAGES = {
  en: LANG_EN_TRANS,
  fr: LANG_FR_TRANS,
};

function translate(key) {
  let translation = key;

  let currentLang = navigator.language.split("-")[0] || "en";
  if (localStorage) currentLang = localStorage["language"] || currentLang;

  if (LANGUAGES[currentLang] && LANGUAGES[currentLang][key]) translation = LANGUAGES[currentLang][key];

  return translation;
}

function getCurrentLang() {
  let currentLang = navigator.language.split("-")[0] || "en";
  if (localStorage) currentLang = localStorage["language"] || currentLang;

  return currentLang;
}

export { translate, getCurrentLang };
