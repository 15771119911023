import React, { useState, useEffect, useRef } from "react";
import { generateGuid } from "../js/Project";

function drawCanvas(canvas, props) {
  const context = canvas.getContext("2d");

  let pixelPerSecond = canvas.width / (props.block.time_end - props.block.time_start);
  context.clearRect(0, 0, canvas.width, canvas.height);

  if (props.block.waveform) context.drawImage(props.block.waveform, 0, 0, props.block.waveform.width, props.block.waveform.height, 0, 0, props.block.audioBuffer.duration * pixelPerSecond, canvas.height);
}

const Block = (props) => {
  const blockRef = useRef();
  const [toggleIcons, setToggleIcons] = useState(false);
  const canvasRef = useRef(null);
  const [mouseState] = useState({
    mouseDown: -1,
    mouseMoved: false,
    domouseDownOffsetX: 0,
    mouseDownStart: 0,
    mouseDownEnd: 0,
  });

  useEffect(() => {
    drawCanvas(canvasRef.current, props);
    props.block.blockRef = blockRef;
  }, [props]);

  const onMouseMove = (e) => {
    if (mouseState.mouseDown >= 0) {
      let delta = (e.clientX - mouseState.mouseDownOffsetX) / props.pixelPerSecond;

      if (mouseState.mouseDown === 0) {
        props.block.time_start = mouseState.mouseDownStart + delta;
        if (props.block.time_start > props.block.time_end - 0.5) props.block.time_start = props.block.time_end - 0.5;
      } else if (mouseState.mouseDown === 1) {
        props.block.time_start = mouseState.mouseDownStart + delta;
        props.block.time_end = mouseState.mouseDownEnd + delta;
      } else {
        props.block.time_end = mouseState.mouseDownEnd + delta;
        if (props.block.time_end < props.block.time_start + 0.51) props.block.time_end = props.block.time_start + 0.5;
      }

      blockRef.current.style.left = props.block.time_start * props.pixelPerSecond + "px";
      props.move(e.ctrlKey);
    }
  };

  const onMouseUp = (e) => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    mouseState.mouseDown = -1;
    blockRef.current.style.zIndex = 1000;

    if (mouseState.mouseDownStart !== props.block.time_start || mouseState.mouseDownEnd !== props.block.time_end) {
      if (!props.block.id) props.block.id = generateGuid();
      props.update(e.ctrlKey);
    }
  };

  const onTouchMove = (e) => {
    if (mouseState.mouseDown >= 0 && e.touches.length > 0) {
      let delta = (e.touches[0].clientX - mouseState.mouseDownOffsetX) / props.pixelPerSecond;

      if (mouseState.mouseDown === 0) {
        props.block.time_start = mouseState.mouseDownStart + delta;
        if (props.block.time_start > props.block.time_end - 0.5) props.block.time_start = props.block.time_end - 0.5;
      } else if (mouseState.mouseDown === 1) {
        props.block.time_start = mouseState.mouseDownStart + delta;
        props.block.time_end = mouseState.mouseDownEnd + delta;
      } else {
        props.block.time_end = mouseState.mouseDownEnd + delta;
        if (props.block.time_end < props.block.time_start + 0.51) props.block.time_end = props.block.time_start + 0.5;
      }

      blockRef.current.style.left = props.block.time_start * props.pixelPerSecond + "px";
      props.move(e.ctrlKey);
    }
  };

  const onTouchEnd = (e) => {
    document.removeEventListener("touchmove", onMouseMove);
    document.removeEventListener("touchend", onMouseUp);
    mouseState.mouseDown = -1;
    blockRef.current.style.zIndex = 1000;

    if (mouseState.mouseDownStart !== props.block.time_start || mouseState.mouseDownEnd !== props.block.time_end) {
      if (!props.block.id) props.block.id = generateGuid();
      props.update(e.ctrlKey);
    }
  };

  return (
    <div
      ref={blockRef}
      className="block"
      onMouseEnter={() => setToggleIcons(true)}
      onMouseLeave={() => setToggleIcons(false)}
      onMouseDown={(e) => {
        mouseState.mouseDown = 1;
        mouseState.mouseDownOffsetX = e.clientX;
        mouseState.mouseDownStart = props.block.time_start;
        mouseState.mouseDownEnd = props.block.time_end;
        blockRef.current.style.zIndex = 100000000000;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      }}
      onTouchStart={(e) => {
        if (e.touches.length > 0) {
          mouseState.mouseDown = 1;
          mouseState.mouseDownOffsetX = e.touches[0].clientX;
          mouseState.mouseDownStart = props.block.time_start;
          mouseState.mouseDownEnd = props.block.time_end;
          blockRef.current.style.zIndex = 100000000000;
          document.addEventListener("touchmove", onTouchMove);
          document.addEventListener("touchend", onTouchEnd);
        }
      }}
      style={{
        width: (props.block.time_end - props.block.time_start) * props.pixelPerSecond + "px",
        left: props.block.time_start * props.pixelPerSecond + "px",
        touchAction: "none",
      }}
    >
      <div className={toggleIcons ? "disabled" : "none"}>
        <div className="voice-name">{props.block.voice_public_name}</div>
        <div className="message">{props.block.text}</div>
      </div>

      <div className={toggleIcons ? "none" : "hidden"}>
        <img
          className="btn-icon"
          src="./img/edit_white.png"
          alt="edit"
          style={{ left: "2px", top: "2px" }}
          onClick={() => {
            props.onEdit();
          }}
        />
        <img
          className="btn-icon"
          src="./img/delete_white.png"
          alt="delete"
          style={{ left: "2px", top: "30px" }}
          onClick={() => {
            props.onDelete();
          }}
        />
      </div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Block;
