import React, { useEffect, useState } from "react";

const SelectLanguage = ({
  languages,
  languageSelected,
  languageSelectedChange,
}) => {
  const [languageOpened, setLanguageOpened] = useState(false);

  useEffect(() => {}, [languageSelected]);

  if (!languages) return null;

  return (
    <div className="selectlanguage">
      <div>
        <button
          className="button"
          style={{ width: "200px", margin: "auto" }}
          onClick={() => {
            setLanguageOpened(!languageOpened);
          }}
        >
          <img
            className={languageSelected ? "btn-icon" : "hidden"}
            src={
              languageSelected
                ? "./img/flag_" + languageSelected.flag + ".png"
                : ""
            }
            alt=""
          ></img>

          {languageSelected ? languageSelected?.language : "Language"}
        </button>

        <div
          className={languageOpened ? "dropdown dropdown-content" : "hidden"}
        >
          {languages.map((lang, i) => (
            <div
              key={i}
              onClick={(e) => {
                languageSelected = lang;
                setLanguageOpened(false);
                languageSelectedChange(lang);
              }}
            >
              <li>
                <img
                  className="btn-icon"
                  src={"./img/flag_" + lang.flag + ".png"}
                  alt=""
                ></img>
                {lang.language}
              </li>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectLanguage;
