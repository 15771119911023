import React, { useEffect, useRef, useState } from "react";
import Player from "../components/Player";
import SelectVoice from "../components/SelectVoice";

const EditPhonetic = ({ isOpen, token, phonetics, closeHandler, updateSpinner, block }) => {
  const [voiceSelected, setVoiceSelected] = useState({
    language_name: block && block.hasOwnProperty("language") ? block.language.language : "Language",
    voice_public_name: block ? block.voice_public_name : "",
    flag: block ? block.language.flag : "",
  });
  const [selectVoiceOpened, setSelectVoiceOpened] = useState(false);

  const phonemeVowelRef = useRef();
  const phonemeConsonantRef = useRef();
  const phonemeSymbolRef = useRef();
  const textareaPhoneticApi = useRef();
  const playerRef = useRef(null);
  const [ipa_vowels, setIpa_vowels] = useState([]);
  const [ipa_consonants, setIpa_consonants] = useState([]);
  const [ipa_additionalSymbols, setIpa_additionalSymbols] = useState([]);

  const [mounted, setMounted] = useState(false);

  const [textAreaPhoneticText, setTextAreaPhoneticText] = useState("");
  const [textAreaPhoneticTranscription, setTextAreaPhoneticTranscription] = useState("");
  const [textAreaPhoneticIPA, setTextAreaPhoneticIPA] = useState("");

  useEffect(() => {
    // if (
    //   textAreaPhoneticText &&
    //   textAreaPhoneticTranscription &&
    //   textAreaPhoneticIPA
    // ) {
    let text = textAreaPhoneticText.trim();
    let textToConvert = textAreaPhoneticText.trim();
    if (textToConvert !== "") {
      text = textAreaPhoneticTranscription.trim();
      if (textAreaPhoneticIPA.trim() !== "") text = '<phoneme alphabet="ipa" ph="' + textAreaPhoneticIPA.trim() + '"> ' + textToConvert + " </phoneme>";
    }

    if (text !== "" && isOpen.isOpen) isOpen.phonetic.text = text;

    //}
  }, [isOpen, textAreaPhoneticText, textAreaPhoneticTranscription, textAreaPhoneticIPA]);

  useEffect(() => {
    if (isOpen.phonetic) {
      setTextAreaPhoneticText(isOpen.phonetic.phoneticText);
      setTextAreaPhoneticTranscription(isOpen.phonetic.phoneticTranscription);
      setTextAreaPhoneticIPA(isOpen.phonetic.phoneticIPA);
      setMounted(false);
    }
  }, [isOpen]);

  const ipa_languages = [
    { code: "fr-FR", language: "FR - French" },
    { code: "en-GB", language: "UK - English" },
    { code: "en-US", language: "US - English" },
    { code: "fr-CA", language: "CA - French" },
    { code: "nl-NL", language: "NL - Dutch" },
    { code: "hi-IN", language: "IN - Hindi" },
    { code: "cmn-CN", language: "CN - Chinese" },
    { code: "ja-JP", language: "JP - Japanese" },
    { code: "ru-RU", language: "RU - Russian" },
    { code: "de-DE", language: "DE - German" },
    { code: "it-IT", language: "IT - Italian" },
    { code: "es-ES", language: "ES - Spanish" },
  ];

  useEffect(() => {
    createPhoneticTables();
  }, []);

  useEffect(() => {}, [isOpen.phonetic]);

  useEffect(() => {
    if (!mounted && phonemeVowelRef) {
      updatePhoneticSymboles();
      setMounted(true);
    }
  }, [mounted, phonemeVowelRef]);

  if (!isOpen.isOpen) return null;

  function createPhoneticTables() {
    setIpa_vowels([]);
    setIpa_consonants([]);
    setIpa_additionalSymbols([]);

    //fr-FR
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ø", example: ["d", "eu", "x"] },
        { ipa: "œ", example: ["n", "eu", "f"] },
        { ipa: "œ̃", example: ["br", "un", ""] },
        { ipa: "ə", example: ["j", "e", ""] },
        { ipa: "a", example: ["t", "a", "ble"] },
        { ipa: "ɑ̃", example: ["cam", "em", "bert"] },
        { ipa: "e", example: ["march", "é", ""] },
        { ipa: "ɛ", example: ["n", "ei", "ge"] },
        { ipa: "ɛ̃", example: ["sap", "in", ""] },
        { ipa: "i", example: ["m", "i", "lle"] },
        { ipa: "o", example: ["h", "ô", "potal"] },
        { ipa: "ɔ", example: ["h", "o", "mme"] },
        { ipa: "ɔ̃", example: ["b", "on", ""] },
        { ipa: "u", example: ["s", "ou", "s"] },
        { ipa: "y", example: ["d", "u", "r"] },
      ],
    ]);

    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "oire"] },
        { ipa: "d", example: ["ma", "d", "ame"] },
        { ipa: "f", example: ["", "f", "emme"] },
        { ipa: "g", example: ["", "g", "rand"] },
        { ipa: "ɥ", example: ["br", "u", "it"] },
        { ipa: "j", example: ["mei", "ll", "eur"] },
        { ipa: "k", example: ["", "q", "uatre"] },
        { ipa: "l", example: ["ma", "l", "ade"] },
        { ipa: "m", example: ["", "m", "aison"] },
        { ipa: "n", example: ["astro", "n", "ome"] },
        { ipa: "ɲ", example: ["bai", "gn", "er"] },
        { ipa: "ŋ", example: ["parki", "ng", ""] },
        { ipa: "p", example: ["", "p", "omme"] },
        { ipa: "ʁ", example: ["amou", "r", "eux"] },
        { ipa: "s", example: ["", "s", "anté"] },
        { ipa: "ʃ", example: ["", "ch", "at"] },
        { ipa: "t", example: ["", "t", "éléphone"] },
        { ipa: "v", example: ["", "v", "rai"] },
        { ipa: "w", example: ["s", "o", "ir"] },
        { ipa: "z", example: ["rai", "s", "on"] },
        { ipa: "ʒ", example: ["auber", "g", "ine"] },
      ],
    ]);

    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //en-GB
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ə", example: ["", "a", "rena"] },
        { ipa: "əʊ", example: ["g", "oa", "t"] },
        { ipa: "æ", example: ["tr", "a", "p"] },
        { ipa: "aɪ", example: ["pr", "i", "ce"] },
        { ipa: "aʊ", example: ["m", "ou", "th"] },
        { ipa: "ɑː", example: ["f", "a", "ther"] },
        { ipa: "eɪ", example: ["f", "a", "ce"] },
        { ipa: "ɜː", example: ["n", "ur", "se"] },
        { ipa: "ɛ", example: ["dr", "e", "ss"] },
        { ipa: "ɛə", example: ["squ", "are", ""] },
        { ipa: "i:", example: ["fl", "ee", "ce"] },
        { ipa: "ɪ", example: ["k", "i", "t"] },
        { ipa: "ɪə", example: ["n", "ear", ""] },
        { ipa: "ɔː", example: ["th", "ou", "ght"] },
        { ipa: "ɔɪ", example: ["ch", "oi", "ce"] },
        { ipa: "ɒ", example: ["l", "o", "t"] },
        { ipa: "u:", example: ["g", "oo", "se"] },
        { ipa: "ʊ", example: ["f", "oo", "t"] },
        { ipa: "ʊə", example: ["c", "u", "re"] },
        { ipa: "ʌ", example: ["str", "u", "t"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "ed"] },
        { ipa: "d", example: ["", "d", "ig"] },
        { ipa: "d͡ʒ", example: ["", "j", "ump"] },
        { ipa: "ð", example: ["", "th", "en"] },
        { ipa: "f", example: ["", "f", "ive"] },
        { ipa: "g", example: ["", "g", "ame"] },
        { ipa: "h", example: ["", "h", "ouse"] },
        { ipa: "j", example: ["", "y", "es"] },
        { ipa: "k", example: ["", "c", "at"] },
        { ipa: "l", example: ["", "l", "ay"] },
        { ipa: "l̩", example: ["batt", "le", ""] },
        { ipa: "m", example: ["", "m", "ouse"] },
        { ipa: "m̩", example: ["anth", "em", ""] },
        { ipa: "n", example: ["", "n", "ap"] },
        { ipa: "n̩", example: ["butto", "n", ""] },
        { ipa: "ŋ", example: ["thi", "ng", ""] },
        { ipa: "p", example: ["", "p", "in"] },
        { ipa: "ɹ", example: ["", "r", "ed"] },
        { ipa: "s", example: ["", "s", "eem"] },
        { ipa: "ʃ", example: ["", "sh", "ip"] },
        { ipa: "t", example: ["", "t", "ask"] },
        { ipa: "t͡ʃ", example: ["", "ch", "art"] },
        { ipa: "Θ", example: ["", "th", "in"] },
        { ipa: "v", example: ["", "v", "est"] },
        { ipa: "w", example: ["", "w", "est"] },
        { ipa: "z", example: ["", "z", "ero"] },
        { ipa: "ʒ", example: ["vi", "s", "ion"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //en-US
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ə", example: ["", "a", "rena"] },
        { ipa: "ɚ", example: ["read", "er", ""] },
        { ipa: "æ", example: ["tr", "a", "p"] },
        { ipa: "aɪ", example: ["pr", "i", "ce"] },
        { ipa: "aʊ", example: ["m", "ou", "th"] },
        { ipa: "ɑ", example: ["f", "a", "ther"] },
        { ipa: "eɪ", example: ["f", "a", "ce"] },
        { ipa: "ɝ", example: ["n", "ur", "se"] },
        { ipa: "ɛ", example: ["dr", "e", "ss"] },
        { ipa: "i", example: ["fl", "ee", "ce"] },
        { ipa: "ɪ", example: ["k", "i", "t"] },
        { ipa: "oʊ", example: ["g", "oa", "t"] },
        { ipa: "ɔ", example: ["th", "ou", "ght"] },
        { ipa: "ɔɪ", example: ["ch", "oi", "ce"] },
        { ipa: "u", example: ["g", "oo", "se"] },
        { ipa: "ʊ", example: ["f", "oo", "t"] },
        { ipa: "ʌ", example: ["str", "u", "t"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "ed"] },
        { ipa: "d", example: ["", "d", "ig"] },
        { ipa: "d͡ʒ", example: ["", "j", "ump"] },
        { ipa: "ð", example: ["", "th", "en"] },
        { ipa: "f", example: ["", "f", "ive"] },
        { ipa: "ɡ", example: ["", "g", "ame"] },
        { ipa: "h", example: ["", "h", "ouse"] },
        { ipa: "j", example: ["", "y", "es"] },
        { ipa: "k", example: ["", "c", "at"] },
        { ipa: "l", example: ["", "l", "ay"] },
        { ipa: "m", example: ["", "m", "ouse"] },
        { ipa: "n", example: ["", "n", "ap"] },
        { ipa: "ŋ", example: ["thi", "ng", ""] },
        { ipa: "p", example: ["s", "p", "eak"] },
        { ipa: "ɹ", example: ["", "r", "ed"] },
        { ipa: "s", example: ["", "s", "eem"] },
        { ipa: "ʃ", example: ["", "sh", "ip"] },
        { ipa: "t", example: ["", "t", "rap"] },
        { ipa: "t͡ʃ", example: ["", "ch", "art"] },
        { ipa: "θ", example: ["", "th", "in"] },
        { ipa: "v", example: ["", "v", "est"] },
        { ipa: "w", example: ["", "w", "est"] },
        { ipa: "z", example: ["", "z", "ero"] },
        { ipa: "ʒ", example: ["vi", "s", "ion"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //fr-CA
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ø", example: ["d", "eu", "x"] },
        { ipa: "œ", example: ["n", "eu", "f"] },
        { ipa: "œ̃", example: ["br", "un", ""] },
        { ipa: "ə", example: ["j", "e", ""] },
        { ipa: "a", example: ["t", "a", "ble"] },
        { ipa: "ɑ̃", example: ["cam", "em", "bert"] },
        { ipa: "e", example: ["march", "é", ""] },
        { ipa: "ɛ", example: ["n", "ei", "ge"] },
        { ipa: "ɛ̃", example: ["sap", "in", ""] },
        { ipa: "i", example: ["m", "i", "lle"] },
        { ipa: "o", example: ["h", "ô", "pital"] },
        { ipa: "ɔ", example: ["h", "o", "mme"] },
        { ipa: "ɔ̃", example: ["b", "on", ""] },
        { ipa: "u", example: ["s", "ou", "s"] },
        { ipa: "y", example: ["d", "u", "r"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "oire"] },
        { ipa: "d", example: ["ma", "d", "ame"] },
        { ipa: "f", example: ["", "f", "emme"] },
        { ipa: "g", example: ["", "g", "rand"] },
        { ipa: "ɥ", example: ["br", "u", "it"] },
        { ipa: "j", example: ["mei", "ll", "eur"] },
        { ipa: "k", example: ["", "q", "uatre"] },
        { ipa: "l", example: ["ma", "l", "ade"] },
        { ipa: "m", example: ["", "m", "aison"] },
        { ipa: "n", example: ["astro", "n", "ome"] },
        { ipa: "ɲ", example: ["bai", "gn", "er"] },
        { ipa: "ŋ", example: ["parki", "ng", ""] },
        { ipa: "p", example: ["", "p", "omme"] },
        { ipa: "ʁ", example: ["amou", "r", "eux"] },
        { ipa: "s", example: ["", "s", "anté"] },
        { ipa: "ʃ", example: ["", "ch", "at"] },
        { ipa: "t", example: ["", "t", "éléphone"] },
        { ipa: "v", example: ["", "v", "rai"] },
        { ipa: "w", example: ["s", "o", "ir"] },
        { ipa: "z", example: ["rai", "s", "on"] },
        { ipa: "ʒ", example: ["auber", "g", "ine"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //nl-NL
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "øː", example: ["n", "eu", "s"] },
        { ipa: "œy", example: ["b", "ui", "t"] },
        { ipa: "ə", example: ["d", "e", ""] },
        { ipa: "a:", example: ["b", "aa", "d"] },
        { ipa: "ɑ:", example: ["b", "a", "d"] },
        { ipa: "e:", example: ["b", "ee", "t"] },
        { ipa: "ɜː", example: ["barri", "è", "re"] },
        { ipa: "ɛ", example: ["b", "e", "d"] },
        { ipa: "ɛi", example: ["b", "ee", "t"] },
        { ipa: "i", example: ["v", "ie", "r"] },
        { ipa: "ɪ", example: ["p", "i", "t"] },
        { ipa: "o:", example: ["b", "oo", "t"] },
        { ipa: "ɔ", example: ["p", "o", "t"] },
        { ipa: "u", example: ["h", "oe", "d"] },
        { ipa: "ʌu", example: ["f", "ou", "t"] },
        { ipa: "yː", example: ["f", "uu", "t"] },
        { ipa: "ʏ", example: ["h", "u", "t"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "ak"] },
        { ipa: "d", example: ["", "d", "ak"] },
        { ipa: "d͡ʒ", example: ["mana", "g", "er"] },
        { ipa: "f", example: ["", "f", "el"] },
        { ipa: "g", example: ["", "g", "oal"] },
        { ipa: "ɣ", example: ["", "h", "oed"] },
        { ipa: "ɦ", example: ["", "h", "and"] },
        { ipa: "j", example: ["", "j", "a"] },
        { ipa: "k", example: ["", "k", "ap"] },
        { ipa: "l", example: ["", "l", "and"] },
        { ipa: "m", example: ["", "m", "et"] },
        { ipa: "n", example: ["", "n", "et"] },
        { ipa: "ŋ", example: ["ba", "ng", ""] },
        { ipa: "p", example: ["", "p", "ak"] },
        { ipa: "r", example: ["", "r", "and"] },
        { ipa: "s", example: ["", "s", "ein"] },
        { ipa: "ʃ", example: ["", "sh", "ow"] },
        { ipa: "t", example: ["", "t", "ak"] },
        { ipa: "v", example: ["", "v", "el"] },
        { ipa: "ʋ", example: ["", "w", "it"] },
        { ipa: "x", example: ["to", "ch", ""] },
        { ipa: "z", example: ["", "z", "iin"] },
        { ipa: "ʒ", example: ["baga", "g", "e"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //hi-IN
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ə", example: ["", "अ", "च्छा"] },
        { ipa: "ə̃", example: ["", "हँ", "सना"] },
        { ipa: "a", example: ["", "आ", "ग"] },
        { ipa: "ã", example: ["घड़ि", "याँ", ""] },
        { ipa: "ɪ", example: ["", "इ", "क्कीस"] },
        { ipa: "ɪ̃", example: ["", "सिं", "चाई"] },
        { ipa: "i", example: ["बि", "ल्ली", ""] },
        { ipa: "ĩ", example: ["न", "हीं", ""] },
        { ipa: "ʊ", example: ["", "उ", "ल्लू"] },
        { ipa: "ʊ̃", example: ["", "मुँ", "ह"] },
        { ipa: "u", example: ["", "फू", "ल"] },
        { ipa: "ũ", example: ["", "ऊँ", "ट"] },
        { ipa: "ɔ", example: ["", "कौ", "न"] },
        { ipa: "ɔ̃", example: ["", "भौं", ""] },
        { ipa: "o", example: ["", "सो", "ना"] },
        { ipa: "õ", example: ["", "क्यों", ""] },
        { ipa: "ɛ", example: ["", "पै", "सा"] },
        { ipa: "ɛ̃", example: ["", "मैं", ""] },
        { ipa: "e", example: ["", "ए", "क"] },
        { ipa: "ẽ", example: ["किता", "बें ", ""] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "pʰ", example: ["", "फू", "ल"] },
        { ipa: "bʱ", example: ["", "भा", "री"] },
        { ipa: "t̪", example: ["", "ता", "पमान"] },
        { ipa: "t̪ʰ", example: ["", "थो", "ड़ा"] },
        { ipa: "d̪", example: ["", "दि", "ल्ली"] },
        { ipa: "d̪ʱ", example: ["", "धो", "बी"] },
        { ipa: "ʈ", example: ["क", "टो", "रा"] },
        { ipa: "ʈʰ", example: ["", "ठं", "ड"] },
        { ipa: "ɖ", example: ["", "ड", "र"] },
        { ipa: "ɖʱ", example: ["", "ढा", "ल"] },
        { ipa: "tʃʰ", example: ["", "छा", "ल"] },
        { ipa: "dʒʱ", example: ["", "झा", "ल"] },
        { ipa: "kʰ", example: ["", "खा", "न"] },
        { ipa: "ɡʱ", example: ["", "घा", "न"] },
        { ipa: "ɳ", example: ["क्ष", "ण", ""] },
        { ipa: "ɾ", example: ["", "रा", "म"] },
        { ipa: "ɽ", example: ["ब", "ड़ा", ""] },
        { ipa: "ɽʱ", example: ["ब", "ढ़ी", ""] },
        { ipa: "ʋ", example: ["", "व", "सूल"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [...ipa_additionalSymbols, []]);
    //cmn-CN
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ɤ", example: ["恶, ", "e", "4"] },
        { ipa: "ə ", example: ["恩, ", "e", "n1"] },
        { ipa: "a", example: ["安, ", "a", "n1"] },
        { ipa: "aɪ", example: ["爱, ", "ai", "4"] },
        { ipa: "aʊ", example: ["奥, ", "ao", "4"] },
        { ipa: "eɪ", example: ["诶, ", "ei", "4"] },
        { ipa: "ɛ", example: ["姐, ji", "e", "3"] },
        { ipa: "i", example: ["鸡, j", "i", "1"] },
        { ipa: "oʊ", example: ["欧, ", "ou", "1"] },
        { ipa: "ɔ", example: ["哦, ", "o", "4"] },
        { ipa: "u", example: ["主, zh", "u", "3"] },
        { ipa: "y", example: ["于, ", "yu", "2"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "f", example: ["发, ", "f", "a1"] },
        { ipa: "h", example: ["和, ", "h", "e2"] },
        { ipa: "k", example: ["古, ", "g", "u3"] },
        { ipa: "kʰ", example: ["苦, ", "k", "u3"] },
        {
          ipa: "l",
          example: ["拉, ", "l", "a1"],
        },
        { ipa: "m", example: ["骂, ", "m", "a4"] },
        { ipa: "n", example: ["那, ", "n", "a4"] },
        { ipa: "ŋ", example: ["正, zhe", "ng", "4"] },
        { ipa: "p", example: ["爸, ", "b", "a4"] },
        { ipa: "pʰ", example: ["怕, ", "p", "a4"] },
        { ipa: "s", example: ["四, ", "s", "i4"] },
        { ipa: "ɕ", example: ["西, ", "x", "i1"] },
        { ipa: "ʂ", example: ["是, ", "sh", "i4"] },
        { ipa: "t", example: ["打, ", "d", "a3"] },
        { ipa: "tʰ ", example: ["他, ", "t", "a1"] },
        { ipa: "ʈ͡ʂ", example: ["之, ", "zh", "i1"] },
        { ipa: "ʈ͡ʂʰ ", example: ["吃, ", "ch", "i1"] },
        { ipa: "t͡s ", example: ["字, ", "z", "i4"] },
        { ipa: "t͡ɕ", example: ["鸡, ", "j", "i1"] },
        { ipa: "t͡ɕʰ", example: ["七, ", "q", "i1"] },
        { ipa: "t͡sʰ ", example: ["次, ", "c", "i4"] },
        { ipa: "w", example: ["我, ", "w", "o3"] },
        { ipa: "ʐ", example: ["日, ", "r", "i4"] },
        { ipa: "ɚ", example: ["二, ", "er", "4"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "1", example: ["淤, yu1", "", ""] },
        { ipa: "2", example: ["鱼, yu2", "", ""] },
        { ipa: "3", example: ["语, yu3", "", ""] },
        { ipa: "4", example: ["育, yu4", "", ""] },
        { ipa: "0", example: ["的, de0", "", ""] },
        { ipa: "-", example: ["语音 yu3-yin1", "", ""] },
      ],
    ]);

    //ja-JP
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "äː", example: ["羽蟻, h", "aa", "ri"] },
        { ipa: "ä", example: ["仮名, k", "a", "na"] },
        { ipa: "eː", example: ["学生, gakus", "ei", ""] },
        { ipa: "e", example: ["歴, r", "e", "ki"] },
        { ipa: "i", example: ["気, k", "i", ""] },
        { ipa: "iː", example: ["詩歌, sh", "ii", "ka"] },
        { ipa: "ɯ", example: ["運, ", "u", "n"] },
        { ipa: "ɯː", example: ["宗教, sh", "uu", "kyou"] },
        { ipa: "oː", example: ["購読, k", "oo", "doku"] },
        { ipa: "o", example: ["読者, d", "o", "kusha"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "ɾ", example: ["練習, ", "r", "enshuu"] },
        { ipa: "ʔ", example: ["あつっ, atsu'", "", ""] },
        { ipa: "b", example: ["舞踊, ", "b", "uyou"] },
        { ipa: "β", example: ["ヴィンテージ, ", "v", "inteeji"] },
        { ipa: "c", example: ["ききょう, ki", "ky", "ou"] },
        { ipa: "ç", example: ["人, ", "h", "ito"] },
        { ipa: "d", example: ["濁点, ", "d", "akuten"] },
        { ipa: "d͡ʑ", example: ["純, ", "j", "un"] },
        { ipa: "ɡ", example: ["ご飯, ", "g", "ohan"] },
        { ipa: "h", example: ["本, ", "h", "on"] },
        { ipa: "j", example: ["屋根, ", "y", "ane"] },
        { ipa: "ɟ", example: ["行儀, ", "gy", "ougi"] },
        { ipa: "k", example: ["漢字, ", "k", "anji"] },
        { ipa: "ɺ", example: ["釣り, tsu", "r", "i"] },
        { ipa: "ɺj", example: ["流行, ", "ry", "uukou"] },
        { ipa: "m", example: ["飯, ", "m", "eshi"] },
        { ipa: "n", example: ["猫, ", "n", "eko"] },
        { ipa: "ɲ", example: ["日本, ", "n", "ippon"] },
        { ipa: "ɴ", example: ["缶, ka", "n", ""] },
        { ipa: "p", example: ["パン, ", "p", "an"] },
        { ipa: "ɸ", example: ["福, ", "h", "uku"] },
        { ipa: "s", example: ["層, ", "s", "ou"] },
        { ipa: "ɕ", example: ["書簡, ", "sh", "okan"] },
        { ipa: "t", example: ["手紙, ", "t", "egami"] },
        { ipa: "t͡s", example: ["釣り, ", "ts", "uri"] },
        { ipa: "t͡ɕ", example: ["吉, ki", "ch", "i"] },
        { ipa: "w", example: ["電話, den", "w", "a"] },
        { ipa: "z", example: ["座敷, ", "z", "ashiki"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [...ipa_additionalSymbols, []]);

    //ru-RU
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "ə", example: ["канарейк", "a", ""] },
        { ipa: "a", example: ["дв", "а, я", "блоко"] },
        { ipa: "e", example: ["п", "е", "чь"] },
        { ipa: "ɛ", example: ["э", "то", ""] },
        { ipa: "i", example: ["од", "и", "н, четыре"] },
        { ipa: "o", example: ["к", "о", "т"] },
        { ipa: "u", example: ["м", "у", "ж, вьюга"] },
        { ipa: "ɨ", example: ["м", "ы", "шь"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "б", "орт"] },
        { ipa: "bʲ", example: ["", "б", "юро"] },
        { ipa: "d", example: ["", "д", "ом"] },
        { ipa: "dʲ", example: ["", "д", "ядя"] },
        { ipa: "f", example: ["", "ф", "лаг"] },
        { ipa: "fʲ", example: ["", "ф", "евраль"] },
        { ipa: "g", example: ["но", "r", "a"] },
        { ipa: "ɡʲ", example: ["", "r", "ерой"] },
        { ipa: "j", example: ["диза", "й", "н, ящик"] },
        { ipa: "k", example: ["", "к", "от"] },
        { ipa: "kʲ", example: ["", "к", "ино"] },
        { ipa: "l", example: ["", "л", "ампа"] },
        { ipa: "lʲ", example: ["", "л", "ес"] },
        { ipa: "m", example: ["", "м", "ама"] },
        { ipa: "mʲ", example: ["", "м", "яч"] },
        { ipa: "n", example: ["", "н", "ос"] },
        { ipa: "nʲ", example: ["", "н", "яня"] },
        { ipa: "p", example: ["", "п", "апа"] },
        { ipa: "pʲ", example: ["", "п", "еро"] },
        { ipa: "r", example: ["", "р", "оза"] },
        { ipa: "rʲ", example: ["", "р", "юмка"] },
        { ipa: "s", example: ["", "с", "ыр"] },
        { ipa: "sʲ", example: ["", "с", "ердце, русь"] },
        { ipa: "ɕ:", example: ["", "щ", "ека"] },
        { ipa: "ʂ", example: ["", "ш", "ум"] },
        { ipa: "t", example: ["", "т", "очка"] },
        { ipa: "tʲ", example: ["", "т", "ётя"] },
        { ipa: "t͡s", example: ["", "ц", "арь"] },
        { ipa: "t͡ɕ", example: ["", "ч", "ас"] },
        { ipa: "v", example: ["", "в", "ор"] },
        { ipa: "vʲ", example: ["", "в", "ерфь"] },
        { ipa: "x", example: ["", "х", "ор"] },
        { ipa: "xʲ", example: ["", "х", "имия"] },
        { ipa: "z", example: ["", "з", "уб"] },
        { ipa: "zʲ", example: ["", "з", "има"] },
        { ipa: "ʑ:", example: ["уе", "зж", "ать"] },
        { ipa: "ʐ", example: ["", "ж", "ена"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [...ipa_additionalSymbols, []]);

    //de-DE
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "øː", example: ["b", "ö", "e"] },
        { ipa: "ɐ", example: ["bess", "er", ""] },
        { ipa: "ɐ̯", example: ["kl", "ar", ""] },
        { ipa: "œ", example: ["k", "ö", "nnen"] },
        { ipa: "ə", example: ["red", "e", ""] },
        { ipa: "a", example: ["s", "a", "lz"] },
        { ipa: "a:", example: ["s", "ah", "ne"] },
        { ipa: "aɪ", example: ["n", "ei", "n"] },
        { ipa: "aʊ", example: ["", "au", "gen"] },
        { ipa: "ɑ̃", example: ["restaur", "ant", ""] },
        { ipa: "e:", example: ["r", "e", "de"] },
        { ipa: "ɛ", example: ["k", "e", "ller"] },
        { ipa: "ɛ̃", example: ["terr", "ain", ""] },
        { ipa: "i:", example: ["l", "ie", "d"] },
        { ipa: "ɪ", example: ["b", "i", "tte"] },
        { ipa: "o:", example: ["k", "oh", "l"] },
        { ipa: "ɔ", example: ["k", "o", "ffer"] },
        { ipa: "ɔ̃", example: ["ann", "on", "ce"] },
        { ipa: "ɔʏ", example: ["n", "eu", ""] },
        { ipa: "u:", example: ["br", "u", "der"] },
        { ipa: "ʊ", example: ["w", "u", "nder"] },
        { ipa: "y:", example: ["k", "üh", "l"] },
        { ipa: "ʏ", example: ["k", "ü", "che"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "ier"] },
        { ipa: "d", example: ["", "d", "ach"] },
        { ipa: "ç", example: ["i", "ch", ""] },
        { ipa: "d͡ʒ", example: ["", "dsch", "ungel"] },
        { ipa: "f", example: ["", "v", "ogel"] },
        { ipa: "g", example: ["", "g", "abel"] },
        { ipa: "h", example: ["", "h", "aus"] },
        { ipa: "j", example: ["", "j", "emand"] },
        { ipa: "k", example: ["", "k", "leid"] },
        { ipa: "l", example: ["", "l", "och"] },
        { ipa: "m", example: ["", "m", "ilch"] },
        { ipa: "n", example: ["", "n", "atur"] },
        { ipa: "ŋ", example: ["kli", "ng", "en"] },
        { ipa: "p", example: ["", "p", "ark"] },
        { ipa: "p͡f", example: ["a", "pf", "el"] },
        { ipa: "ʀ", example: ["", "r", "egen"] },
        { ipa: "s", example: ["me", "ss", "er"] },
        { ipa: "ʃ", example: ["fi", "sch", "er"] },
        { ipa: "t", example: ["", "t", "opf"] },
        { ipa: "t͡s", example: ["", "z", "ahl"] },
        { ipa: "t͡ʃ", example: ["deu", "tsch", ""] },
        { ipa: "v", example: ["", "w", "asser"] },
        { ipa: "x", example: ["ko", "ch", "en"] },
        { ipa: "z", example: ["", "s", "ee"] },
        { ipa: "ʒ", example: ["oran", "g", "e"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //it-IT
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "a", example: ["", "a", "rco"] },
        { ipa: "e", example: ["tr", "e", ""] },
        { ipa: "ɛ", example: ["", "e", "ttaro"] },
        { ipa: "i", example: ["", "i", "mpero"] },
        { ipa: "o", example: ["cent", "o", ""] },
        { ipa: "ɔ", example: ["", "o", "tto"] },
        { ipa: "u", example: ["", "u", "no"] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "b", example: ["", "b", "acca"] },
        { ipa: "d", example: ["", "d", "ama"] },
        { ipa: "d͡z", example: ["", "z", "ero"] },
        { ipa: "d͡ʒ", example: ["", "g", "iro"] },
        { ipa: "f", example: ["", "f", "amiglia"] },
        { ipa: "g", example: ["", "g", "atto"] },
        { ipa: "h", example: ["", "h", "orror"] },
        { ipa: "j", example: ["d", "i", "eci"] },
        { ipa: "k", example: ["", "c", "ampo"] },
        { ipa: "l", example: ["", "l", "ido"] },
        { ipa: "ʎ", example: ["a", "gli", "o"] },
        { ipa: "m", example: ["", "m", "ille"] },
        { ipa: "n", example: ["", "n", "ove"] },
        { ipa: "ɲ", example: ["lasa", "gn", "e"] },
        { ipa: "p", example: ["", "p", "izza"] },
        { ipa: "r", example: ["", "r", "isata"] },
        { ipa: "s", example: ["", "s", "ei"] },
        { ipa: "ʃ", example: ["", "sci", "enza"] },
        { ipa: "t", example: ["", "t", "avola"] },
        { ipa: "t͡s", example: ["for", "z", "a"] },
        { ipa: "t͡ʃ", example: ["", "ci", "elo"] },
        { ipa: "v", example: ["", "v", "enti"] },
        { ipa: "w", example: ["q", "u", "attro"] },
        { ipa: "z", example: ["bi", "s", "ogno"] },
        { ipa: "ʒ", example: ["bi", "j", "ou"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);

    //es-ES
    setIpa_vowels((ipa_vowels) => [
      ...ipa_vowels,
      [
        { ipa: "a", example: ["t", "a", "nque"] },
        { ipa: "e", example: ["p", "e", "so"] },
        { ipa: "i", example: ["c", "i", "nco"] },
        { ipa: "o", example: ["b", "o", "sque"] },
        { ipa: "u", example: ["p", "u", "blicar"] },
        { ipa: "e", example: ["k", "e", "çi"] },
        { ipa: "ɛ", example: ["ded", "e", ""] },
        { ipa: "i", example: ["b", "i", "r"] },
        { ipa: "i:", example: ["", "i", "zah"] },
        { ipa: "ɪ", example: ["keç", "i", ""] },
        { ipa: "ɯ", example: ["k", "ı", "l"] },
        { ipa: "o", example: ["k", "o", "l"] },
        { ipa: "o:", example: ["d", "o", "lar"] },
        { ipa: "u", example: ["d", "u", "rum"] },
        { ipa: "u:", example: ["r", "u", "hum"] },
        { ipa: "ʊ", example: ["dol", "u", ""] },
        { ipa: "Y", example: ["g", "ü", "venlik"] },
        { ipa: "ʏ", example: ["aş", "ı", ""] },
      ],
    ]);
    setIpa_consonants((ipa_consonants) => [
      ...ipa_consonants,
      [
        { ipa: "ɾ", example: ["pe", "r", "o"] },
        { ipa: "b", example: ["b", "e", "stia"] },
        { ipa: "β", example: ["be", "b", "é"] },
        { ipa: "d", example: ["cuan", "d", "o"] },
        { ipa: "ð", example: ["ar", "d", "er"] },
        { ipa: "f", example: ["ca", "f", "é"] },
        { ipa: "g", example: ["", "g", "ato"] },
        { ipa: "ɣ", example: ["tri", "g", "o"] },
        { ipa: "j", example: ["hac", "i", "a"] },
        { ipa: "ʝ", example: ["enh", "i", "elar"] },
        { ipa: "k", example: ["la", "c", "a"] },
        { ipa: "l", example: ["", "l", "ino"] },
        { ipa: "ʎ", example: ["po", "ll", "o"] },
        { ipa: "m", example: ["", "m", "adre"] },
        { ipa: "n", example: ["", "n", "ido"] },
        { ipa: "ɲ", example: ["caba", "ñ", "a"] },
        { ipa: "ŋ", example: ["ci", "n", "co"] },
        { ipa: "p", example: ["", "p", "ozo"] },
        { ipa: "r", example: ["pe", "rr", "o"] },
        { ipa: "s", example: ["ca", "s", "a"] },
        { ipa: "t", example: ["", "t", "amiz"] },
        { ipa: "t͡ʃ", example: ["", "ch", "ubasco"] },
        { ipa: "θ", example: ["cere", "z", "a"] },
        { ipa: "w", example: ["f", "u", "ego"] },
        { ipa: "x", example: ["", "g", "eneral"] },
        { ipa: "z", example: ["ra", "s", "go"] },
      ],
    ]);
    setIpa_additionalSymbols((ipa_additionalSymbols) => [
      ...ipa_additionalSymbols,
      [
        { ipa: "ˈ", example: ["Ala", "ba", "ma"] },
        { ipa: "ˌ", example: ["", "A", "labama"] },
        { ipa: ".", example: ["A.la.ba.ma", "", ""] },
      ],
    ]);
  }

  function updatePhoneticSymboles() {
    if (!phonemeVowelRef.current) return;
    let language = 0;
    let index = ipa_languages.findIndex((element) => element.language === voiceSelected.language_name);
    if (index >= 0) language = index;

    let elements = document.getElementsByClassName("div-phoneme");
    while (elements[0]) {
      elements[0].parentNode.removeChild(elements[0]);
    }

    phonemeVowelRef.current.style.display = "flex";
    phonemeVowelRef.current.style.flexDirection = "row";
    phonemeVowelRef.current.style.justifyContent = "center";

    ipa_vowels[language].forEach((phoneme) => {
      let div = document.createElement("div");
      div.className = "div-phoneme";

      let divIpa = document.createElement("div");
      divIpa.className = "ipa";
      divIpa.innerHTML = phoneme.ipa;
      div.appendChild(divIpa);

      let divExample = document.createElement("div");
      divExample.className = "example";
      divExample.innerHTML = phoneme.example[0].toUpperCase() + "<u>" + phoneme.example[1].toUpperCase() + "</u>" + phoneme.example[2].toUpperCase();
      div.appendChild(divExample);

      div.onclick = function () {
        let pos = textareaPhoneticApi.current.selectionStart;
        textareaPhoneticApi.current.value = textareaPhoneticApi.current.value.substr(0, pos) + phoneme.ipa + textareaPhoneticApi.current.value.substr(pos);
        textareaPhoneticApi.current.selectionStart = pos + 1;
        textareaPhoneticApi.current.selectionEnd = pos + 1;
        textareaPhoneticApi.current.focus();

        isOpen.phonetic.phoneticIPA = textareaPhoneticApi.current.value;
        setTextAreaPhoneticIPA(isOpen.phonetic.phoneticIPA);
      };

      phonemeVowelRef.current.appendChild(div);
    });

    phonemeConsonantRef.current.style.display = "flex";
    phonemeConsonantRef.current.style.flexDirection = "row";
    phonemeConsonantRef.current.style.justifyContent = "center";
    phonemeConsonantRef.current.style.flexWrap = "wrap";

    ipa_consonants[language].forEach((phoneme) => {
      let div = document.createElement("div");
      div.className = "div-phoneme";

      let divIpa = document.createElement("div");
      divIpa.className = "ipa";
      divIpa.innerHTML = phoneme.ipa;
      div.appendChild(divIpa);

      let divExample = document.createElement("div");
      divExample.className = "example";
      divExample.innerHTML = phoneme.example[0].toUpperCase() + "<u>" + phoneme.example[1].toUpperCase() + "</u>" + phoneme.example[2].toUpperCase();
      div.appendChild(divExample);

      div.onclick = function () {
        let pos = textareaPhoneticApi.current.selectionStart;
        textareaPhoneticApi.current.value = textareaPhoneticApi.current.value.substr(0, pos) + phoneme.ipa + textareaPhoneticApi.current.value.substr(pos);
        textareaPhoneticApi.current.selectionStart = pos + 1;
        textareaPhoneticApi.current.selectionEnd = pos + 1;
        textareaPhoneticApi.current.focus();

        isOpen.phonetic.phoneticIPA = textareaPhoneticApi.current.value;
        setTextAreaPhoneticIPA(isOpen.phonetic.phoneticIPA);

        //imv_updateEditPhoneticPlayButton();
      };

      phonemeConsonantRef.current.appendChild(div);
    });

    phonemeSymbolRef.current.style.display = "flex";
    phonemeSymbolRef.current.style.flexDirection = "row";
    phonemeSymbolRef.current.style.justifyContent = "center";
    phonemeSymbolRef.current.style.flexWrap = "wrap";

    ipa_additionalSymbols[language].forEach((phoneme) => {
      let div = document.createElement("div");
      div.className = "div-phoneme";

      let divIpa = document.createElement("div");
      divIpa.className = "ipa";
      divIpa.innerHTML = phoneme.ipa;
      div.appendChild(divIpa);

      let divExample = document.createElement("div");
      divExample.className = "example";
      divExample.innerHTML = phoneme.example[0].toUpperCase() + "<u>" + phoneme.example[1].toUpperCase() + "</u>" + phoneme.example[2].toUpperCase();
      div.appendChild(divExample);

      div.onclick = function () {
        let pos = textareaPhoneticApi.current.selectionStart;
        textareaPhoneticApi.current.value = textareaPhoneticApi.current.value.substr(0, pos) + phoneme.ipa + textareaPhoneticApi.current.value.substr(pos);
        textareaPhoneticApi.current.selectionStart = pos + 1;
        textareaPhoneticApi.current.selectionEnd = pos + 1;
        textareaPhoneticApi.current.focus();

        isOpen.phonetic.phoneticIPA = textareaPhoneticApi.current.value;
        setTextAreaPhoneticIPA(isOpen.phonetic.phoneticIPA);
      };

      phonemeSymbolRef.current.appendChild(div);
    });
  }

  return (
    <div className="editphonetic">
      <div
        className="modal-container"
        onClick={() => {
          playerRef.current.pauseIfPlaying();
          closeHandler(true);
        }}
      >
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div
            className="close-btn"
            onClick={() => {
              playerRef.current.pauseIfPlaying();
              closeHandler(true);
            }}
          >
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>
          <SelectVoice
            closeHandler={(public_name) => {
              setSelectVoiceOpened(false);
            }}
            isOpen={selectVoiceOpened}
            block={voiceSelected}
            handleLanguageChange={(e) => {
              setVoiceSelected({
                language_name: e.language,
                flag: e.flag,
              });
              isOpen.phonetic.voice_public_name = block.voice_public_name;
              updatePhoneticSymboles();
            }}
            handleVoiceChange={(name, language) => {
              isOpen.phonetic.voice_public_name = name;

              setVoiceSelected({
                language_name: language.language,
                flag: language.flag,
                voice_public_name: name,
              });

              updatePhoneticSymboles();
            }}
          />
          <h2>Edit phonetic</h2>

          <div>
            <p style={{ color: "#ccc" }}>
              You have two possibilities to replace a text by its phonetics. By filling in the field <span style={{ color: "#fff", fontStyle: "italic" }}>Replace with</span> with text whose pronunciation will match the desired pronunciation
              or by selecting a combination of IPA symbols from the table below. Selecting the language displays the phonemes corresponding to the language.
            </p>

            <div className="field">
              <label className="label-field">Text to replace</label>
              <textarea
                autoComplete="off"
                className="field-textarea"
                spellCheck="false"
                value={textAreaPhoneticText}
                onChange={(e) => {
                  isOpen.phonetic.phoneticText = e.target.value;
                  setTextAreaPhoneticText(isOpen.phonetic.phoneticText);
                }}
              />
            </div>
            <div className="field">
              <label className="label-field">Replace by</label>
              <textarea
                autoComplete="off"
                className="field-textarea"
                spellCheck="false"
                value={textAreaPhoneticTranscription}
                onChange={(e) => {
                  isOpen.phonetic.phoneticTranscription = e.target.value;
                  setTextAreaPhoneticTranscription(isOpen.phonetic.phoneticTranscription);
                }}
              />
            </div>
            <div className="field">
              <label className="label-field">Phonetic IPA</label>
              <textarea
                ref={textareaPhoneticApi}
                autoComplete="off"
                className="field-textarea"
                spellCheck="false"
                value={textAreaPhoneticIPA}
                onChange={(e) => {
                  isOpen.phonetic.phoneticIPA = e.target.value;
                  setTextAreaPhoneticIPA(isOpen.phonetic.phoneticIPA);
                }}
              />
            </div>

            {/* <button
              className="btn-ok"
              onClick={() => {
                playerRef.current.pauseIfPlaying();
                closeHandler(true);
              }}
            >
              OK
            </button> */}

            <div>
              <p>Select the language for which you want to have the IPA phonetics symbols. The choice of voice allows you to listen to the result.</p>

              <button
                className="button"
                style={{ width: "240px" }}
                onClick={() => {
                  setSelectVoiceOpened(true);
                }}
              >
                <img className={voiceSelected.flag !== "" ? "btn-icon" : "hidden"} src={voiceSelected ? "./img/flag_" + voiceSelected.flag + ".png" : ""} alt=""></img>
                {voiceSelected.language_name + (voiceSelected.voice_public_name !== "" ? " - " + voiceSelected.voice_public_name : "")}
              </button>

              <div style={{ height: "20px" }}></div>

              <label className="label-phoneme">Vowels</label>
              <div ref={phonemeVowelRef}></div>
              <div style={{ height: "10px" }}></div>
              <label className="label-phoneme">Consonants</label>
              <div ref={phonemeConsonantRef}></div>
              <div style={{ height: "10px" }}></div>
              <label className="label-phoneme">Additional symbols</label>
              <div ref={phonemeSymbolRef}></div>
            </div>
            <div style={{ height: "20px" }}></div>
            <Player ref={playerRef} token={token} phonetics={phonetics} block={isOpen.phonetic} updateSpinner={updateSpinner} info={""} phoneticMode={true}></Player>
            <div style={{ height: "10px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPhonetic;
