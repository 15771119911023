import React, { useEffect, useState } from "react";
import SelectLanguage from "./SelectLanguage";
import { translate } from "../js/Translation";

const Translate = ({ totalCharNumber, isOpen, token, closeHandler, onTranslate }) => {
  const apiAddress = "https://imea-studio.com/";
  const [languages, setLanguages] = useState();
  const [languageSelected, setLanguageSelected] = useState("");

  useEffect(() => {
    async function getLanguages() {
      const data = await (
        await fetch(apiAddress + "vo-get-languages.php?token=8gs34lxp6w9m082H4q721-45sx", {
          method: "GET",
        })
      ).json();

      setLanguages(data["languages"]);
    }

    getLanguages();
  }, []);

  if (!isOpen) return null;

  return (
    <div className="selectvoice">
      <div className="modal-container" onClick={closeHandler}>
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div className="close-btn" onClick={closeHandler}>
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>
          <div style={{ height: "50px" }}></div>
          <div className="info-char">{translate("SelectTheLanguageInWhichYouWantToPerformTheTranslation")}</div>
          <div style={{ height: "30px" }}></div>
          <SelectLanguage
            languages={languages}
            languageSelected={languageSelected}
            languageSelectedChange={(e) => {
              setLanguageSelected(
                languages.find((lang) => {
                  return lang.language === e.language;
                })
              );
            }}
          />

          <div style={{ height: "40px" }}></div>
          <div className={languageSelected !== "" ? "info" : "hidden"}>
            <div className="info-char">{translate("SaveTheProjectBeforePerformingTheTranslationYouCanThenSaveTheNewProjectForTheNewLanguage")}</div>
            <div className="info-char">
              {translate("CharsAvailable")} <span style={{ color: "#fff", fontWeight: "bold" }}>{token.voiceover_char_credit}</span>
            </div>
            <div className="info-char">
              {translate("AboutChars")} <span style={{ color: "#fff", fontWeight: "bold" }}>{totalCharNumber}</span> {translate("CharsWillBeDeductedAfterTheTranslationHasBeenGenerated")}
            </div>
            <button
              className="btn-ok"
              onClick={(e) => {
                onTranslate(languageSelected);
              }}
            >
              {translate("TRANSLATEALLTEXTS")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Translate;
