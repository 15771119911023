import React from "react";

const Spinner = (props) => {
  return (
    <div className="spinner" style={{ display: props.values.visible ? "block" : "none" }}>
      <div className="overlay"></div>
      <div>
        <div className="sk-fading-circle">
          <div className="percent">{props.values.percent}</div>
          <div className="info">{props.values.info}</div>
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
