import React, { useState } from "react";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import Navigation from "../components/Navigation";

const Editor = () => {
  const [videoSrc, setVideoSrc] = useState("");
  const [message, setMessage] = useState("Click Start to transcode");
  const ffmpeg = createFFmpeg({
    log: false,
  });

  const doTranscode = async () => {
    const inputFileMp4 = document.getElementById("uploaderMp4").files[0];
    const inputFileMp3 = document.getElementById("uploaderMp3").files[0];
    setMessage("Loading ffmpeg-core.js");
    await ffmpeg.load();

    ffmpeg.setProgress(({ ratio }) => {
      //console.log(ratio);
    });

    setMessage("Start transcoding");
    ffmpeg.FS("writeFile", "video.mp4", await fetchFile(inputFileMp4));
    ffmpeg.FS("writeFile", "audio.mp3", await fetchFile(inputFileMp3));
    await ffmpeg.run(
      "-i",
      "video.mp4",
      "-i",
      "audio.mp3",
      "-c:v",
      "copy",
      "-map",
      "0:v",
      "-map",
      "1:a",
      "-y",
      "output.mp4"
    );
    setMessage("Complete transcoding");
    const data = ffmpeg.FS("readFile", "output.mp4");
    setVideoSrc(
      URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }))
    );

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var url = window.URL.createObjectURL(new Blob([data.buffer]));
    a.href = url;
    a.download = "coucou.mp4";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Navigation />
      <h1>EDITOR</h1>

      <p />
      <div>
        MP4
        <input id="uploaderMp4" type="file" accept="video/*"></input>
      </div>
      <div>
        MP3
        <input id="uploaderMp3" type="file" accept="audio/*"></input>
      </div>
      <video src={videoSrc} controls></video>
      <br />
      <button onClick={doTranscode}>Start</button>
      <p>{message}</p>
    </div>
  );
};

export default Editor;
