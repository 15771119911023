import React, { useEffect, useRef, useState } from "react";
import SelectVoice from "../components/SelectVoice";
import Phonetic from "../components/Phonetic";
import Player from "../components/Player";
import { translate } from "../js/Translation";

const EditBlock = ({ isOpen, closeHandler, blockSelected, updateSpinner, token, toolbarMenuOpened }) => {
  const apiAddress = "https://imea-studio.com/";
  const editBlockRef = useRef();
  const playerRef = useRef(null);
  const textareaRef = useRef(null);
  const [textArea, setTextArea] = useState("");
  const [selectVoiceOpened, setSelectVoiceOpened] = useState(false);
  const [phoneticOpened, setPhoneticOpened] = useState(false);
  const [languages, setLanguages] = useState();

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       editBlockRef.current &&
  //       !editBlockRef.current.contains(event.target)
  //     ) {
  //       closeHandler();
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [closeHandler]);

  useEffect(() => {
    async function getLanguages() {
      const data = await (
        await fetch(apiAddress + "vo-get-languages.php?token=8gs34lxp6w9m082H4q721-45sx", {
          method: "GET",
        })
      ).json();

      setLanguages(data["languages"]);
    }

    getLanguages();
  }, []);

  useEffect(() => {
    if (languages && isOpen.block) {
      if (isOpen.block.language_name === "") isOpen.block.language_name = "FR - French";
      isOpen.block.language = languages.find((lang) => {
        return lang.language === isOpen.block.language_name;
      });
    }

    if (blockSelected && isOpen.block !== blockSelected) {
      if (blockSelected.animationFrameId) window.cancelAnimationFrame(blockSelected.animationFrameId);

      if (blockSelected.audioBufferSource) blockSelected.audioBufferSource.stop();
      playerRef.current?.updatePlaying(0);
    }

    if (isOpen.block) {
      setTextArea(isOpen.block.text);
    }
  }, [isOpen.block, blockSelected, languages]);

  if (!isOpen.opened) return null;

  if (textareaRef.current && textareaRef.current.value !== isOpen.block.text) {
    textareaRef.current.value = isOpen.block.text;
  }

  return (
    <div ref={editBlockRef} className={isOpen ? "editblock" : "hidden"} style={{ zIndex: toolbarMenuOpened ? "100" : "10000000" }}>
      <div>
        <Phonetic
          closeHandler={() => {
            setPhoneticOpened(false);
          }}
          isOpen={phoneticOpened}
          block={isOpen.block}
          project={isOpen.project}
          updateSpinner={updateSpinner}
          token={token}
        />

        <SelectVoice
          closeHandler={(public_name) => {
            setSelectVoiceOpened(false);
          }}
          isOpen={selectVoiceOpened}
          block={isOpen.block}
          updateAllMode={false}
          handleVoiceChange={(public_name, language) => {
            isOpen.block.voice_public_name = public_name;
            isOpen.block.language = language;
          }}
        />

        <div
          className="close-btn widthConditionInf"
          onClick={() => {
            playerRef.current.pauseIfPlaying();
            closeHandler();
          }}
        >
          <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
        </div>
        <textarea
          ref={textareaRef}
          placeholder={translate("EditTextEnterText")}
          className="textarea"
          spellCheck="false"
          value={textArea}
          onChange={(e) => {
            isOpen.block.text = e.target.value;
            setTextArea(isOpen.block.text);
          }}
        ></textarea>
      </div>
      <div>
        <div
          className="close-btn widthConditionSup"
          onClick={() => {
            playerRef.current.pauseIfPlaying();
            closeHandler();
          }}
        >
          <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
        </div>

        <div style={{ margin: "20px auto 10px auto", textAlign: "center" }}>
          <button
            className="button"
            onClick={() => {
              setSelectVoiceOpened(true);
            }}
          >
            {isOpen.block.language && (
              <div>
                <img className="btn-icon" src={isOpen.block.language ? "./img/flag_" + isOpen.block.language.flag + ".png" : ""} alt=""></img>
                {isOpen.block.voice_public_name ? isOpen.block.voice_public_name : "Voice"}
              </div>
            )}
            {!isOpen.block.language && <div>{isOpen.block.voice_public_name ? isOpen.block.voice_public_name : "Voice"}</div>}
          </button>

          <button
            className="button"
            onClick={() => {
              setPhoneticOpened(true);
            }}
          >
            {translate("Phonetic")}
          </button>
        </div>
        <Player ref={playerRef} token={token} block={isOpen.block} phonetics={isOpen.project.phonetics} updateSpinner={updateSpinner} info={translate("CharsAvailable") + token.voiceover_char_credit}></Player>
      </div>
    </div>
  );
};

export default EditBlock;
