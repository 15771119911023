import React from "react";
import Navigation from "../components/Navigation";
import AppContext from "../js/AppContext";

const Home = () => {
  const mode = AppContext.mode;

  return (
    <div>
      <Navigation mode={mode} />
    </div>
  );
};

export default Home;
