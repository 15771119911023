import React, { useState, useEffect, useRef } from "react";
import { translate } from "../js/Translation";

//import { Image } from "react-bootstrap";

const Toolbar = (props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    props.setMenuOpened(menuOpened);
  }, [menuOpened]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  function onSelectLang(language) {
    localStorage.setItem("language", language);
  }

  function updateProjectTotalChars() {
    let totalChars = 0;
    props.project.blocks.forEach((block) => {
      totalChars += block.text.length;
    });

    props.project.totalChars = totalChars;
  }

  return (
    <div style={{ display: props.visible ? "flex" : "none" }}>
      <button className={props.playing ? "hidden" : "none"} onMouseDown={props.onPlay}>
        <img src="./img/play_white.png" alt="" />
      </button>
      <button className={props.playing ? "none" : "hidden"} onMouseDown={props.onPause}>
        <img src="./img/stop_white.png" alt="" />
      </button>
      <button style={{ marginRight: "10px" }} onMouseDown={props.onRewind}>
        <img src="./img/fast_rewind_white.png" alt="" />
      </button>

      <button style={{ marginRight: "10px" }} onMouseDown={props.onAddBlock}>
        <span>ADD TEXT</span>
      </button>

      {props.mode === "tuto" && (
        <span>
          <button onMouseDown={props.onAddCut}>
            <img src="./img/cut_white.png" alt="" />
          </button>
          <button style={{ marginRight: "10px" }} onMouseDown={props.onAddExpand}>
            <img src="./img/expand_horizontal_white.png" alt="" />
          </button>
        </span>
      )}

      <button disabled={props.zoom === 1} onMouseDown={props.onZoomM}>
        <span>
          ZOOM
          <br />-
        </span>
      </button>
      <button style={{ marginRight: "10px" }} className="button-right" disabled={props.zoom === 32} onMouseDown={props.onZoomP}>
        <span>
          ZOOM
          <br />+
        </span>
      </button>

      <span ref={dropdownRef}>
        <div className="dropdown">
          <div
            className="menu"
            onMouseDown={() => {
              updateProjectTotalChars();
              if (props.visible) setMenuOpened(!menuOpened);
            }}
          >
            <img src="./img/hamburger_menu_white.png" alt="" />
          </div>

          <div id="menu" className={menuOpened ? "dropdown-content" : "hidden"}>
            <li
              onClick={(e) => {
                props.onSelectFile(e);
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/upload_white.png" alt=""></img>
              {translate("OpenFile")}
            </li>

            <li
              onClick={(e) => {
                props.onNewProject(e);
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/refresh_white.png" alt=""></img>
              {translate("NewProject")}
            </li>

            <li
              onClick={(e) => {
                props.onSaveProject(e);
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/save_white.png" alt=""></img>
              {translate("SaveTheProject")}
            </li>

            <li
              onClick={(e) => {
                props.onSettings(e);
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/settings_white.png" alt=""></img>
              {translate("AudioSettings")}
            </li>

            <li
              onClick={(e) => {
                props.onPhonetic(e);
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/voiceover_white.png" alt=""></img>
              {translate("Phonetics")}
            </li>

            <li className="disabledx">
              <img className="btn-icon" src="./img/download_white.png" alt=""></img>
              <span>{translate("Download")}</span>
            </li>

            <li
              className="sub-item"
              onClick={(e) => {
                props.onExportVideo(e);
                setMenuOpened(false);
              }}
            >
              Video
            </li>
            <li
              className="sub-item"
              onClick={(e) => {
                props.onExportAudioMp3(e);
                setMenuOpened(false);
              }}
            >
              Audio (mp3)
            </li>
            <li
              className="sub-item"
              onClick={(e) => {
                props.onExportAudioWave(e);
                setMenuOpened(false);
              }}
            >
              Audio (wav)
            </li>
            <li
              className="sub-item"
              onClick={(e) => {
                props.onExportSubtitlesVtt(e);
                setMenuOpened(false);
              }}
            >
              {translate("SubtitlesVtt")}
            </li>
            <li
              className="sub-item"
              onClick={(e) => {
                props.onExportSubtitlesSrt(e);
                setMenuOpened(false);
              }}
            >
              {translate("SubtitlesSrt")}
            </li>

            <li
              className={props.userToken.token !== "" ? "" : "disabledx"}
              onClick={(e) => {
                if (props.userToken.token !== "") {
                  props.onSelectTranslate(e);
                  setMenuOpened(false);
                }
              }}
            >
              <img className="btn-icon" src="/img/translate_white.png" alt=""></img>
              <span>{translate("TranslateAllInANewLanguage")}</span>
            </li>
            <li
              className={props.userToken.token !== "" ? "" : "disabledx"}
              onClick={(e) => {
                if (props.userToken.token !== "") {
                  props.onSelectVoice(e);
                  setMenuOpened(false);
                }
              }}
            >
              <img className="btn-icon" src="./img/voiceover_white.png" alt=""></img>
              <span>{translate("UpdateAllVoices")}</span>
            </li>
            <li
              className={props.userToken.token === "" ? "" : "disabledx"}
              onClick={(e) => {
                if (props.userToken.token === "") {
                  props.login();
                  setMenuOpened(false);
                }
              }}
            >
              <img className="btn-icon" src="./img/login_white.png" alt=""></img>
              <span>{translate("LogIn")}</span>
            </li>
            <li
              className={props.userToken.token !== "" ? "" : "disabledx"}
              onClick={(e) => {
                if (props.userToken.token !== "") {
                  props.logout();
                  setMenuOpened(false);
                }
              }}
            >
              <img className="btn-icon" src="./img/logout_white.png" alt=""></img>
              <span>{translate("LogOut")}</span>
            </li>
            <hr style={{ opacity: "0.5" }}></hr>
            <li
              className={localStorage.getItem("language") === "fr" ? "language-selected" : "language-unselected"}
              onClick={(e) => {
                onSelectLang("fr");
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/flag_fr.png" alt=""></img>
              <span>FR</span>
            </li>
            <li
              className={localStorage.getItem("language") === "en" ? "language-selected" : "language-unselected"}
              onClick={(e) => {
                onSelectLang("en");
                setMenuOpened(false);
              }}
            >
              <img className="btn-icon" src="./img/flag_uk.png" alt=""></img>
              <span>EN</span>
            </li>
            {props.project?.totalChars ? (
              <div style={{ margin: "5px", fontSize: "13px", color: "#888" }}>
                {translate("TotalChars")} {props.project.totalChars}
              </div>
            ) : null}
          </div>
        </div>
      </span>
    </div>
  );
};

export default Toolbar;
