import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Editor from "./pages/Editor";
import Voiceover from "./pages/Voiceover";
import AppContext from "./js/AppContext";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  AppContext.mode = queryParameters.get("mode");
  AppContext.project = queryParameters.get("project");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Voiceover />} />
        <Route path="/" element={<Voiceover />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/voiceover" element={<Voiceover />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
