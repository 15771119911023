import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Login from "../components/Login";
import AppContext from "../js/AppContext";

const Navigation = ({ loginHandler, unsavedChanges, updateUnsavedChanges }) => {
  const [loginOpened, setLoginOpened] = useState(false);
  const [userToken, setUserToken] = useState("");

  return (
    <div className="navigation">
      <Login
        isOpen={loginOpened}
        token={userToken}
        closeHandler={(token) => {
          setUserToken(token);
          setLoginOpened(false);
        }}
      />
      <div className="logo">
        {AppContext.mode === "tuto" && <img alt="logo" src="../img/school_white.png" width={40} />}
        {AppContext.mode !== "tuto" && <img alt="logo" src="../img/voiceover_white.png" width={40} />}
      </div>

      <div className="brand-name">{AppContext.mode === "tuto" ? "TUTORIAL MAKER" : "VOICE ON VIDEO"}</div>

      <ul>
        <NavLink
          onClick={(e) => {
            if (unsavedChanges) {
              e.preventDefault();
              updateUnsavedChanges();
            }
          }}
          to="/"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <li>HOME</li>
        </NavLink>
        <NavLink to="/voiceover" className={(nav) => (nav.isActive ? "nav-active" : "")}>
          <li>EDITOR</li>
        </NavLink>
        <NavLink
          onClick={(e) => {
            if (unsavedChanges) {
              e.preventDefault();
              updateUnsavedChanges();
            }
          }}
          to="/tutorial"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <li>TUTORIALS</li>
        </NavLink>
        <NavLink
          onClick={(e) => {
            if (unsavedChanges) {
              e.preventDefault();
              updateUnsavedChanges();
            }
          }}
          to="/pricing"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <li>PRICING</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
