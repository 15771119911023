import React, { useState } from "react";
import EditPhonetic from "../components/EditPhonetic";
import PhoneticHelp from "../components/PhoneticHelp";

const Phonetic = ({ isOpen, token, block, closeHandler, project, updateSpinner }) => {
  const [editPhoneticOpened, setEditPhoneticOpened] = useState(false);
  const [phonticHelpOpened, setPhonticHelpOpened] = useState(false);
  const [updatePhonetic, setUpdatePhonetic] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="phonetic">
      <div className="modal-container" onClick={closeHandler}>
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <PhoneticHelp
            updateSpinner={updateSpinner}
            isOpen={phonticHelpOpened}
            closeHandler={() => {
              setPhonticHelpOpened(false);
            }}
          />

          <EditPhonetic
            closeHandler={(save) => {
              if (save && editPhoneticOpened.phonetic.phoneticText !== "" && (editPhoneticOpened.phonetic.phoneticTranscription !== "" || editPhoneticOpened.phonetic.phoneticIPA !== "")) {
                let index = editPhoneticOpened.phonetic.index;

                if (index >= 0) {
                  project.phonetics[index].phoneticText = editPhoneticOpened.phonetic.phoneticText;
                  project.phonetics[index].phoneticTranscription = editPhoneticOpened.phonetic.phoneticTranscription;
                  project.phonetics[index].phoneticIPA = editPhoneticOpened.phonetic.phoneticIPA;
                } else {
                  project.phonetics.push({
                    phoneticText: editPhoneticOpened.phonetic.phoneticText,
                    phoneticTranscription: editPhoneticOpened.phonetic.phoneticTranscription,
                    phoneticIPA: editPhoneticOpened.phonetic.phoneticIPA,
                  });
                }
              }

              setEditPhoneticOpened(false);
            }}
            isOpen={editPhoneticOpened}
            block={block}
            updateSpinner={updateSpinner}
            token={token}
            phonetics={project.phonetics}
          />

          <div className="close-btn" onClick={closeHandler}>
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>
          <div style={{ margin: "60px auto 10px auto", textAlign: "center" }}>
            <h2>Phonetics</h2>
            <button
              className="button"
              onClick={() => {
                setEditPhoneticOpened({
                  isOpen: true,
                  phonetic: {
                    phoneticText: "",
                    phoneticTranscription: "",
                    phoneticIPA: "",
                    index: -1,
                    audioBuffer: null,
                    voice_public_name: block ? block.voice_public_name : "",
                  },
                });
              }}
            >
              Add Phonetic
            </button>

            <button
              className="button"
              onClick={() => {
                setPhonticHelpOpened({ isOpen: true });
              }}
            >
              Get Help
            </button>
          </div>
          <div>
            <table className="table">
              <tbody>
                <tr>
                  <th>Text to convert</th>
                  <th>Phonetic match</th>
                  <th>IPA phonetic</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr style={{ height: "10px" }}></tr>
                {project?.phonetics.map((phonetic, i) => (
                  <tr key={i} className="table-tr">
                    <td>{phonetic.phoneticText}</td>
                    <td>{phonetic.phoneticTranscription}</td>
                    <td>{phonetic.phoneticIPA}</td>
                    <td style={{ width: "80px" }}>
                      <img
                        alt="edit"
                        src="./img/edit_white.png"
                        onClick={() => {
                          let index = project.phonetics.indexOf(phonetic);
                          if (index >= 0) {
                            setEditPhoneticOpened({
                              isOpen: true,
                              phonetic: {
                                phoneticText: phonetic.phoneticText,
                                phoneticTranscription: phonetic.phoneticTranscription,
                                phoneticIPA: phonetic.phoneticIPA,
                                index: index,
                                audioBuffer: null,
                                voice_public_name: block ? block.voice_public_name : "",
                              },
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ width: "80px" }}>
                      <img
                        alt="delete"
                        src="./img/delete_white.png"
                        onClick={() => {
                          let index = project.phonetics.indexOf(phonetic);
                          if (index >= 0) {
                            project.phonetics.splice(index, 1);
                            setUpdatePhonetic(!updatePhonetic);
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Phonetic;
