/*2023-01-06
- Refaire les voices files adaptés à Voiceover et non à Fonetic
- Menu music, document libre de droits
- Edge, SharedArrayBuffer
- Promo Plugin Dubbing sur le site WordPress
- essayer d'accélérer ffmpeg lorsqu'ils y a des cuts.
*/

import React, { useState, useRef, useEffect } from "react";

//import Navigation from "../components/Navigation";
//import { useDropzone } from "react-dropzone";
import CanvasBlock from "../components/CanvasBlock";
import Toolbar from "../components/Toolbar";
import SelectVoice from "../components/SelectVoice";
import Phonetic from "../components/Phonetic";
import { newProject, loadProject, saveProject, generateGuid, loadSubtitleTextFile, loadSubtitleFile } from "../js/Project";
import { computMix, computVideo, loadAudioFile, loadVideoFile, updateAudioBuffer, computSubtitles } from "../js/Audio";
import Block from "../components/Block";
import Spinner from "../components/Spinner";
import Settings from "../components/Settings";
import Input from "../components/Input";
import EditBlock from "../components/EditBlock";
import { FileUploader } from "react-drag-drop-files";
import AppContext from "../js/AppContext";
import Login from "../components/Login";
import Translate from "../components/Translate";
import { logon, textToSpeech, textToTranslate } from "../js/Api";
import { processBlockBuffer } from "../js/Project";
import { translate } from "../js/Translation";

import tuto_fjord_fr from "../assets/fjord_fr.zip";
import tuto_fjord_en from "../assets/fjord_en.zip";
import futurbeats_fr from "../assets/futurbeats_fr.zip";
import futurbeats_en from "../assets/futurbeats_en.zip";

const apiAddress = "https://imea-studio.com/";

const Voiceover = () => {
  const mode = AppContext.mode;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [videoSrc, setVideoSrc] = useState("");
  const [currentTime] = useState({
    time: 0,
    timestamp: 0,
    playing: 0,
    animateTime: 0,
    updateAudio: false,
  });
  const [project, setProject] = useState(newProject());
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [pixelPerSecond, setPixelPerSecond] = useState(1);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectVoiceOpened, setSelectVoiceOpened] = useState("");
  const [selectTranslateOpened, setSelectTranslateOpened] = useState("");
  const [phoneticOpened, setPhoneticOpened] = useState(false);
  const [spinnerValues, setSpinnerValues] = useState({
    visible: false,
    info: "",
    percent: "",
    updateAudio: false,
  });

  const [loginOpened, setLoginOpened] = useState(false);
  const [userToken, setUserToken] = useState({ token: localStorage.getItem("vo_token"), voiceover_char_credit: localStorage.getItem("voiceover_char_credit") });
  const [dragFileHidden, setDragFileHidden] = useState(true);

  let audioCtx = null;

  const videoRef = useRef(null);
  const blockZoneRef = useRef(null);

  const [update, setUpdate] = useState(false);
  const [totalCharNumber, setTotalCharNumber] = useState(0);

  const [toolbarMenuOpened, setToolbarMenuOpened] = useState(false);

  const [scrollLeft, setScrollLeft] = useState();
  useEffect(() => {
    if (blockZoneRef.current) {
      blockZoneRef.current.scrollLeft = scrollLeft;
    }
  }, [scrollLeft]);

  const updateProject = (p) => {
    p.loading = true;
    setZoom(1);
    currentTime.time = 0;
    currentTime.playing = 0;
    setProject(p);
  };

  const updateTotalCharNumber = () => {
    let charNumber = 0;
    project.blocks.forEach((block) => {
      charNumber += block.text.length;
    });

    setTotalCharNumber(charNumber);
  };

  navigator.browserDetection = (function () {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  })();

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [unsavedChanges]);

  // useEffect(() => {
  //   function tick() {
  //     if (spinnerValues.timeoutCompter > 0) {
  //       spinnerValues.timeoutCompter--;
  //       if (spinnerValues.timeoutCompter === 0) spinnerValues.visible = false;
  //     }
  //   }

  //   const id = setInterval(tick, 5000);
  //   return () => clearInterval(id);
  // }, []);

  useEffect(() => {
    if (project.loading) {
      project.loading = false;
      if (project.videoData) setVideoSrc(URL.createObjectURL(project.videoData));
    }
  }, [project]);

  const openTuto = async (tuto, name) => {
    let result = await fetch(tuto);
    const blob = await result.blob();
    const file = new File([blob], name);
    openFile(file);
  };

  useEffect(() => {
    logon(setUserToken);

    if (AppContext.project && AppContext.project !== "") {
      if (AppContext.project === "tuto_fjord_fr") openTuto(tuto_fjord_fr, "fjord_fr.zip");
      else if (AppContext.project === "tuto_fjord_en") openTuto(tuto_fjord_en, "fjord_en.zip");
      else if (AppContext.project === "futurbeats_en") openTuto(futurbeats_en, "futurbeats_en.zip");
      else if (AppContext.project === "futurbeats_fr") openTuto(futurbeats_fr, "futurbeats_fr.zip");
    }
  }, []);

  function updateSpinner(values) {
    setSpinnerValues(values);
  }

  async function updateAudio() {
    updateSpinner({ visible: true, info: "UPDATE AUDIO", percent: "" });
    await setTimeout(() => {
      updateAudioBuffer(project);
      updateSpinner({ visible: false, info: "", percent: "" });
    }, 10);
  }

  function updateDuration() {
    let durationEx = 0;
    if (videoRef.current) {
      durationEx = videoRef.current.duration;
      project.videoDuration = videoRef.current.duration;
    }

    for (let i = 0; i < project.cuts.length; i++) {
      let cut = project.cuts[i];
      if (cut.time_start >= durationEx) break;

      if (cut.expand) durationEx += cut.time_end - cut.time_start;
      else durationEx -= cut.time_end - cut.time_start;
    }

    if (durationEx < 0) durationEx = 0;

    if (durationEx !== project.duration) {
      project.duration = durationEx;

      setUpdate(!update);
    }
  }

  async function updateTime(time) {
    let playing = currentTime.playing;
    await pause();

    currentTime.time = time;
    updateVideoCurrentTime();
    setUpdate(!update);

    if (playing) await play();
  }

  function updateVideoCurrentTime() {
    let currentTimeEx = currentTime.time;
    let expandTime = 0;
    for (let i = 0; i < project.cuts.length; i++) {
      if (currentTimeEx < project.cuts[i].time_start) break;
      if (project.cuts[i].expand) {
        if (currentTimeEx < project.cuts[i].time_end) {
          expandTime -= currentTimeEx - project.cuts[i].time_start;
          break;
        }
        expandTime -= project.cuts[i].time_end - project.cuts[i].time_start;
      } else {
        expandTime += project.cuts[i].time_end - project.cuts[i].time_start;
      }
    }
    let newTime = currentTimeEx + expandTime;
    if (videoRef.current.currentTime !== newTime) {
      videoRef.current.currentTime = newTime;
    }
  }

  async function play() {
    if (currentTime.updateAudio) updateSpinner({ visible: true, info: "UPDATE AUDIO", percent: "" });

    setTimeout(() => {
      if (currentTime.updateAudio) {
        currentTime.updateAudio = false;
        updateAudioBuffer(project);
        updateSpinner({ visible: false, info: "", percent: "" });
      }

      let timer = setInterval(function () {
        if (videoRef.current.readyState === 4) {
          currentTime.timestamp = new Date().getTime() / 1000 - currentTime.time;
          setAnimationFrameId(window.requestAnimationFrame(animate));
          currentTime.playing = 1;
          videoRef.current.play();

          if (audioCtx == null) audioCtx = new (window.AudioContext || window.webkitAudioContext)();

          project?.blocks.forEach((block) => {
            let offset = 0;
            for (let i = 0; i < project.cuts.length; i++) {
              if (project.cuts[i].time_start > block.time_start - offset) break;
              if (!project.cuts[i].expand) offset += project.cuts[i].time_end - project.cuts[i].time_start;
            }

            let blockTimeStart = block.time_start - offset;
            let blockTimeEnd = block.time_end - offset;

            if (block.audioBuffer && currentTime.time < blockTimeEnd) {
              block.audioBufferSource = audioCtx.createBufferSource();
              block.audioBufferSource.buffer = block.audioBuffer;
              block.audioBufferSource.connect(audioCtx.destination);
              let when = blockTimeStart - currentTime.time;
              if (when >= 0) {
                block.audioBufferSource.start(audioCtx.currentTime + when, 0, block.time_end - block.time_start);
              } else {
                block.audioBufferSource.start(0, -when, block.time_end - block.time_start);
              }
            }
          });

          if (project.audioBuffer) {
            let offset = 0;
            project.audioBufferSource = audioCtx.createBufferSource();
            project.audioBufferSource.buffer = project.audioBuffer;
            project.audioBufferSource.connect(audioCtx.destination);
            project.audioBufferSource.start(0, Math.max(0, currentTime.time - offset));
          }

          clearInterval(timer);
        }
      }, 1);
    }, 10);
  }

  function pause() {
    videoRef.current.pause();
    window.cancelAnimationFrame(animationFrameId);
    setAnimationFrameId(null);
    currentTime.playing = 0;

    project?.blocks.forEach((block) => {
      if (block.audioBuffer && block.audioBufferSource) block.audioBufferSource.stop(0);
      block.audioBufferSource = null;
    });

    if (project.audioBufferSource) {
      project.audioBufferSource.stop(0);
      project.audioBufferSource = null;
    }
  }

  const animate = () => {
    if (currentTime.playing === 1) {
      window.cancelAnimationFrame(animationFrameId);
      currentTime.time = new Date().getTime() / 1000 - currentTime.timestamp;

      let timeEx = currentTime.time;
      let expand = 0;
      let pauseEx = false;
      for (let i = 0; i < project.cuts.length; i++) {
        if (project.cuts[i].expand) {
          expand -= project.cuts[i].time_end - project.cuts[i].time_start;
          if (timeEx >= project.cuts[i].time_start && timeEx < project.cuts[i].time_end) {
            pauseEx = true;
            if (!videoRef.current.paused) {
              videoRef.current.pause();
            }
          }
        } else {
          expand += project.cuts[i].time_end - project.cuts[i].time_start;
          if (timeEx >= project.cuts[i].time_start && timeEx < project.cuts[i].time_end && currentTime.animateTime < project.cuts[i].time_start) {
            videoRef.current.currentTime = timeEx + expand;

            if (i < project.cuts.length - 1) {
              if (project.cuts[i + 1].expand && project.cuts[i + 1].time_start < project.cuts[i].time_end + 10) {
                pauseEx = true;
              }
            }

            //break;
          }
        }
      }

      if (!pauseEx && videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      }

      if (currentTime.time >= project.duration) {
        pause();
        return;
      }

      //  if (videoRef.current) console.log(videoRef.current.readyState);

      currentTime.animateTime = currentTime.time;
      setAnimationFrameId(window.requestAnimationFrame(animate));
    }
  };

  const handleChange = (file) => {
    openFile(file);
  };

  const inputFileRef = React.useRef();
  const onFileChangeCapture = (e) => {
    openFile(e.target.files[0]);
    e.target.value = null;
  };

  async function openFile(file) {
    pause();
    const ext = file.name.split(".").pop();
    if (ext === "zip") {
      if (unsavedChanges) setInputProjectNameOpened({ open: true, unsavedChanges: true });
      (async () => {
        try {
          updateSpinner({ visible: true, info: "LOAD PROJECT", percent: "" });
          let p = await loadProject(file, updateSpinner, mode);
          updateProject(p);
          updateDuration();
          updateSpinner({ visible: false, info: "", percent: "" });
        } catch (err) {}
      })();
    } else if (file.type.toLowerCase().startsWith("video")) {
      updateSpinner({ visible: true, info: "LOAD VIDEO FILE", percent: "" });
      await loadVideoFile(file, project);
      setVideoSrc(URL.createObjectURL(project.videoData));
      updateSpinner({ visible: false, info: "", percent: "" });
    } else if (file.type.toLowerCase().startsWith("audio")) {
      updateSpinner({ visible: true, info: "LOAD AUDIO FILE", percent: "" });
      updateDuration();
      await loadAudioFile(file, project);
      updateAudioBuffer(project);
      setUpdate(!update);
      updateSpinner({ visible: false, info: "", percent: "" });
    } else if (ext === "vtt" || ext === "srt") {
      updateSpinner({ visible: true, info: "LOAD TEXT FILE", percent: "" });
      await loadSubtitleFile(file, project);
      updateDuration();
      setUpdate(!update);
      updateSpinner({ visible: false, info: "", percent: "" });
    } else if (ext === "txt") {
      updateSpinner({ visible: true, info: "LOAD TEXT FILE", percent: "" });
      await loadSubtitleTextFile(file, project);
      updateDuration();
      setUpdate(!update);
      updateSpinner({ visible: false, info: "", percent: "" });
    }
  }

  /*const onDrop = useCallback((acceptedFiles) => {
    console.log(project);
    openFile(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
*/

  const [settingsOpened, setSettingsOpened] = useState(false);
  const handleSettingsChange = (e) => {
    let { name, value } = e.target;
    if (name === "audioVideoMute") value = !project.audioVideoMute;
    if (name === "audioFileMute") value = !project.audioFileMute;
    if (name === "audioFadeInFadeOut") value = !project.audioFadeInFadeOut;

    setProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const [editBlockOpened, setEditBlockOpened] = useState({
    block: null,
    opened: false,
  });

  const [blockSelected, setBlockSelected] = useState(null);
  const [inputProjectNameOpened, setInputProjectNameOpened] = useState({ open: false, unsavedChanges: false });

  const [languages, setLanguages] = useState();
  useEffect(() => {
    async function getLanguages() {
      const data = await (
        await fetch(apiAddress + "vo-get-languages.php?token=8gs34lxp6w9m082H4q721-45sx", {
          method: "GET",
        })
      ).json();

      setLanguages(data["languages"]);
    }

    getLanguages();
  }, []);

  function addBlock(offsetX) {
    let start = offsetX / pixelPerSecond;
    let offset = 0;
    for (let i = 0; i < project.cuts.length; i++) {
      if (project.cuts[i].time_start > start) break;
      if (!project.cuts[i].expand) offset += project.cuts[i].time_end - project.cuts[i].time_start;
    }

    start += offset;
    let voice_public_name = "";
    let language_name = "";
    let index = -1;
    for (let i = 0; i < project.blocks.length; i++) {
      if (project.blocks[i].time_start >= start) break;

      index = i;
    }

    if (index === -1) {
      if (project.blocks.length > 0) {
        voice_public_name = project.blocks[0].voice_public_name;
        language_name = project.blocks[0].language_name;
      }
    } else {
      voice_public_name = project.blocks[index].voice_public_name;
      language_name = project.blocks[index].language_name;
    }

    const id = generateGuid();

    project.blocks.push({
      id: id,
      voice_public_name: voice_public_name,
      language_name: language_name,
      text: "",
      time_start: start,
      time_end: start + 2,
      waveform: null,
    });

    let block = project.blocks[project.blocks.length - 1];
    project.blocks.sort(function (a, b) {
      return a.time_start - b.time_start;
    });

    setUpdate(!update);
    onEditBlock(block);
  }

  function onEditBlock(block) {
    if (block.language !== "" && block.language_name !== "") {
      const index = languages.findIndex((b) => b.language === block.language_name);
      if (index >= 0) {
        block.language = { code: languages[index].code, language: languages[index].language, flag: languages[index].flag };
        //block.language = { code: "fr-FR", language: "FR - French", flag: "fr" };
        //block.language_name = "FR - French";
      }
    }

    setEditBlockOpened({
      project: project,
      block: block,
      opened: true,
      updateSpinner: updateSpinner,
    });
    let timer = setInterval(function () {
      setBlockSelected(block);

      clearInterval(timer);
    }, 1000);
  }
  function addCut(expand, offsetX) {
    let start = currentTime.time;

    const id = generateGuid();
    const range = 1;

    project.cuts.push({
      id: id,
      expand: expand,
      time_start: start,
      time_end: start + range,
    });

    project.cuts.sort(function (a, b) {
      return a.time_start - b.time_start;
    });

    const index = project.cuts.findIndex((c) => c.id === id);

    if (expand) {
      for (let i = index + 1; i < project.cuts.length; i++) {
        project.cuts[i].time_start += range;
        project.cuts[i].time_end += range;
      }
    } else {
      for (let i = index + 1; i < project.cuts.length; i++) {
        project.cuts[i].time_start -= range;
        project.cuts[i].time_end -= range;
      }
    }

    updateDuration();
    updateAudio();
  }

  async function translatAllTexts(language_dest) {
    if (language_dest.code.length < 2) return;

    let language_dest_ex = language_dest.code.substring(0, 2);

    updateSpinner({ visible: true, info: "PROCESS TRANSLATION", percent: "" });

    for (let i = 0; i < project.blocks.length; i++) {
      if (project.blocks[i].language_name.length > 1 && project.blocks[i].text !== "") {
        let language_src = project.blocks[i].language_name.substring(0, 2).toLowerCase();
        let result = await textToTranslate(userToken.token, project.blocks[i].text, language_src, language_dest_ex);

        if (result.message !== "" || result.translation !== "") {
          project.blocks[i].text = result.translation;
          project.blocks[i].language_name = language_dest.language;

          project.blocks[i].mp3Buffer = null;
          project.blocks[i].waveform = null;
          project.blocks[i].buffer_ready = false;
          project.blocks[i].data = null;
          project.blocks[i].voice_public_name = "";
        }
      }

      updateSpinner({ visible: true, info: "PROCESS TRANSLATION", percent: Math.floor((i * 100) / project.blocks.length).toString() + "%" });
    }

    const apiAddress = "https://fonetic.be/php/";
    const data = await (
      await fetch(apiAddress + "vo-get-account-ex.php?token=" + userToken.token, {
        method: "GET",
      })
    ).json();

    userToken.voiceover_char_credit = data["voiceover_char_credit"];

    updateSpinner({ visible: false, info: "", percent: "" });
  }

  async function updateAllvoices(voice_public_name, language) {
    updateSpinner({ visible: true, info: "PROCESS VOICEOVER", percent: "" });

    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    for (let i = 0; i < project.blocks.length; i++) {
      project.blocks[i].voice_public_name = voice_public_name;
      project.blocks[i].language = language;
      project.blocks[i].language_name = language.language;
      if (project.blocks[i].text !== "") {
        let text = project.blocks[i].text;

        if (project.phonetics) {
          project.phonetics.forEach((phonetic) => {
            let textToConvert = "";
            if (phonetic.phoneticText) textToConvert = phonetic.phoneticText.trim();

            if (textToConvert !== "") {
              let textPhonetic = phonetic.phoneticIPA.trim();
              if (textPhonetic !== "") textPhonetic = '<phoneme alphabet="ipa" ph="' + textPhonetic + '"> ' + textToConvert + " </phoneme>";
              else textPhonetic = phonetic.phoneticTranscription.trim();

              if (textPhonetic !== "") {
                const splits = text.split(" ");
                for (let j = 0; j < splits.length; j++) {
                  if (splits[j].toLowerCase() === textToConvert.toLowerCase()) splits[j] = textPhonetic;
                }
                text = splits.join(" ");
              }
            }
          });
        }

        let result = await textToSpeech(userToken.token, text, project.blocks[i].voice_public_name, 0);

        if (result.message === "" && result.buffer !== null) {
          let audioBuffer = new Uint8Array(result.buffer);

          project.blocks[i].mp3Buffer = new Uint8Array(audioBuffer.length);
          for (let j = 0; j < audioBuffer.length; j++) project.blocks[i].mp3Buffer[j] = audioBuffer[j];

          audioCtx.decodeAudioData(result.buffer, function (buff) {
            project.blocks[i].buffer_ready = true;
            project.blocks[i].time_end = project.blocks[i].time_start + buff.duration;
            processBlockBuffer(project.blocks[i], buff, audioCtx);
          });
        }
      }

      updateSpinner({ visible: true, info: "PROCESS VOICEOVER", percent: Math.floor((i * 100) / project.blocks.length).toString() + "%" });
    }

    const apiAddress = "https://fonetic.be/php/";
    const data = await (
      await fetch(apiAddress + "vo-get-account-ex.php?token=" + userToken.token, {
        method: "GET",
      })
    ).json();

    userToken.voiceover_char_credit = data["voiceover_char_credit"];

    updateSpinner({ visible: false, info: "", percent: "" });
  }

  if (navigator.browserDetection.toLowerCase().startsWith("safari")) {
    return (
      <div className="voiceover">
        <div style={{ margin: "50px" }}>{translate("SafariIncompatible")}</div>
      </div>
    );
  }

  return (
    <div>
      {windowHeight < 600 && <h2 style={{ position: "fixed", fontSize: "30px", color: "#ff0", textTransform: "uppercase", left: "50%", transform: "translate(-50%)", zIndex: "100000000" }}>{translate("HeightMustBeGreaterThan")}</h2>}
      <Login
        isOpen={loginOpened}
        token={userToken}
        closeHandler={(token) => {
          setUserToken(token);
          setLoginOpened(false);
        }}
      />
      <SelectVoice
        closeHandler={(public_name) => {
          setSelectVoiceOpened(false);
        }}
        isOpen={selectVoiceOpened}
        totalCharNumber={totalCharNumber}
        updateAllMode={true}
        token={userToken}
        handleVoiceChange={(public_name) => {}}
        onGenerate={(public_name, language) => {
          setSelectVoiceOpened(false);
          updateAllvoices(public_name, language);
        }}
      />

      <Translate
        closeHandler={() => {
          setSelectTranslateOpened(false);
        }}
        isOpen={selectTranslateOpened}
        totalCharNumber={totalCharNumber}
        token={userToken}
        onTranslate={(language_dest) => {
          setSelectTranslateOpened(false);
          translatAllTexts(language_dest);
        }}
      />
      <Input
        title={inputProjectNameOpened.unsavedChanges ? translate("SAVEBEFORELEAVING") : translate("SAVE")}
        valueName="Name"
        buttonName={translate("SAVE")}
        buttonNameSecond={inputProjectNameOpened.unsavedChanges ? translate("NO") : ""}
        description={inputProjectNameOpened.unsavedChanges ? translate("SaveTheProjectBeforeLeavingThePageSoAsNotToLoseTheModifications") : translate("TheProjectWillBeSavedLocallyToTheDestinationOfYourDownloads")}
        defaultValue={project.name}
        closeHandler={() => {
          setInputProjectNameOpened({ open: false, unsavedChanges: false });
        }}
        buttonHandler={(e) => {
          if (e) project.name = e.trim();

          setInputProjectNameOpened({ open: false, unsavedChanges: false });
          saveProject({ project: project, updateSpinner: updateSpinner });
          setUnsavedChanges(false);
        }}
        buttonSecondHandler={(e) => {
          setUnsavedChanges(false);
          setInputProjectNameOpened({ open: false, unsavedChanges: false });
        }}
        isOpen={inputProjectNameOpened.open}
      />
      <EditBlock
        closeHandler={() => {
          setEditBlockOpened(false);
        }}
        isOpen={editBlockOpened}
        blockSelected={blockSelected}
        updateSpinner={(values) => {
          updateSpinner(values);
        }}
        token={userToken}
        toolbarMenuOpened={toolbarMenuOpened}
      />
      <Settings
        closeHandler={() => {
          setSettingsOpened(false);
          updateAudio();
        }}
        isOpen={settingsOpened}
        project={project}
        handleSettingsChange={handleSettingsChange}
      />
      {/* <Navigation
        unsavedChanges={unsavedChanges}
        updateUnsavedChanges={(e) => {
          setInputProjectNameOpened({ open: true, unsavedChanges: true });
        }}
      /> */}
      <div
        className="voiceover"
        onMouseMove={(e) => {
          setDragFileHidden(e.clientY > videoRef.current.clientHeight || toolbarMenuOpened);
        }}
      >
        <input hidden type="file" ref={inputFileRef} onChangeCapture={onFileChangeCapture} />

        <Spinner values={spinnerValues}></Spinner>

        <Phonetic
          closeHandler={() => {
            setPhoneticOpened(false);
          }}
          isOpen={phoneticOpened}
          project={project}
          block={blockSelected}
          token={userToken}
          updateSpinner={(values) => {
            updateSpinner(values);
          }}
        />

        <div
          className={"input-zone"}
          style={{
            opacity: videoSrc && dragFileHidden ? "0.0" : "1.0",
            display: toolbarMenuOpened ? "none" : "block",
          }}
        >
          {!videoSrc && <h1>{mode === "tuto" ? "TUTORIAL MAKER" : "VOICE ON VIDEO"}</h1>}
          <div>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={["zip", "mp4", "m4a", "mov", "mp3", "wav", "txt", "srt", "vtt"]}
              children=<div className="drop-zone">
                <p>Drag & drop some files here or click to select files</p>
                <p>Project (ZIP)</p>
                <p>Video (MP4, MOV)</p>
                <p>Audio (MP3, WAV)</p>
                <p>Subtitles (TXT, SRT, VTT)</p>
              </div>
            />
          </div>
          <p style={{ color: "#fff" }}>
            {project.name}
            {project.duration > 0 ? " (" + Math.floor(project.duration / 60).toString() + ":" + (Math.floor(project.duration) % 60).toString().padStart(2, "0") + ")" : ""}
          </p>
        </div>
        <div
          className={"edit-zone"}
          style={
            {
              //opacity: videoSrc && dragFileHidden ? "1.0" : "0.2",
            }
          }
        >
          <div className={videoSrc ? "none" : "hidden"}>
            <video
              className="video"
              ref={videoRef}
              src={videoSrc}
              muted
              //controls
              onLoadedData={(event) => {
                updateDuration();
                updateAudioBuffer(project);
                setUpdate(!update);
              }}
            ></video>
          </div>
          <div className="block-div" ref={blockZoneRef} style={{ margin: "auto", backgroundColor: "inherit" }}>
            <div className="block-zone ">
              {project?.blocks.map((block, i) => (
                <Block
                  key={i}
                  block={block}
                  update={(ctrlKey) => {
                    /*if (ctrlKey) {
                      const index = project.blocks.findIndex((b) => b.id === block.id);
                      if (index >= 0 && index < project.blocks.length - 1) {
                        for (let k = index; k < project.blocks.length - 1; k++) {
                          if (project.blocks[k].time_end > project.blocks[k + 1].time_start) {
                            let timeStart = project.blocks[k + 1].time_start;
                            project.blocks[k + 1].time_start = project.blocks[k].time_end;
                            project.blocks[k + 1].time_end += project.blocks[k + 1].time_start - timeStart;
                          }
                        }
                      }
                    }*/

                    //currentTime.time = block.time_start;
                    currentTime.updateAudio = true;
                    project.blocks.sort(function (a, b) {
                      return a.time_start - b.time_start;
                    });

                    setUnsavedChanges(true);
                    setUpdate(!update);
                  }}
                  move={(ctrlKey) => {
                    if (!ctrlKey) {
                      const index = project.blocks.findIndex((b) => b.id === block.id);
                      if (index >= 0 && index < project.blocks.length - 1) {
                        for (let k = index; k < project.blocks.length - 1; k++) {
                          if (project.blocks[k].time_end > project.blocks[k + 1].time_start) {
                            let timeStart = project.blocks[k + 1].time_start;
                            project.blocks[k + 1].time_start = project.blocks[k].time_end;
                            project.blocks[k + 1].time_end += project.blocks[k + 1].time_start - timeStart;
                            project.blocks[k + 1].blockRef.current.style.left = project.blocks[k + 1].time_start * pixelPerSecond + "px";
                          }
                        }
                      }
                    }
                  }}
                  pixelPerSecond={pixelPerSecond}
                  onEdit={(event) => {
                    onEditBlock(block);
                  }}
                  onDelete={() => {
                    const index = project.blocks.findIndex((b) => b.id === block.id);
                    if (index >= 0) {
                      project.blocks.splice(index, 1);
                      setUnsavedChanges(true);
                      setUpdate(!update);
                    }
                  }}
                ></Block>
              ))}
            </div>

            <CanvasBlock
              project={project}
              currentTime={currentTime}
              zoom={zoom}
              update={update}
              updateAudio={() => {
                setUnsavedChanges(true);
                updateDuration();
                currentTime.updateAudio = true;
              }}
              videoDuration={videoRef.current?.duration}
              blockZoneRef={blockZoneRef}
              updatePixelPerSecond={(event) => {
                setPixelPerSecond(event);
              }}
              currentTimeChange={(event) => {
                updateTime(event);
              }}
              deleteCut={(index) => {
                if (index >= 0) {
                  let delta = project.cuts[index].time_end - project.cuts[index].time_start;
                  if (project.cuts[index].expand) delta = -delta;
                  for (let i = index + 1; i < project.cuts.length; i++) {
                    project.cuts[i].time_start += delta;
                    project.cuts[i].time_end += delta;
                  }

                  project.cuts.splice(index, 1);
                  setUnsavedChanges(true);
                  updateDuration();
                  updateAudio();
                }
              }}
            />
          </div>
          <div style={{ clear: "both" }}></div>
          <div className="toolbar">
            <Toolbar
              mode={mode}
              userToken={userToken}
              project={project}
              login={() => {
                setLoginOpened(true);
              }}
              logout={() => {
                setUserToken({ token: "", voiceover_char_credit: 0 });
                localStorage.setItem("vo_token", "");
                localStorage.setItem("voiceover_char_credit", 0);
              }}
              setMenuOpened={(event) => {
                setToolbarMenuOpened(event);
              }}
              visible={videoSrc && dragFileHidden}
              playing={currentTime.playing}
              zoom={zoom}
              onPlay={(event) => {
                play();
              }}
              onPause={(event) => {
                pause();
              }}
              onRewind={(event) => {
                currentTime.time = 0;
                updateTime(0);

                if (blockZoneRef.current) blockZoneRef.current.scrollLeft = 0;
              }}
              onAddBlock={(event) => {
                addBlock(currentTime.time * pixelPerSecond);
              }}
              onAddCut={(event) => {
                addCut(false, currentTime.time * pixelPerSecond);
              }}
              onAddExpand={(event) => {
                addCut(true, currentTime.time * pixelPerSecond);
              }}
              onZoomM={(event) => {
                setScrollLeft(blockZoneRef.current.scrollLeft / 2 - blockZoneRef.current.clientWidth / 4);
                setZoom(zoom / 2);
              }}
              onZoomP={(event) => {
                setScrollLeft(blockZoneRef.current.scrollLeft * 2 + blockZoneRef.current.clientWidth / 2);
                setZoom(zoom * 2);
              }}
              onSelectFile={(event) => {
                inputFileRef.current.click();
              }}
              onNewProject={(event) => {
                setVideoSrc("");
                updateProject(newProject);
              }}
              onSaveProject={(event) => {
                setInputProjectNameOpened({ open: true, unsavedChanges: false });
              }}
              onExportAudioWave={(event) => {
                computMix(
                  {
                    project: project,
                    sampleRate: 44100,
                    channelNumber: 2,
                    updateSpinner: updateSpinner,
                  },
                  false,
                  true
                );
              }}
              onExportAudioMp3={(event) => {
                computMix(
                  {
                    project: project,
                    sampleRate: 44100,
                    channelNumber: 2,
                    updateSpinner: updateSpinner,
                  },
                  true,
                  true
                );
              }}
              onExportVideo={(event) => {
                /*if (unsavedChanges) setInputProjectNameOpened({ open: true, unsavedChanges: true });
                else {*/
                computVideo({
                  project: project,
                  duration: videoRef?.current?.duration,
                  sampleRate: 44100,
                  channelNumber: 2,
                  updateSpinner: updateSpinner,
                });
                //}
              }}
              onExportSubtitlesVtt={(event) => {
                computSubtitles(project, true, true);
              }}
              onExportSubtitlesSrt={(event) => {
                computSubtitles(project, false, true);
              }}
              onSettings={(event) => {
                setSettingsOpened(true);
              }}
              onPhonetic={(event) => {
                setPhoneticOpened(true);
              }}
              onSelectTranslate={(event) => {
                updateTotalCharNumber();
                setSelectTranslateOpened(true);
              }}
              onSelectVoice={(event) => {
                updateTotalCharNumber();
                setSelectVoiceOpened(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voiceover;
