import React, { useState } from "react";

const Input = ({ isOpen, closeHandler, buttonHandler, buttonSecondHandler, title, description, valueName, buttonName, buttonNameSecond, defaultValue }) => {
  const [textAreaValue, setTextAreaValue] = useState();

  if (!isOpen) return null;

  const onTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  const onButtonClick = (e) => {
    e.preventDefault();
    buttonHandler(textAreaValue);
  };

  const onButtonSecondClick = (e) => {
    e.preventDefault();
    buttonSecondHandler(textAreaValue);
  };

  return (
    <div className="input">
      <div className="modal-container" onClick={closeHandler}>
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div className="close-btn" onClick={closeHandler}>
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>

          <h2>{title}</h2>

          <div>
            <p>{description}</p>
            <div style={{ height: "20px" }}></div>

            <div>
              <div className="field">
                <label className="label-field">{valueName}</label>
                <input
                  type="text"
                  autoComplete="off"
                  required
                  className="field-textarea"
                  spellCheck="false"
                  onChange={onTextAreaChange}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") onButtonClick(e);
                  }}
                  defaultValue={defaultValue}
                />
              </div>
              <div style={{ height: "20px" }}></div>
            </div>

            <button className="btn-ok" onClick={onButtonClick}>
              {buttonName}
            </button>
            {buttonNameSecond && buttonNameSecond !== "" && (
              <button className="btn-ok" onClick={onButtonSecondClick}>
                {buttonNameSecond}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
