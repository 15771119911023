import React, { useRef, useState } from "react";
import { getSecurityNumber } from "../js/Api";

const Recaptcha = ({ language, updateVerify }) => {
  const audioRef = useRef();
  const [number, setNumber] = useState(0);

  async function generateNumber() {
    const resp = await getSecurityNumber(language);
    if (resp.blob) {
      setNumber(resp.number);
      const objectURL = URL.createObjectURL(resp.blob);
      audioRef.current.src = objectURL;
      audioRef.current.play();
    }
  }

  return (
    <div className="recaptcha">
      <audio ref={audioRef} onEnded={() => {}} />

      <div>Enter the number you hear when you click on</div>

      <div style={{ margin: "20px", marginTop: "-5px" }}>
        <img
          src="./img/hearing_white.png"
          className="btn-icon"
          alt="ear"
          onClick={() => {
            generateNumber();
          }}
        />
      </div>
      <div>
        <input
          type="text"
          className="text-area"
          spellCheck="false"
          onKeyUp={(e) => {
            updateVerify(number === e.target.value);
          }}
          placeholder="number"
        ></input>
      </div>
    </div>
  );
};

export default Recaptcha;
