import React from "react";
import Navigation from "../components/Navigation";

const Contact = () => {
  return (
    <div>
      <Navigation />

      <div className="contact">
        <h2>Contact us</h2>
        <img
          alt="contact"
          src="./img/background-g07243ea35_1920.png"
          style={{ width: "300px" }}
        />

        <div className="field-form">
          <p>
            Do not hesitate to contact us if you have special requests such as
            the automation of the creation of messages for a large number of
            mobiles, creation of messages for voice servers...
          </p>
          <div style={{ height: "30px" }}></div>

          <div>
            <div className="field">
              <label className="label-field">Name</label>
              <textarea
                id="imc_textareaName"
                className="field-textarea"
                spellCheck="false"
              ></textarea>
            </div>

            <div className="field">
              <label className="label-field">Email</label>
              <textarea
                id="imc_textareaEmail"
                className="field-textarea"
                spellCheck="false"
              ></textarea>
            </div>

            <div className="field">
              <label className="label-field">Message</label>
              <textarea
                id="imc_textareaMessage"
                className="field-textarea"
                style={{ height: "80px" }}
                spellCheck="false"
              ></textarea>
            </div>

            <div style={{ height: "20px" }}></div>
            {/* <div hidden id="imc_SecuritId">
                    <div style={{display: "flex", justifyContent: "center">
                        <div><?php echo _ContactSecurityInfo ?></div>
                        <div style="margin: 20px; margin-top: -5px;"><img class="btn-icon" alt="" src="images/dark/hearing.png" onclick="imc_onGenerateNumber()" alt="ear"></div>
                        <div><textarea id="imc_SecurityNumberId" class="contact-textarea" style="height: 34px; width: 100px; margin-top: -10px; text-align: center;" spellcheck="false" onkeyup="imc_updateSendButton()" placeholder="nombre"></textarea></div>
                    </div>
                </div>
                
               
                <div style="clear: both; margin: auto; text-align: center; margin-top: 10px;">
                    <button hidden  id="imc_SendButtonId" class="btn-ok" style="margin: auto;" onclick="imc_onSend()"><?php echo _ContactButtonSend ?></button>
                    <p hidden id="imc_InfoId" style="margin: 10px; font-size: 14px; font-style: italic;"></p>
                </div>

                <div style="height: 20px;"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
