import React, { useEffect, useState, useRef } from "react";
import Player from "../components/Player";
import Recaptcha from "../components/Recaptcha";
import {
  createMp3File,
  loadAudioFile,
  processAudioBuffer,
  toWaveFileBuffer,
  trimAudioBuffer,
} from "../js/Audio";
import { getPhoneticHelp } from "../js/Api";

const PhoneticHelp = ({ isOpen, closeHandler, updateSpinner }) => {
  const [textAreaPhoneticHelpEmail, setTextAreaPhoneticHelpEmail] =
    useState("");
  const [
    textAreaPhoneticHelpInstructions,
    setTextAreaPhoneticHelpInstructions,
  ] = useState("");
  const [verify, setVerify] = useState(false);
  const [emailReady, setEmailReady] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const playerRef = useRef(null);
  const [phonetic, setPhonetic] = useState({
    audioBuffer: null,
    waveform: null,
  });
  const helpInputFileRef = React.useRef();

  const [recording, setRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState();

  useEffect(() => {}, []);

  useEffect(() => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setEmailReady(emailRegex.test(textAreaPhoneticHelpEmail));
  }, [textAreaPhoneticHelpEmail]);

  const [menuOpened, setMenuOpened] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  if (!isOpen) return null;

  const onFileChangeCapture = (e) => {
    openFile(e.target.files[0]);
    e.target.value = null;
  };

  function init() {
    setPhonetic({
      recording: false,
      audioBuffer: null,
      waveform: null,
      time_start: 0,
      time_end: 0,
    });
    setVerify(false);
    setEmailReady(false);
    setEmailSent(false);
    setTextAreaPhoneticHelpEmail("");
    setTextAreaPhoneticHelpInstructions("");
  }

  async function send() {
    let mediaData = [];
    mediaData[0] = phonetic.audioBuffer.getChannelData(0);

    const waveFileBuffer = toWaveFileBuffer(
      mediaData,
      1,
      16,
      phonetic.audioBuffer.sampleRate
    );

    const blob = await createMp3File(
      waveFileBuffer,
      { updateSpinner: updateSpinner, project: { name: "phonetic_record" } },
      false
    );

    const resp = await getPhoneticHelp(
      textAreaPhoneticHelpEmail,
      textAreaPhoneticHelpInstructions,
      "en",
      phonetic.audioBuffer,
      blob
    );

    if (resp === "success") setEmailSent(true);
  }

  async function openFile(file) {
    if (file.type.toLowerCase().startsWith("audio")) {
      let project = {};
      await loadAudioFile(file, project);

      setPhonetic({
        recording: false,
        audioBuffer: project.audioFileBuffer,
        waveform: project.audioFileWaveForm,
        time_start: 0,
        time_end: project.audioFileBuffer.duration,
      });
    }
  }

  function exportRecord() {
    let mediaData = [];
    mediaData[0] = trimAudioBuffer(phonetic.audioBuffer).getChannelData(0);

    const waveFileBuffer = toWaveFileBuffer(
      mediaData,
      1,
      16,
      phonetic.audioBuffer.sampleRate
    );

    createMp3File(
      waveFileBuffer,
      { updateSpinner: updateSpinner, project: { name: "phonetic" } },
      true
    );
  }

  async function onRecording() {
    if (recording) {
      setRecording(false);
      if (audioRecorder) {
        (async () => {
          await audioRecorder.stop();
        })();
      }
    } else {
      setRecording(true);
      let audioCtx = new (window.AudioContext || window.webkitAudioContext)();

      let recordAudio = () =>
        new Promise(async (resolve) => {
          let stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          let mediaRecorder = new MediaRecorder(stream);
          let audioChunks = [];

          setPhonetic({
            recording: true,
          });

          mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);

            const audioRecordingBlob = new Blob(audioChunks);
            new Response(audioRecordingBlob)
              .arrayBuffer()
              .then(function (buff) {
                if (buff.byteLength > 0) {
                  audioCtx.decodeAudioData(buff, function (buffer) {
                    if (buffer.duration > 30) {
                      mediaRecorder.stop();
                    }

                    const waveform = document.createElement("canvas");
                    //buffer = trimAudioBuffer(buffer);
                    processAudioBuffer(buffer, waveform);

                    setPhonetic({
                      audioBuffer: buffer,
                      waveform: waveform,
                      time_start: 0,
                      time_end: buffer.duration,
                    });
                  });
                }
              });
          };

          let start = () => mediaRecorder.start(100);

          let stop = () =>
            new Promise((resolve) => {
              mediaRecorder.addEventListener("stop", () => {
                setRecording(false);
              });
              mediaRecorder.stop();
            });

          resolve({ start, stop });
        });

      (async () => {
        const ar = await recordAudio();
        setAudioRecorder(ar);
        ar.start();
      })();
    }
  }

  return (
    <div className="phonetichelp">
      <input
        hidden
        type="file"
        ref={helpInputFileRef}
        onChangeCapture={onFileChangeCapture}
      />

      <div
        className="modal-container"
        onClick={() => {
          init();
          closeHandler(false);
        }}
      >
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div
            className="close-btn"
            onClick={() => {
              init();
              closeHandler(false);
            }}
          >
            <img
              alt="close"
              style={{ width: "30px" }}
              src="./img/close_white.png"
            />
          </div>

          <h2>Need help</h2>

          <div>
            <p>
              You can contact us to help you find the phonetics of a surname, a
              first name, a brand or a link between 2 words. Make an audio
              recording corresponding to the correct pronunciation. The data of
              your message, as well as your recording will allow us to send you
              back your corrected phonetics.
            </p>
            <div style={{ height: "20px" }}></div>
            <div className="field">
              <label className="label-field">Email for response</label>
              <textarea
                autoComplete="off"
                className="field-textarea"
                spellCheck="false"
                value={textAreaPhoneticHelpEmail}
                onChange={(e) => {
                  setTextAreaPhoneticHelpEmail(e.target.value);
                }}
              />
            </div>
            <div className="field">
              <label className="label-field">Comment</label>
              <textarea
                autoComplete="off"
                className="field-textarea"
                style={{ height: "100px" }}
                spellCheck="false"
                value={textAreaPhoneticHelpInstructions}
                onChange={(e) => {
                  setTextAreaPhoneticHelpInstructions(e.target.value);
                }}
              />
            </div>
            <div className="toolb">
              <button
                className="button"
                style={{ background: recording ? "#f44" : "#aaaa93" }}
                onClick={() => {
                  onRecording();
                }}
              >
                {recording ? "Stop recording" : "Start recording"}
              </button>

              <span ref={dropdownRef}>
                <div className="dropdown">
                  <div
                    className="menu"
                    onMouseDown={() => {
                      setMenuOpened(!menuOpened);
                    }}
                  >
                    <img src="./img/hamburger_menu_white.png" alt="" />
                  </div>

                  <div
                    id="menu"
                    className={menuOpened ? "dropdown-content" : "hidden"}
                  >
                    <li
                      onClick={(e) => {
                        helpInputFileRef.current.click();
                        setMenuOpened(false);
                      }}
                    >
                      <img
                        className="btn-icon"
                        src="./img/download_white.png"
                        alt=""
                      ></img>
                      Import an audio file
                    </li>

                    <li
                      onClick={(e) => {
                        exportRecord();
                      }}
                    >
                      <img
                        className="btn-icon"
                        src="./img/upload_white.png"
                        alt=""
                      ></img>
                      Export recording
                    </li>
                  </div>
                </div>
              </span>
            </div>
            <div style={{ height: "20px" }}></div>

            <Player
              ref={playerRef}
              block={phonetic}
              recording={recording}
            ></Player>
            <div style={{ height: "20px" }}></div>
            {!verify &&
              emailReady &&
              phonetic.audioBuffer &&
              phonetic.audioBuffer.duration > 0.5 &&
              !recording && (
                <Recaptcha
                  language="en"
                  updateVerify={(e) => {
                    setVerify(e);
                  }}
                ></Recaptcha>
              )}
            {verify && !emailSent && (
              <button
                className="btn-ok"
                onClick={() => {
                  send();
                }}
              >
                SEND
              </button>
            )}
            {emailSent && (
              <div>
                <p>Your request has been received.</p>

                <button
                  className="btn-ok"
                  onClick={() => {
                    init();
                    closeHandler(false);
                  }}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneticHelp;
