import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { textToSpeech } from "../js/Api";
import { processBlockBuffer } from "../js/Project";
import { translate } from "../js/Translation";

function drawCanvas(canvas, block, currentTime, recording) {
  if (!canvas || !block.waveform) return;

  let pixelPerSecond = canvas.width / (block.time_end - block.time_start);
  const timeIndex = currentTime * pixelPerSecond;

  const context = canvas.getContext("2d");
  // context.translate(0.5, 0.5);
  context.globalAlpha = 0.5;

  context.clearRect(0, 0, canvas.width, canvas.height);
  //context.fillStyle = "#0f0";
  //context.fillRect(0, 0, canvas.width, canvas.height);

  context.drawImage(block.waveform, 0, 0, block.waveform.width, block.waveform.height, 0, 0, canvas.width, canvas.height);

  if (!recording) {
    context.globalAlpha = 1.0;
    context.fillStyle = "#fff";
    context.fillRect(timeIndex, 10, 1, canvas.height - 35);
  }
}

const Player = forwardRef(({ token, block, phonetics, updateSpinner, info, phoneticMode, recording = false }, ref) => {
  const canvasRef = useRef(null);
  const [playing, setPlaying] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [message, setMessage] = useState(info);

  useImperativeHandle(ref, () => ({
    pauseIfPlaying() {
      if (playing) pause();
    },

    updatePlaying(state) {
      setPlaying(state);
    },
  }));

  useEffect(() => {
    drawCanvas(canvasRef.current, block, currentTime, recording);
  }, [block, currentTime, recording]);

  let timestamp = 0;
  function animate() {
    setCurrentTime(new Date().getTime() / 1000 - timestamp);
    block.animationFrameId = window.requestAnimationFrame(animate);
  }

  function playPause() {
    if (playing) pause();
    else play();
  }

  function play() {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    if (block.audioBuffer) {
      block.audioBufferSource = audioCtx.createBufferSource();
      block.audioBufferSource.buffer = block.audioBuffer;
      block.audioBufferSource.connect(audioCtx.destination);

      block.audioBufferSource.onended = (event) => {
        pause();
      };

      block.audioBufferSource.start(
        0,
        0, //currentTime,
        //0,
        block.audioBuffer.duration
      );

      setPlaying(1);
      timestamp = new Date().getTime() / 1000; // - currentTime;
      block.animationFrameId = window.requestAnimationFrame(animate);
    }
  }

  function pause() {
    window.cancelAnimationFrame(block.animationFrameId);
    if (block.audioBufferSource) block.audioBufferSource.stop();
    setPlaying(0);
  }

  async function textToSpeechEx() {
    if (block.voice_public_name === "") return;

    updateSpinner({ visible: true, info: "TEXT TO SPEECH", percent: "" });

    let text = block.text;

    if (!phoneticMode && phonetics) {
      phonetics.forEach((phonetic) => {
        let textToConvert = "";
        if (phonetic.phoneticText) textToConvert = phonetic.phoneticText.trim();

        if (textToConvert !== "") {
          let textPhonetic = phonetic.phoneticIPA.trim();
          if (textPhonetic !== "") textPhonetic = '<phoneme alphabet="ipa" ph="' + textPhonetic + '"> ' + textToConvert + " </phoneme>";
          else textPhonetic = phonetic.phoneticTranscription.trim();

          /*if (textPhonetic !== "") {
            //var regex = new RegExp(textToConvert, "gi");
            //text = text.replace(regex, textPhonetic);
            const splits = text.split(" ");
            for (let i = 0; i < splits.length; i++) {
              if (splits[i].toLowerCase() === textToConvert.toLowerCase()) splits[i] = textPhonetic;
            }
            text = splits.join(" ");
          }*/

          if (textPhonetic !== "") {
            let i = 0;
            while (1) {
              let index = text.toLowerCase().indexOf(textToConvert.toLowerCase(), i);
              if (index >= 0) {
                if (index === 0 || text[index - 1] === " ") {
                  if (
                    index + textToConvert.length === text.length ||
                    text[index + textToConvert.length] === " " ||
                    text[index + textToConvert.length] === "," ||
                    text[index + textToConvert.length] === "." ||
                    text[index + textToConvert.length] === ";"
                  ) {
                    text = text.substring(0, index) + textPhonetic + text.substring(index + textToConvert.length);
                  }
                }

                i = index + textPhonetic.length;
                continue;
              }

              break;
            }
          }
        }
      });
    }

    const result = await textToSpeech(token.token, text, block.voice_public_name, 0);

    if (result.message !== "" || result.buffer === null) {
      setMessage(result.message);
      updateSpinner({ visible: false, info: "", percent: "" });
      return;
    }

    const apiAddress = "https://fonetic.be/php/";
    const data = await (
      await fetch(apiAddress + "vo-get-account-ex.php?token=" + token.token, {
        method: "GET",
      })
    ).json();

    token.voiceover_char_credit = data["voiceover_char_credit"];

    updateSpinner({ visible: true, info: "SPEECH AUDIO", percent: "" });

    const audioBuffer = new Uint8Array(result.buffer);

    block.mp3Buffer = new Uint8Array(audioBuffer.length);
    for (let i = 0; i < audioBuffer.length; i++) block.mp3Buffer[i] = audioBuffer[i];

    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    audioCtx.decodeAudioData(result.buffer, function (buff) {
      block.buffer_ready = true;
      //block.time_start = 0;
      block.time_end = block.time_start + buff.duration;
      processBlockBuffer(block, buff, audioCtx);
      drawCanvas(canvasRef.current, block, currentTime);
      updateSpinner({ visible: false, info: "", percent: "" });
    });
  }

  return (
    <div ref={ref} style={{ textAlign: "center" }}>
      {phoneticMode && (
        <div style={{ display: block.phoneticIPA !== "" || block.phoneticTranscription !== "" ? "block" : "none" }}>
          <div className="info-char">{message}</div>

          <button
            className="btn-control"
            style={{
              margin: "10px 0",
              height: "30px",
              opacity: block.voice_public_name && block.voice_public_name !== "" ? "1.0" : "0.3",
            }}
            onClick={() => {
              textToSpeechEx();
            }}
          >
            {translate("GenerateTheVoiceover")}
          </button>
        </div>
      )}
      {!phoneticMode && (
        <div style={{ display: block.text ? "block" : "none" }}>
          <div className="info-char">{message}</div>
          <div className="info-char">
            {translate("AboutChars")} {block.hasOwnProperty("text") ? block.text.length : 0} {translate("CharsWillBeDeductedAfterTheVoiceoverHasBeenGenerated")}
          </div>
          <button
            className="btn-control"
            style={{
              margin: "10px 0",
              height: "30px",
              opacity: block.hasOwnProperty("text") && token.voiceover_char_credit >= block.text.length && block.voice_public_name && block.voice_public_name !== "" && block.text && block.text.length > 1 ? "1.0" : "0.3",
            }}
            onClick={() => {
              textToSpeechEx();
            }}
          >
            {translate("GenerateTheVoiceover")}
          </button>
        </div>
      )}
      <div className={block.audioBuffer ? "player" : "hidden"} style={{ height: "40px" }}>
        {!recording && (
          <img
            src={playing ? "./img/stop_white.png" : "./img/play_white.png"}
            alt="play"
            className="btn-play"
            onClick={() => {
              playPause();
            }}
            style={{ height: "30px", width: "30px" }}
          />
        )}

        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
});

export default Player;
