//import { saveAs } from "file-saver";

const apiAddress = "https://imea-studio.com/";
const token = "8gs34lxp6w9m082H4q721-45sx";

const apiAddressFonetic = "https://fonetic.be/php/";
async function loginUser(email, password) {
  const data = await (
    await fetch(apiAddressFonetic + "vo-login-ex.php?email=" + encodeURIComponent(email) + "&password=" + encodeURIComponent(password), {
      method: "GET",
    })
  ).json();

  return data;
}

async function forgotPassword(email) {
  const data = await (
    await fetch(apiAddressFonetic + "vo-forgot-password-ex.php?email=" + encodeURIComponent(email), {
      method: "GET",
    })
  ).json();

  return data["result"];
}

async function logon(setUserToken) {
  const response = await loginUser(localStorage.getItem("vo_email"), localStorage.getItem("vo_password"));

  if (response["result"] === "success") {
    localStorage.setItem("vo_token", response["token"]);
    localStorage.setItem("voiceover_char_credit", response["voiceover_char_credit"]);
  } else {
    localStorage.setItem("vo_token", "");
    localStorage.setItem("voiceover_char_credit", 0);
  }

  setUserToken({ token: localStorage.getItem("vo_token"), voiceover_char_credit: localStorage.getItem("voiceover_char_credit") });
}

function textToSpeech(token, text, voice_name, pitch) {
  return new Promise((resolve) => {
    let url = new URL(apiAddress + "vo-text-to-speech");
    url.searchParams.set("token", token);
    url.searchParams.set("voice_name", voice_name);
    url.searchParams.set("text", text);
    url.searchParams.set("pitch", pitch);

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url, true);

    xhr.onload = function () {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        const audioBuffer = new Uint8Array(fileReader.result);
        if (audioBuffer.length < 2000) {
          const jsonResult = JSON.parse(String.fromCharCode.apply(null, audioBuffer));

          let message = "";
          switch (jsonResult["error"]) {
            case "no_user":
              message = "You must log in to access this service.";
              break;
            case "insufficient_chars":
              message = "You must credit your account to access this service.";
              break;

            default:
              break;
          }

          //if (message !== "") alert(message);
          resolve({ buffer: null, message: message });
        } else {
          resolve({ buffer: fileReader.result, message: "" });
        }
      };

      fileReader.readAsArrayBuffer(xhr.response);
    };

    xhr.send();
  });
}

function textToTranslate(token, text, language_src, language_dest) {
  return new Promise((resolve) => {
    let url = new URL(apiAddress + "vo-text-to-translate");
    url.searchParams.set("token", token);
    url.searchParams.set("text", text);
    url.searchParams.set("language_src", language_src);
    url.searchParams.set("language_dest", language_dest);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let message = "";
        let translation = "";

        var resp;
        try {
          resp = JSON.parse(xhr.response);
        } catch (e) {
          console.log(e);
          message = e;
          resolve({ translation: translation, message: message });
          return;
        }

        translation = resp["translation"];

        switch (resp["error"]) {
          case "no_user":
            message = "You must log in to access this service.";
            break;
          case "insufficient_chars":
            message = "You must credit your account to access this service.";
            break;

          default:
            break;
        }

        resolve({ translation: translation, message: message });
      }
    };

    xhr.send();
  });
}

function getSecurityNumber(language) {
  return new Promise((resolve) => {
    const url = new URL(apiAddress + "vo-get-security-number");
    url.searchParams.set("token", token);
    url.searchParams.set("language", language);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);

    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        const resp = JSON.parse(xhr.response);
        if (resp["result"] === "success") {
          await fetch(apiAddress + "/vo-download-security-file.php?file_name=" + resp["file"] + ".mp3")
            .then((response) => response.blob())
            .then((blob) => {
              resolve({ blob: blob, number: resp["number"] });
            });
        } else resolve();
      }
    };

    xhr.send();
  });
}

function getPhoneticHelp(email, instructions, language, audioBuffer, blob) {
  return new Promise((resolve) => {
    /*let mediaData = [];
    mediaData[0] = audioBuffer.getChannelData(0);

    const waveFileBuffer = toWaveFileBuffer(
      mediaData,
      1,
      16,
      audioBuffer.sampleRate
    );

    const blob = await createMp3File(
      waveFileBuffer,
      { updateSpinner: updateSpinner, project: { name: "phonetic_record" } },
      false
    );*/

    const data = new FormData();
    data.append("token", token);
    data.append("email", email);
    data.append("instructions", instructions);
    data.append("language", language);
    data.append("recordingfile", blob);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", apiAddress + "vo-upload-phonetic-help", true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        var resp = JSON.parse(xhr.response);
        resolve(resp["result"]);
      }
    };

    xhr.send(data);
  });
}

export { logon, loginUser, forgotPassword, textToSpeech, textToTranslate, getSecurityNumber, getPhoneticHelp };
