import React, { useRef, useState, useEffect } from "react";
import { translate } from "../js/Translation";
import { loginUser, forgotPassword } from "../js/Api";
import * as CryptoJS from "crypto-js";

const Login = ({ isOpen, closeHandler, token }) => {
  const [email, setEmail] = useState(localStorage.getItem("vo_email"));
  const [password, setPassword] = useState(localStorage.getItem("vo_password"));
  const [remember, setRemember] = useState(localStorage.getItem("vo_remember") !== "");

  const infoRef = useRef();

  const reMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    localStorage.setItem("vo_email", email);
    localStorage.setItem("vo_password", password);
    localStorage.setItem("vo_remember", remember ? "x" : "");

    if (!remember) {
      //localStorage.setItem("vo_email", "");
      localStorage.setItem("vo_password", "");
    }
  }, [remember, email, password]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let passwordCrypt = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(password));

    localStorage.setItem("vo_token", "");
    infoRef.current.style.opacity = "0.0";

    const response = await loginUser(email, passwordCrypt);
    token = { token: response["token"], voiceover_char_credit: response["voiceover_char_credit"] };

    infoRef.current.style.opacity = "1.0";
    infoRef.current.style.color = "#f88";
    infoRef.current.innerHTML = translate("ConnectionFailedCheckYourCredentials");

    if (token.token) {
      localStorage.setItem("vo_token", response["token"]);
      localStorage.setItem("voiceover_char_credit", response["voiceover_char_credit"]);

      setEmail(email);
      setPassword(remember ? password : "");

      localStorage.setItem("vo_password", passwordCrypt);
      localStorage.setItem("vo_email", email);

      infoRef.current.innerHTML = "";
      closeHandler(token);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    infoRef.current.style.opacity = "0.0";

    const response = await forgotPassword(email);

    if (response === "success") {
      infoRef.current.style.opacity = "1.0";
      infoRef.current.style.color = "#8ff";
      infoRef.current.innerHTML = translate("AnEmailHasBeenSentToYouWithYourPassword");
    } else {
      infoRef.current.style.opacity = "1.0";
      infoRef.current.style.color = "#f88";
      infoRef.current.innerHTML = translate("ThisEmailHasNotBeenIdentified");
    }
  };

  return (
    <div className="login">
      <div
        className="modal-container"
        onClick={(e) => {
          closeHandler(token);
        }}
      >
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div
            className="close-btn"
            onClick={(e) => {
              closeHandler(token);
            }}
          >
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>

          <h2>{translate("LOGIN")}</h2>

          <form onSubmit={handleSubmit}>
            <div>
              <div style={{ height: "30px" }}></div>

              <div className="field">
                <label className="label-field">{translate("Email")}</label>
                <input type="text" className="field-textarea" value={email} spellCheck="false" onChange={(e) => setEmail(e.target.value)}></input>
              </div>

              <div className="field">
                <label className="label-field">{translate("Password")}</label>
                <input type="password" className="field-textarea" value={password} spellCheck="false" onChange={(e) => setPassword(e.target.value)}></input>
              </div>

              <div style={{ height: "20px" }}></div>

              <div
                className="link"
                onClick={(e) => {
                  handleForgotPassword(e);
                }}
              >
                {translate("ForgotYourPassword")}
              </div>
              <div style={{ height: "30px" }}></div>

              <div className="check-param">
                <span className="title">{translate("RememberMyLogin")}</span>
                <label className="checkbox">
                  <input type="checkbox" name="audioFadeInFadeOut" checked={remember} onChange={(e) => setRemember(e.target.checked)} />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div style={{ height: "30px" }}></div>
            <button type="submit" className={email !== "" && password !== "" && reMail.test(email) ? "btn-ok" : "hidden"}>
              {translate("LogIn")}
            </button>

            <p ref={infoRef}></p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
