import { saveAs } from "file-saver";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

const computMix = async (props, mp3, saveMode) => {
  return new Promise((resolve, reject) => {
    props.updateSpinner({ visible: true, info: "Process Audio", percent: "" });

    updateAudioBuffer(props.project);
    let sampleNumber = props.project.duration * props.sampleRate;
    let offlineCtx = new OfflineAudioContext(props.channelNumber, sampleNumber, props.sampleRate);
    if (offlineCtx) {
      props.project.blocks.forEach((block) => {
        let offset = 0;
        for (let i = 0; i < props.project.cuts.length; i++) {
          if (props.project.cuts[i].time_start > block.time_start - offset) break;
          if (!props.project.cuts[i].expand) offset += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
        }

        if (block.audioBuffer) {
          block.audioBufferSource = offlineCtx.createBufferSource();
          block.audioBufferSource.buffer = block.audioBuffer;
          block.audioBufferSource.connect(offlineCtx.destination);
          block.audioBufferSource.start(Math.max(0, block.time_start - offset), 0, Math.max(0, block.time_end - block.time_start));
        }
      });

      if (props.project.audioBuffer) {
        props.project.audioBufferSource = offlineCtx.createBufferSource();
        props.project.audioBufferSource.buffer = props.project.audioBuffer;
        props.project.audioBufferSource.connect(offlineCtx.destination);
        props.project.audioBufferSource.start(0);
      }

      offlineCtx.oncomplete = function (e) {
        let mediaData = [];
        mediaData[0] = e.renderedBuffer.getChannelData(0);
        mediaData[1] = e.renderedBuffer.getChannelData(1);

        const waveFileBuffer = toWaveFileBuffer(mediaData, props.channelNumber, 16, props.sampleRate);

        props.updateSpinner({ visible: false, info: "", percent: "" });

        if (mp3) {
          createMp3File(waveFileBuffer, props, saveMode);
        } else if (saveMode) {
          let blob = new Blob([waveFileBuffer], { type: "audio/wav" });
          saveAs(blob, props.project.name + ".wav");
        }

        resolve(waveFileBuffer);
      };

      offlineCtx.startRendering();
    }
  });
};

function computSubtitles(project, webvtt, saveMode) {
  let subtitle = "";
  let blockCount = 1;

  project.blocks.forEach((block) => {
    let offset = 0;
    for (let i = 0; i < project.cuts.length; i++) {
      if (project.cuts[i].time_start > block.time_start - offset) break;
      if (!project.cuts[i].expand) offset += project.cuts[i].time_end - project.cuts[i].time_start;
    }

    let startTime = block.time_start - offset;
    let endTime = block.time_end - offset;

    subtitle += blockCount.toString() + "\r\n";
    let ms = startTime * 1000 - Math.floor(startTime) * 1000;
    subtitle +=
      "00:" +
      Math.floor(startTime / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      Math.floor(startTime % 60)
        .toString()
        .padStart(2, "0") +
      "." +
      Math.floor(ms).toString().padStart(3, "0");
    subtitle += " --> ";
    ms = endTime * 1000 - Math.floor(endTime) * 1000;
    subtitle +=
      "00:" +
      Math.floor(endTime / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      Math.floor(endTime % 60)
        .toString()
        .padStart(2, "0") +
      "." +
      Math.floor(ms).toString().padStart(3, "0");

    let text = "";
    let splits = block.text.replace("\r", "").split("\n");
    splits.forEach((split) => {
      if (split !== "") text += split + "\n";
    });

    subtitle += "\r\n" + text + "\r\n\r\n";
    blockCount++;
  });

  if (webvtt) subtitle = "WEBVTT\r\n\r\n" + subtitle;

  let blob = new Blob([subtitle], { type: "text" });

  if (saveMode) saveAs(blob, project.name + (webvtt ? ".vtt" : ".srt"));
  //else
  //subtitleSrc = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));

  return blob;
}

async function computVideo(props) {
  //const subtitleBuffer = await computSubtitles(props.project, false, false);

  props.updateSpinner({ visible: true, info: "Process Audio", percent: "" });

  const audioBuffer = await computMix(props, false);

  const ffmpeg = createFFmpeg({
    log: false,
  });

  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }

  // ffmpeg.setProgress(({ ratio }) => {
  //   let r = ratio;
  //   if (props.project.videoDuration && props.project.duration > 0) r *= props.project.videoDuration / props.project.duration;

  //   let info = "Process Video";
  //   let percent = Math.floor(r * 100) + "%";
  //   if (r > 100) {
  //     info = "Finishing the video";
  //     percent = "";
  //   }

  //   console.log(ratio, r, percent);
  //   props.updateSpinner({
  //     visible: true,
  //     info: info,
  //     percent: percent,
  //   });
  // });

  const videoBuffer = await props.project.videoData.arrayBuffer();
  ffmpeg.FS("writeFile", "video." + props.project.videoExt, new Uint8Array(videoBuffer));
  ffmpeg.FS("writeFile", "audio.wav", audioBuffer);

  let expandCommand = "";
  let cutCommand = "";
  let start = 0;
  let duration = props.project.duration;

  let offset = 0;
  let expandOffset = 0;
  for (let i = 0; i < props.project.cuts.length; i++) {
    let cut = props.project.cuts[i];
    if (cut.expand) {
      expandCommand += " + gte(T," + (cut.time_start - expandOffset).toString() + ")*(" + (cut.time_end - cut.time_start).toString() + "/TB)";
      offset += cut.time_end - cut.time_start;
      expandOffset += cut.time_end - cut.time_start;
    } else {
      if (cutCommand !== "") cutCommand += "+";
      cutCommand += "between(t," + start.toString() + "," + (cut.time_start - offset).toString() + ")";
      start = cut.time_end - offset;
      offset -= cut.time_end - cut.time_start;
    }
  }

  if (cutCommand !== "" && duration - offset > start) {
    cutCommand += "+between(t," + start.toString() + "," + (duration - offset).toString() + ")";
  }

  let command = "";
  if (cutCommand !== "") command = "select='" + cutCommand + "', setpts=N/FRAME_RATE/TB";
  if (expandCommand !== "") {
    if (command === "") command = "setpts='PTS-STARTPTS ";
    if (cutCommand !== "") command += " [1]; [1] setpts='PTS-STARTPTS ";
    command += expandCommand + "'";
    if (cutCommand !== "") command += " [out]";
  }

  ffmpeg.setProgress(({ ratio }) => {
    let r = ratio;
    if (props.project.videoDuration && props.project.duration > 0) r *= props.project.videoDuration / (duration - offset);

    let info = "Process Video";
    let percent = Math.floor(r * 100) + "%";
    if (r > 100) {
      info = "Finishing the video";
      percent = "";
    }

    props.updateSpinner({
      visible: true,
      info: info,
      percent: percent,
    });
  });

  // if (command === "") {
  //   await ffmpeg.run("-i", "video." + props.project.videoExt, "-i", "audio.wav", "-map", "0:v", "-map", "1:a", "-y", "output." + props.project.videoExt);
  // } else {
  //   await ffmpeg.run("-i", "video." + props.project.videoExt, "-i", "audio.wav", "-map", "0:v", "-map", "1:a", "-vf", command, "-y", "output." + props.project.videoExt);
  // }

  if (command === "") {
    await ffmpeg.run("-i", "video." + props.project.videoExt, "-i", "audio.wav", "-c:v", "copy", "-map", "0:v", "-map", "1:a", "-y", "output." + props.project.videoExt);
  } else {
    await ffmpeg.run("-i", "video." + props.project.videoExt, "-i", "audio.wav", "-map", "0:v", "-map", "1:a", "-vf", command, "-y", "output." + props.project.videoExt);
  }

  props.updateSpinner({ visible: false, info: "", percent: "" });

  const data = ffmpeg.FS("readFile", "output." + props.project.videoExt);
  let blob = new Blob([data.buffer], {
    type: "video/" + props.project.videoExt,
  });
  saveAs(blob, props.project.name + "." + props.project.videoExt);
}

function toWaveFileBuffer(pcmArrays, channelNumber, bitDepth, sampleRate) {
  let bytesPerSample = bitDepth / 8;
  let bufferLength = pcmArrays[0].length;
  let dataLength = bufferLength * channelNumber * bytesPerSample;
  let reducedData = new Uint8Array(dataLength);
  let bufferNumber = pcmArrays.length;

  for (let i = 0; i < bufferLength; i++) {
    for (let channel = 0; channel < channelNumber; channel++) {
      let outputIndex = (i * channelNumber + channel) * bytesPerSample;
      let sample = pcmArrays[channel][i];

      if (channelNumber === 1 && bufferNumber === 2) sample += pcmArrays[1][i];

      if (sample > 1) sample = 1;
      else if (sample < -1) sample = -1;

      switch (bytesPerSample) {
        case 4:
          sample = sample * 2147483648;
          reducedData[outputIndex] = sample;
          reducedData[outputIndex + 1] = sample >> 8;
          reducedData[outputIndex + 2] = sample >> 16;
          reducedData[outputIndex + 3] = sample >> 24;
          break;

        case 3:
          sample = sample * 8388608;
          reducedData[outputIndex] = sample;
          reducedData[outputIndex + 1] = sample >> 8;
          reducedData[outputIndex + 2] = sample >> 16;
          break;

        case 2:
          sample = sample * 32768;
          reducedData[outputIndex] = sample;
          reducedData[outputIndex + 1] = sample >> 8;
          break;

        case 1:
          reducedData[outputIndex] = (sample + 1) * 128;
          break;
        default:
          break;
      }
    }
  }

  let headerLength = 44;
  let wav = new Uint8Array(headerLength + dataLength);
  let view = new DataView(wav.buffer);

  view.setUint32(0, 1380533830, false); // RIFF identifier 'RIFF'
  view.setUint32(4, 36 + dataLength, true); // file length minus RIFF identifier length and file description length
  view.setUint32(8, 1463899717, false); // RIFF type 'WAVE'
  view.setUint32(12, 1718449184, false); // format chunk identifier 'fmt '
  view.setUint32(16, 16, true); // format chunk length
  view.setUint16(20, 1, true); // sample format (raw)
  view.setUint16(22, channelNumber, true); // channel count
  view.setUint32(24, sampleRate, true); // sample rate
  view.setUint32(28, sampleRate * bytesPerSample * channelNumber, true); // byte rate (sample rate * block align)
  view.setUint16(32, bytesPerSample * channelNumber, true); // block align (channel count * bytes per sample)
  view.setUint16(34, bitDepth, true); // bits per sample
  view.setUint32(36, 1684108385, false); // data chunk identifier 'data'
  view.setUint32(40, dataLength, true); // data chunk length

  wav.set(reducedData, headerLength);
  //createMp3File(wav);
  //return wav.buffer;
  return wav;
}

async function createMp3File(waveBuffer, props, saveMode) {
  props.updateSpinner({ visible: true, info: "Process MP3", percent: "" });

  const ffmpeg = createFFmpeg({
    log: false,
  });

  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }

  ffmpeg.setProgress(({ ratio }) => {
    props.updateSpinner({
      visible: true,
      info: "Process MP3",
      percent: Math.floor(ratio * 100) + "%",
    });
  });

  ffmpeg.FS("writeFile", "audio.wav", waveBuffer);
  await ffmpeg.run("-i", "audio.wav", "output.mp3");
  const data = ffmpeg.FS("readFile", "output.mp3");
  let blob = new Blob([data.buffer], { type: "audio/mp3" });

  if (saveMode) {
    saveAs(blob, props.project.name + ".mp3");
  }

  props.updateSpinner({ visible: false, info: "", percent: "" });

  //return CSSStyleDeclaration;
  return blob;
}

async function doTranscode(inputFileMp4, inputFileMp3) {
  const ffmpeg = createFFmpeg({
    log: false,
  });

  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }

  ffmpeg.setProgress(({ ratio }) => {
    console.log(ratio);
  });

  ffmpeg.FS("writeFile", "video.mp4", await fetchFile(inputFileMp4));
  ffmpeg.FS("writeFile", "audio.mp3", await fetchFile(inputFileMp3));
  await ffmpeg.run("-i", "video.mp4", "-i", "audio.mp3", "-c:v", "copy", "-map", "0:v", "-map", "1:a", "-y", "output.mp4");

  const data = ffmpeg.FS("readFile", "output.mp4");
  return data;
}

const loadVideoFile = async (file, project) => {
  return new Promise((resolve, reject) => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      if (project.name === "") project.name = file.name.split(".")[0];
      project.videoFileName = file.name;
      project.videoExt = file.name.split(".").pop();
      project.videoData = new Blob([reader.result], { type: file.type });

      audioCtx
        .decodeAudioData(reader.result)
        .then(function (buffer) {
          project.audioVideoBuffer = buffer;
          project.audioVideoWaveForm = document.createElement("canvas");
          processAudioBuffer(project.audioVideoBuffer, project.audioVideoWaveForm);
          resolve();
        })
        .catch(function (error) {
          console.log("decode exception", error);
          resolve();
        });
    };
    reader.readAsArrayBuffer(file);
  });
};

const loadAudioFile = async (file, project) => {
  return new Promise((resolve, reject) => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    const reader = new FileReader();
    reader.onload = () => {
      const audioBuffer = new Uint8Array(reader.result);
      project.audioFileBufferOrigin = new Uint8Array(audioBuffer.length);
      for (let i = 0; i < audioBuffer.length; i++) project.audioFileBufferOrigin[i] = audioBuffer[i];

      audioCtx.decodeAudioData(reader.result).then(function (buffer) {
        let videoLength = buffer.length;
        if (project.duration) {
          videoLength = Math.floor(project.duration * buffer.sampleRate);
        }

        let bufferLength = buffer.length * 2;
        let audioDuration = buffer.duration * 2;
        while (audioDuration < project.duration) {
          audioDuration += buffer.duration;
          bufferLength += buffer.length;
        }

        project.audioFileBuffer = audioCtx.createBuffer(buffer.numberOfChannels, bufferLength, buffer.sampleRate);

        for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
          let floatBuffer = new Float32Array(buffer.length);
          buffer.copyFromChannel(floatBuffer, channel, 0);

          let length = 0;
          let lengthToCopy = buffer.length;
          while (true) {
            if (length >= videoLength) break;

            if (length + lengthToCopy >= videoLength) lengthToCopy = videoLength - length;

            project.audioFileBuffer.copyToChannel(floatBuffer, channel, length);
            length += lengthToCopy;
          }
        }

        project.audioFileName = file.name;
        project.audioFileWaveForm = document.createElement("canvas");

        processAudioBuffer(project.audioFileBuffer, project.audioFileWaveForm, "#fff");
        resolve();
      });
    };

    reader.readAsArrayBuffer(file);
  });
};

function processAudioBuffer(audioBuffer, audioWaveForm, color = "#fff") {
  let size = audioBuffer.length;
  let data = audioBuffer.getChannelData(0);
  let waveformHeight = 100;
  let context = audioWaveForm.getContext("2d");
  audioWaveForm.width = 4000 * 2;
  let samplePerPixel = size / audioWaveForm.width;

  audioWaveForm.height = waveformHeight;

  let min = 1.0;
  let max = -1.0;
  let left = 0;
  let add = 0;
  let d;

  let maxLevel = 0.0;

  context.lineWidth = 1;
  context.fillStyle = color;
  context.fillRect(0, waveformHeight / 2, audioWaveForm.width, 1);

  while (add < size) {
    min = 1.0;
    max = -1.0;
    for (let i = 0; i < samplePerPixel; i++) {
      d = data[add++];

      if (d < min) min = d;
      if (d > max) max = d;

      if (add >= size) break;
    }

    left = add / samplePerPixel;
    let maxMinDifference = ((max - min) * waveformHeight) / 4;
    context.fillRect(left, waveformHeight / 2 - maxMinDifference, 1, maxMinDifference * 2);

    if (max - min > maxLevel) maxLevel = max - min;
  }
}

let faderCurve = [];
let faderLinearRangeEx = 100.0;
let faderLinearAtt = 0.0;
let faderSteps = 1000;
let messageMusicPreFadeOutDurationEx = 0.5;
let messageMusicPreFadeInDurationEx = 0.5;
let musicFadeInDurationEx = 1.5; //2.0;
let musicFadeOutDurationEx = 0.5; //2.0;

updateFaderCurve();

const updateAudioBuffer = async (project) => {
  if (!project.duration || project.duration === 0) return;
  return new Promise((resolve, reject) => {
    let initialized = false;
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    let sampleRate = audioCtx.sampleRate;
    //if (project.audioVideoBuffer) sampleRate = project.audioVideoBuffer.sampleRate;

    project.audioBuffer = audioCtx.createBuffer(2, project.duration * audioCtx.sampleRate, audioCtx.sampleRate);

    if (project.audioVideoBuffer && !project.audioVideoMute) {
      let level = Math.pow(2, project.audioVideoLevel / 6);
      for (let channel = 0; channel < project.audioBuffer.numberOfChannels; channel++) {
        let audioBuffer = project.audioBuffer.getChannelData(channel);
        let audioBufferSource = project.audioVideoBuffer.getChannelData(0);
        if (project.audioVideoBuffer.numberOfChannels > 1) audioBufferSource = project.audioVideoBuffer.getChannelData(channel);

        //let sampleRate = project.audioBuffer.sampleRate;
        let addDest = 0;
        let addSrc = 0;

        for (let c = 0; c < project.cuts.length; c++) {
          let cutFrom = Math.floor(project.cuts[c].time_start * sampleRate);
          let cutTo = Math.floor(project.cuts[c].time_end * sampleRate);
          // let range = cutFrom - addDest;

          for (let i = addDest; i < cutFrom; i++) audioBuffer[addDest++] = audioBufferSource[addSrc++] * level;

          if (project.cuts[c].expand) {
            for (let i = cutFrom; i < cutTo; i++) audioBuffer[addDest++] = 0;
          } else {
            addSrc += cutTo - cutFrom;
          }
        }

        let destLength = audioBuffer.length - addDest;
        let srcLength = audioBufferSource.length - addSrc;
        let length = Math.min(destLength, srcLength);
        for (let i = 0; i < length; i++) {
          audioBuffer[addDest++] = audioBufferSource[addSrc++] * level;
        }
      }

      initialized = true;
    }

    if (project.audioFileBuffer && !project.audioFileMute) {
      let level = Math.pow(2, project.audioFileLevel / 6);
      let offsetAudio = Math.floor(project.offsetAudioFile * sampleRate);
      if (project.audioFileBuffer.numberOfChannels === 1) {
        for (let channel = 0; channel < project.audioBuffer.numberOfChannels; channel++) {
          let audioBuffer = project.audioBuffer.getChannelData(channel);
          let audioBufferSource = project.audioFileBuffer.getChannelData(0);
          let length = Math.min(project.audioFileBuffer.length, audioBuffer.length);

          if (initialized) {
            if (offsetAudio < 0) for (let i = -offsetAudio; i < length; i++) audioBuffer[i] += audioBufferSource[i + offsetAudio] * level;
            else for (let i = 0; i < length; i++) audioBuffer[i] += audioBufferSource[i + offsetAudio] * level;
          } else {
            if (offsetAudio < 0) {
              for (let i = 0; i < -offsetAudio; i++) audioBuffer[i] = 0;
              for (let i = -offsetAudio; i < length; i++) audioBuffer[i] = audioBufferSource[i + offsetAudio] * level;
            } else for (let i = 0; i < length; i++) audioBuffer[i] = audioBufferSource[i + offsetAudio] * level;
          }
        }
      } else {
        for (let channel = 0; channel < project.audioBuffer.numberOfChannels; channel++) {
          let audioBuffer = project.audioBuffer.getChannelData(channel);
          let audioBufferSource = project.audioFileBuffer.getChannelData(channel);
          let length = Math.min(project.audioFileBuffer.length, audioBuffer.length);
          if (initialized) {
            if (offsetAudio < 0) for (let i = -offsetAudio; i < length; i++) audioBuffer[i] += audioBufferSource[i + offsetAudio] * level;
            else for (let i = 0; i < length; i++) audioBuffer[i] += audioBufferSource[i + offsetAudio] * level;
          } else {
            if (offsetAudio < 0) {
              for (let i = 0; i < -offsetAudio; i++) audioBuffer[i] = 0;
              for (let i = -offsetAudio; i < length; i++) audioBuffer[i] = audioBufferSource[i + offsetAudio] * level;
            } else for (let i = 0; i < length; i++) audioBuffer[i] = audioBufferSource[i + offsetAudio] * level;
          }
        }
      }

      initialized = true;
    }

    if (!initialized) {
      for (let channel = 0; channel < project.audioBuffer.numberOfChannels; channel++) {
        let audioBuffer = project.audioBuffer.getChannelData(channel);
        for (let i = 0; i < audioBuffer.length; i++) {
          audioBuffer[i] = 0;
        }
      }
    }

    //let sampleRate = project.audioBuffer.sampleRate;

    let messageMusicFadeInDuration = Math.floor(messageMusicPreFadeInDurationEx * sampleRate);
    let musicFadeInDuration = Math.floor(musicFadeInDurationEx * sampleRate);
    let musicFadeOutDuration = Math.floor(musicFadeOutDurationEx * sampleRate);
    let autoFadeAttenuation = project.audioAttOnVoice;

    let gates = getVoiceOverGates(project, sampleRate);
    //let noise = this.license.days <= 0;
    let noise = false;
    // if (
    //   ((project.audioFile && !project.audioFileMute) ||
    //     (project.audioVideo && !project.audioVideoMute)) &&
    //   gates.length === 0 &&
    //   noise
    // ) {
    //   let audioBuffer1 = project.audioBuffer.getChannelData(0);
    //   let audioBuffer2 = project.audioBuffer.getChannelData(1);
    //   let samples = project.videoDuration * 60 * sampleRate;

    //   let add = Math.floor(Math.random() * 8 * sampleRate);
    //   let noiseDuration = 22050;

    //   while (add < samples) {
    //     add = Math.min(add, samples - noiseDuration);

    //     for (let i = 0; i < noiseDuration; i++) {
    //       let noise = (Math.random() * 2 - 1) * 0.07;
    //       audioBuffer1[add++] += noise;
    //       audioBuffer2[add++] += noise;
    //     }

    //     add += 8 * sampleRate;
    //   }
    // }

    for (let channel = 0; channel < project.audioBuffer.numberOfChannels; channel++) {
      let audioBuffer = project.audioBuffer.getChannelData(channel);

      let add = 0;
      let musicAtt = 0;
      let faderPos = faderSteps;
      let faderInc = 1;

      if (project.audioFadeInFadeOut) {
        musicAtt = -60;
        faderPos = getFaderPostion(musicAtt);
        faderInc = (faderSteps - faderPos) / musicFadeInDuration;

        let to = musicFadeInDuration;
        for (let i = 0; i < to; i++) {
          faderPos = Math.min(faderSteps - 1, Math.max(0, faderPos));
          audioBuffer[add++] *= faderCurve[Math.floor(faderPos)];
          faderPos += faderInc;
        }
      }

      let attFaderPos = getFaderPostion(autoFadeAttenuation);
      let faderDec = (faderSteps - attFaderPos) / musicFadeOutDuration;
      faderInc = (faderSteps - attFaderPos) / musicFadeInDuration;
      gates.forEach((gate) => {
        let noisePeriode = 2 * sampleRate;
        let noiseDuration = 0;
        for (let i = gate.From; i < gate.To; i++) {
          faderPos = Math.min(faderSteps - 1, Math.max(0, faderPos));
          audioBuffer[i] *= faderCurve[Math.floor(faderPos)];
          if (faderPos > attFaderPos) faderPos -= faderDec;

          if (noise) {
            if (noisePeriode > 0) {
              noisePeriode--;
              if (noisePeriode === 0) {
                noisePeriode = 6 * sampleRate;
                noiseDuration = 22050;
              }
            }
            if (noiseDuration > 0) {
              noiseDuration--;
              audioBuffer[i] = (Math.random() * 2 - 1) * 0.07;
            }
          }
        }

        for (let i = gate.To; i < gate.To + musicFadeInDuration; i++) {
          faderPos = Math.min(faderSteps - 1, Math.max(0, faderPos));
          audioBuffer[i] *= faderCurve[Math.floor(faderPos)];
          if (faderPos < faderSteps) faderPos += faderInc;
        }
      });

      if (project.audioFadeInFadeOut) {
        let totalSize = project.duration * sampleRate;
        let from = Math.floor(totalSize - musicFadeOutDuration);
        if (gates.length > 0 && from < gates[0].To + messageMusicFadeInDuration) from = gates[0].To + messageMusicFadeInDuration;

        let faderAttPos = getFaderPostion(musicAtt);
        faderDec = (faderSteps - faderAttPos) / musicFadeOutDuration;
        for (let i = from; i < totalSize; i++) {
          faderPos = Math.min(faderSteps - 1, Math.max(0, faderPos));
          audioBuffer[i] *= faderCurve[Math.floor(faderPos)];
          if (faderPos > faderAttPos) faderPos -= faderDec;
        }
      }
    }

    resolve();
  });
};

function getVoiceOverGates(project, sampleRate) {
  let messageMusicPreFadeOutDuration = Math.floor(messageMusicPreFadeOutDurationEx * sampleRate);
  let messageMusicPreFadeInDuration = Math.floor(messageMusicPreFadeInDurationEx * sampleRate);
  let holdTime = Math.floor(project.audioAttHoldTime * sampleRate);

  project.blocks.sort(function (a, b) {
    return a.time_start - b.time_start;
  });

  let gates = [];
  let cut = 0;
  let offset = 0;

  project.blocks.forEach((block) => {
    while (cut < project.cuts.length) {
      if (project.cuts[cut].time_start >= block.time_start - offset) break;
      if (!project.cuts[cut].expand) offset += project.cuts[cut].time_end - project.cuts[cut].time_start;
      cut++;
    }

    let from = Math.floor((block.time_start - offset) * sampleRate - messageMusicPreFadeInDuration);
    let to = Math.floor((block.time_end - offset) * sampleRate - messageMusicPreFadeOutDuration);
    if (block.audioBuffer) to = Math.floor((block.time_start + block.audioBuffer.duration - offset) * sampleRate - messageMusicPreFadeOutDuration);

    if (gates.length > 0 && from - gates[gates.length - 1].To < holdTime) gates[gates.length - 1].To = to;
    else gates.push({ From: from, To: to });
  });

  return gates;
}

// function getFaderPostion(db) {
//   let faderLinearRange = (faderSteps * faderLinearRangeEx) / 100;
//   let add = 0;
//   if (db > faderLinearAtt)
//     add = Math.floor(
//       ((faderSteps - faderLinearRange) / faderLinearAtt) *
//         (faderLinearAtt - db) +
//         faderLinearRange
//     );
//   else
//     add = Math.floor(
//       Math.pow(2, (-faderLinearAtt + db) / 6) * faderLinearRange
//     );

//   add = Math.min(faderSteps - 1, Math.max(0, add));
//   return add;
// }

function getFaderPostion(db) {
  let faderLinearRange = (faderSteps * faderLinearRangeEx) / 100;
  let add = 0;

  //add = Math.floor(Math.pow(2, (-faderLinearAtt + db) / 6) * faderLinearRange);
  add = Math.floor(Math.pow(2, db / 6) * faderLinearRange);

  add = Math.min(faderSteps - 1, Math.max(0, add));
  return add;
}

function updateFaderCurve() {
  let gainAtt = Math.pow(2, faderLinearAtt / 6);
  let gain = 0.0;
  let linearTo = Math.floor((faderSteps * faderLinearRangeEx) / 100);
  let coef = gainAtt / linearTo;

  for (let i = 0; i < linearTo; i++) {
    faderCurve.push(gain);
    gain += coef;
  }

  coef = 1 / Math.exp(Math.log(gainAtt) / (faderSteps - linearTo));
  for (let i = linearTo; i < faderSteps; i++) {
    gain *= coef;
    faderCurve.push(gain);
  }
}

function trimAudioBuffer(buffFrom) {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  var buffer = buffFrom.getChannelData(0);

  let threshold = Math.pow(2.0, -30.0 / 6.0);
  let detectionOn = 0;
  let detectionOff = buffer.length;
  for (let i = 0; i < buffer.length; i++) {
    let absValue = Math.abs(buffer[i]);
    if (detectionOn === 0) {
      if (absValue > threshold) detectionOn = i;
    } else {
      if (absValue > threshold) detectionOff = i;
    }
  }

  let bufferTo = audioCtx.createBuffer(1, detectionOff - detectionOn, audioCtx.sampleRate);
  let buffTo = bufferTo.getChannelData(0);
  let add = 0;
  for (let i = detectionOn; i < detectionOff; i++) buffTo[add++] = buffer[i];

  return bufferTo;
}

export { computMix, computVideo, createMp3File, doTranscode, loadVideoFile, loadAudioFile, processAudioBuffer, updateAudioBuffer, computSubtitles, toWaveFileBuffer, trimAudioBuffer };
