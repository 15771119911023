import * as CryptoJS from "crypto-js";
import { saveAs } from "file-saver";
import { loadAudioFile, processAudioBuffer } from "../js/Audio";

const newProject = () => {
  const project = {
    blocks: [],
    cuts: [],
    phonetics: [],
    videoData: null,
    videoSrc: null,
    videExt: "",
    videoDuration: 0,
    duration: 0,
    audioBuffer: null,
    audioFileBuffer: null,
    audioFileWaveForm: null,
    audioVideoBuffer: null,
    audioVideoWaveForm: null,
    name: "",
    audioFile: "",
    audioFileMute: false,
    audioFileLevel: 0,
    audioVideo: "",
    audioVideoMute: false,
    audioVideoLevel: 0,
    audioAttOnVoice: -15,
    audioAttHoldTime: 2,
    audioFadeInFadeOut: true,
    offsetAudioFile: 0,

    version: "",
  };

  return project;
};

const saveProject = async ({ project, updateSpinner }) => {
  const zip = require("jszip")();

  return new Promise((resolve, reject) => {
    let blocks = [];
    let phonetics = [];
    let cuts = [];
    project.blocks.forEach((block) => {
      let blockData = block.data;
      if (block.mp3Buffer && block.mp3Buffer.byteLength > 0) {
        let i8a = new Uint8Array(block.mp3Buffer);
        var byteArray = [];
        for (let i = 0; i < i8a.length; i += 4) byteArray.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);

        blockData = CryptoJS.AES.encrypt(JSON.stringify(byteArray), "a8jk96ShP1").toString();
      }

      blocks.push({
        text_id: block.text_id,
        voice_public_name: block.voice_public_name,
        language_name: block.language_name,
        text: block.text,
        speed: block.speed,
        contour: block.contour,
        time_start: block.time_start,
        time_end: block.time_end,
        waveform: null,
        mp3Buffer: null,
        data: blockData,
        text_origin: block.text_origin,
        language_origin: block.language_origin,
        translate_timer: 0,
        update_voice: false,
      });
    });

    project.phonetics.forEach((phonetic) => {
      phonetics.push({
        phoneticText: phonetic.phoneticText,
        phoneticTranscription: phonetic.phoneticTranscription,
        phoneticIPA: phonetic.phoneticIPA,
      });
    });

    project.cuts.forEach((cut) => {
      cuts.push({
        expand: cut.expand,
        time_start: cut.time_start,
        time_end: cut.time_end,
      });
    });

    //  if (project.videoData) {
    //   let blob = new Blob([project.videoData], { type: "" });
    //   zip.file(project.videoFileName, blob);
    // }

    if (project.videoData) zip.file(project.videoFileName, project.videoData);

    if (project.audioFileBufferOrigin) {
      let blob = new Blob([project.audioFileBufferOrigin], { type: "" });
      zip.file(project.audioFileName, blob);
    }

    let data = {
      version: "2023-05",

      name: project.name,
      audioFile: project.audioFile,
      audioFileMute: project.audioFileMute,
      audioFileLevel: project.audioFileLevel,
      audioVideo: project.audioVideo,
      videoExt: project.videoExt,
      videoDuration: project.videoDuration,
      audioVideoMute: project.audioVideoMute,
      audioVideoLevel: project.audioVideoLevel,
      audioAttOnVoice: project.audioAttOnVoice,
      audioAttHoldTime: project.audioAttHoldTime,
      audioFadeInFadeOut: project.audioFadeInFadeOut,
      offsetAudioFile: project.offsetAudioFile,
      duration: project.duration,
      blocks: blocks,
      phonetics: phonetics,
      cuts: cuts,
    };

    const json = JSON.stringify(data);
    zip.file("data.json", json);
    zip.generateAsync({ type: "blob", compression: "STORE" }, updateCallback).then(function (content) {
      saveAs(content, project.name + ".zip");
      updateSpinner({ visible: false, info: "", percent: "" });
      resolve();
    });

    function updateCallback(metaData) {
      const percent = metaData.percent;
      updateSpinner({
        visible: true,
        info: "SAVE PROJECT",
        percent: Math.floor(percent) + "%",
      });
    }
  });
};

const loadProject = async (file, updateSpinner, mode) => {
  const zip = require("jszip")();
  return new Promise((resolve, reject) => {
    const project = newProject();

    zip.loadAsync(file).then(function (z) {
      z.file("data.json")
        .async("string")
        .then(async (s) => {
          let json = JSON.parse(s);

          if (json.version) project.version = json.version;

          project.blocks = json.blocks;
          project.phonetics = json.phonetics;
          project.duration = 0;
          project.offsetAudioFile = 0;

          if (project.version === "2023-05") {
            if (mode === "tuto") project.cuts = json.cuts;

            project.name = json.name;
            project.audioFile = json.audioFile;
            project.audioFileMute = json.audioFileMute;
            project.audioFileLevel = json.audioFileLevel;
            project.audioVideo = json.audioVideo;
            project.videoDuration = json.videoDuration;
            project.videoExt = json.videoExt;
            project.audioVideoMute = json.audioVideoMute;
            project.audioVideoLevel = json.audioVideoLevel;
            project.audioAttOnVoice = json.audioAttOnVoice;
            project.audioAttHoldTime = json.audioAttHoldTime;
            project.audioFadeInFadeOut = json.audioFadeInFadeOut;
            project.duration = json.duration;

            if (json.offsetAudioFile) project.offsetAudioFile = json.offsetAudioFile;

            if (mode === "tuto") project.cuts = json.cuts;
          } else {
            project.name = json.mixSettings.ProjectName;
            project.audioFile = json.mixSettings.AudioFile;
            project.audioFileMute = json.mixSettings.AudioFileoMute;
            project.audioFileLevel = json.mixSettings.AudioFileLevel;
            project.audioVideo = json.mixSettings.AudioVideo;
            project.audioVideoMute = json.mixSettings.AudioVideoMute;
            project.audioVideoLevel = json.mixSettings.AudioVideoLevel;
            project.audioAttOnVoice = json.mixSettings.AudioAttOnVoice;
            project.audioAttHoldTime = json.mixSettings.AudioAttHoldTime;
            project.audioFadeInFadeOut = json.mixSettings.FadeInFadeOut;

            let offset = 0;
            json.cuts.forEach((cut) => {
              if (cut.from) cut.time_start = cut.from - offset;
              if (cut.from) cut.time_end = cut.to - offset;
              if (cut.time_start && cut.time_end) {
                if (!cut.expand) offset += cut.time_end - cut.time_start;

                if (!cut.id) cut.id = generateGuid();
                project.cuts.push(cut);
              }
            });
          }

          const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
          updateSpinner({ visible: true, info: "LOAD PROJECT BLOCK", percent: "" });
          project.blocks.forEach((block) => {
            if (!block.id) block.id = generateGuid();

            if (block.data && block.data !== "") {
              let bytes = CryptoJS.AES.decrypt(block.data, "a8jk96ShP1");
              let decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              let i8a = new Uint8Array(decryptData.length * 4);
              let add = 0;
              for (let i = 0; i < decryptData.length; i++) {
                let bytes = decryptData[i];
                i8a[add++] = bytes >> 24;
                i8a[add++] = bytes >> 16;
                i8a[add++] = bytes >> 8;
                i8a[add++] = bytes;
              }

              // block.mp3Buffer = new ArrayBuffer(add);
              // new Uint8Array(block.mp3Buffer).set(i8a);

              // let buff = new ArrayBuffer(add);
              // new Uint8Array(buff).set(i8a);

              // audioCtx.decodeAudioData(buff, function (buffer) {
              //   block.buffer_ready = true;
              //   processBlockBuffer(block, buffer, audioCtx);
              // });

              block.mp3Buffer = new ArrayBuffer(add);
              new Uint8Array(block.mp3Buffer).set(i8a);

              audioCtx.decodeAudioData(block.mp3Buffer, function (buffer) {
                block.buffer_ready = true;
                processBlockBuffer(block, buffer, audioCtx);
              });
            }
          });

          await new Promise((resolve, reject) => {
            let length = Object.entries(z.files).length;
            z.forEach((relativePath, file) => {
              if (file.name.split(".").pop() === "mp3" || file.name.split(".").pop() === "wav") {
                z.file(file.name)
                  .async("blob")
                  .then(async function (data) {
                    updateSpinner({ visible: true, info: "LOAD PROJECT AUDIO", percent: "" });

                    await loadAudioFile(data, project);
                    project.audioFileName = file.name;
                    length--;
                    if (length === 0) resolve();
                  });
              } else if (file.name.split(".").pop() === "mp4" || file.name.split(".").pop() === "avi" || file.name.split(".").pop() === "mov" || file.name.split(".").pop() === "m4v") {
                z.file(file.name)
                  .async("blob")
                  .then(function (data) {
                    project.videoData = data;
                    project.videoFileName = file.name;
                    project.videoExt = file.name.split(".").pop();
                    updateSpinner({ visible: true, info: "LOAD PROJECT VIDEO", percent: "" });

                    let fileReader = new FileReader();
                    fileReader.onload = function (event) {
                      audioCtx
                        .decodeAudioData(event.target.result)
                        .then(function (buffer) {
                          project.audioVideoBuffer = buffer;
                          project.audioVideoWaveForm = document.createElement("canvas");
                          processAudioBuffer(project.audioVideoBuffer, project.audioVideoWaveForm);

                          length--;
                          if (length === 0) resolve();
                        })
                        .catch(function (error) {
                          length--;
                          if (length === 0) resolve();
                        });
                    };
                    fileReader.readAsArrayBuffer(data);
                  });
              } else {
                length--;
                if (length === 0) resolve();
              }
            });
          });

          resolve(project);
          //reject(error);
        });
    });
  });
};

function processBlockBuffer(block, buffer, audioCtx) {
  block.audioBuffer = audioCtx.createBuffer(buffer.numberOfChannels, buffer.length, buffer.sampleRate);
  block.time_end = block.time_start + buffer.duration;
  let data = block.audioBuffer.getChannelData(0);
  let dataEx = buffer.getChannelData(0);
  let size = Math.min(data.length, dataEx.length);
  for (let i = 0; i < size; i++) data[i] = dataEx[i];

  let samplePerPixel = 400;
  let waveformHeight = 100;
  block.waveform = document.createElement("canvas");
  let context = block.waveform.getContext("2d");
  context.translate(0.5, 0.5);
  block.waveform.width = size / samplePerPixel;
  block.waveform.height = waveformHeight;

  let min = 1.0;
  let max = -1.0;
  let left = 0;
  let add = 0;
  let d;

  context.lineWidth = 1;
  context.fillStyle = "#ffffffe0";

  context.fillRect(0, waveformHeight / 2, block.waveform.width, 1);

  while (add < size) {
    min = 1.0;
    max = -1.0;
    for (let i = 0; i < samplePerPixel; i++) {
      d = data[add++];

      if (d < min) min = d;
      if (d > max) max = d;

      if (add >= size) break;
    }

    left = add / samplePerPixel;
    let maxMinDifference = ((max - min) * waveformHeight) / 4;
    context.fillRect(left, waveformHeight / 2 - maxMinDifference, 1, maxMinDifference * 2);
  }
}

function generateGuid() {
  let currentDateMilliseconds = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (currentChar) {
    let randomChar = (currentDateMilliseconds + Math.random() * 16) % 16 | 0;
    currentDateMilliseconds = Math.floor(currentDateMilliseconds / 16);
    return (currentChar === "x" ? randomChar : (randomChar & 0x7) | 0x8).toString(16);
  });
}

const loadSubtitleTextFile = async (file, project) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function () {
      let result = reader.result;

      project.blocks = [];
      let from = 0;
      let splits = result.replace("\r", "").split("\n");

      splits.forEach((split) => {
        let s = split.trim();
        if (s !== "") {
          from += 2;
          let to = from + s.length * 0.08;

          project.blocks.push({
            id: generateGuid(),
            voice_public_name: "",
            language_name: "",
            speed: "SpeedNormal",
            text: s,
            time_start: from,
            time_end: to,
            waveform: null,
          });
          from = to;
        }
      });

      resolve();
    };

    reader.readAsText(file);
  });
};

const loadSubtitleFile = async (file, project) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function () {
      let result = reader.result;
      project.blocks = [];

      let splits = result.replace("\r", "").split("\n");

      let text = "";
      let from = -1;
      let to = -1;
      splits.forEach((split) => {
        let s = split.trim();
        if (s === "") {
          if (text !== "" && from >= 0 && to > from) {
            project.blocks.push({
              id: generateGuid(),
              voice_public_name: "",
              language_name: "",
              speed: "SpeedNormal",
              text: text,
              time_start: from,
              time_end: to,
              waveform: null,
            });
            from = to;
          }

          text = "";
          from = -1;
          to = -1;
        } else if (from < 0) {
          let times = s.split("-->");
          if (times.length > 1) {
            from = stringToTime(times[0]);
            to = stringToTime(times[1]);
            text = "";
          }
        } else {
          if (text !== "") text += "\r\n";
          text += s;
        }
      });

      if (text !== "" && from >= 0 && to > from) {
        project.blocks.push({
          text_id: "",
          voice_public_name: "",
          language_name: "",
          speed: "SpeedNormal",
          contour: "",
          text: text,
          time_start: from,
          time_end: to,
          waveform: null,
          mp3Buffer: null,
          audioBuffer: null,
          audioBufferSource: null,
          audio_file_timer: 0,
          save_timer: 0,
          save_ready: false,
          buffer_ready: false,
          text_origin: "",
          translate_timer: 0,
          update_voice: false,
        });
      }

      resolve();
    };

    reader.readAsText(file);
  });
};

function stringToTime(s) {
  let time = -1;
  let splits = s.trim().replace(".", ":").replace(",", ":").split(":");
  if (splits.length > 2) {
    time = Number(splits[0]) * 3600;
    time += Number(splits[1]) * 60;
    time += Number(splits[2]);
    if (splits.length > 3) time += Number("0." + splits[3]);
  }

  return time;
}

export { newProject, saveProject, loadProject, processBlockBuffer, generateGuid, loadSubtitleTextFile, loadSubtitleFile };
