import React from "react";

const Settings = ({ project, isOpen, closeHandler, handleSettingsChange }) => {
  if (!isOpen) return null;
  return (
    <div>
      <div className="settings">
        <div className="modal-container" onClick={closeHandler}>
          <div className="modal-box" onClick={(e) => e.stopPropagation()}>
            <div className="container">
              <div className="close-btn" onClick={closeHandler}>
                <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
              </div>
              <div style={{ height: "20px" }}></div>
              <h2>Audio Settings</h2>
              <div className="space"></div>
              <div className="slider-param" style={{ opacity: project.audioVideoBuffer ? 1.0 : 0.5 }}>
                <span className="title">Audio Video Level</span>
                <img alt="mute" className="btn-icon" src={project.audioVideoMute ? "./img/volume_off_white.png" : "./img/volume_up_white.png"} name="audioVideoMute" onClick={handleSettingsChange} />
                <input type="range" min="-15" max="15" name="audioVideoLevel" onChange={handleSettingsChange} value={project.audioVideoLevel} />
                <input type="text" className="text-slider" name="audioVideoLevel" onChange={handleSettingsChange} value={project.audioVideoLevel} />
                <span className="title" style={{ width: "20px" }}>
                  db
                </span>
              </div>

              <div className="slider-param" style={{ opacity: project.audioFileBuffer ? 1.0 : 0.5 }}>
                <span className="title">Audio File Level</span>
                <img alt="mute" className="btn-icon" src={project.audioFileMute ? "./img/volume_off_white.png" : "./img/volume_up_white.png"} name="audioFileMute" onClick={handleSettingsChange} />
                <input type="range" min="-15" max="15" name="audioFileLevel" onChange={handleSettingsChange} value={project.audioFileLevel} />
                <input type="text" className="text-slider" name="audioFileLevel" onChange={handleSettingsChange} value={project.audioFileLevel} />

                <span className="title" style={{ width: "20px" }}>
                  db
                </span>
              </div>

              <div className="slider-param">
                <span className="title">Auto Fade Attenuation</span>
                <input style={{ width: "230px" }} type="range" min="-20" max="0" name="audioAttOnVoice" onChange={handleSettingsChange} value={project.audioAttOnVoice} />
                <input type="text" className="text-slider" name="audioAttOnVoice" onChange={handleSettingsChange} value={project.audioAttOnVoice} />
                <span className="title" style={{ width: "20px" }}>
                  db
                </span>
              </div>

              <div className="slider-param">
                <span className="title">Auto Fade Hold Time</span>
                <input style={{ width: "230px" }} type="range" min="0" max="10" name="audioAttHoldTime" onChange={handleSettingsChange} value={project.audioAttHoldTime} />
                <input type="text" className="text-slider" name="audioAttHoldTime" onChange={handleSettingsChange} value={project.audioAttHoldTime} />
                <span className="title" style={{ width: "20px" }}>
                  sec
                </span>
              </div>

              {/* project.audioAttOnVoice = json.audioAttOnVoice,
            project.audioAttHoldTime = json.audioAttHoldTime,
            project.audioFadeInFadeOut = json.audioFadeInFadeOut, */}

              <div className="info">
                Auto Attenuation attenuates audio when voiceover appears by a value expressed in dB. The attenuation is maintained when the silence between 2 voice overs is less than the value of the 'Attenuation maintenance time' expressed
                in seconds.
              </div>

              <div className="slider-param">
                <span className="title">Fade In / Fade Out</span>
                <label className="checkbox">
                  <input type="checkbox" name="audioFadeInFadeOut" onChange={handleSettingsChange} checked={project.audioFadeInFadeOut} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="info">Fade In / Fade Out increases the sound gradually at the start of the mix and gradually fades it at the end.</div>
              <div className="space"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
