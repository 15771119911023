import React, { useRef, useEffect, useState } from "react";

function draw(canvas, props, cutSelected = -1) {
  // let duration = props.project.duration;

  // props.project.cuts.forEach((cut) => {
  //   if (!cut.expand) duration += cut.time_end - cut.time_start;
  // });

  let duration = props.videoDuration;
  props.project.cuts.forEach((cut) => {
    if (cut.expand) duration += cut.time_end - cut.time_start;
    //else duration -= cut.time_end - cut.time_start;
  });

  let currentTime = props.currentTime.time;

  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;

  const pixelPerSecond = canvas.width / duration;
  props.updatePixelPerSecond(pixelPerSecond);

  const context = canvas.getContext("2d");
  //context.translate(0.5, 0.5);

  context.clearRect(0, 0, canvas.width, canvas.height);

  context.globalAlpha = 1;
  context.shadowBlur = 0;
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.lineWidth = 1;
  context.strokeStyle = "#ffffff80";
  context.font = "10px Arial";

  if (cutSelected < 0) {
    props.project.cuts.sort(function (a, b) {
      return a.time_start - b.time_start;
    });
  }

  let expandTime = 0;
  for (let c = 0; c < props.project.cuts.length; c++) {
    let cut = props.project.cuts[c];
    let left = cut.time_start + expandTime;
    let range = (cut.time_end - cut.time_start) * pixelPerSecond;
    let resizeWidth = Math.floor(Math.min(8, range / 2));

    context.fillStyle = cut.expand ? "#d9ed92" : "#a692ed";
    context.fillRect(left * pixelPerSecond, canvas.height - 68, range, 24);

    context.fillStyle = cut.expand ? "#b9d458" : "#7358d4";
    context.fillRect(left * pixelPerSecond, canvas.height - 68, resizeWidth, 24);

    context.fillRect(left * pixelPerSecond + range - resizeWidth, canvas.height - 68, resizeWidth, 24);

    if (!props.project.cuts[c].expand) expandTime += cut.time_end - cut.time_start;
  }

  context.fillStyle = "#ffffffc0";
  context.shadowColor = "#ffffff80";
  context.shadowBlur = 2;

  if (canvas.width < 10000 && props.project.audioVideoBuffer && !props.project.audioVideoMute) {
    context.globalAlpha = props.project.audioVideoMute ? 0.2 : 0.7;

    let videoPixelPerSecond = props.project.audioVideoWaveForm.width / props.project.audioVideoBuffer.duration;
    let addDest = 0;
    let addSrc = 0;
    let offset = 0;

    for (let c = 0; c < props.project.cuts.length; c++) {
      let cutFrom = props.project.cuts[c].time_start + offset;
      let cutTo = props.project.cuts[c].time_end + offset;
      let range = cutFrom - addDest;

      context.drawImage(props.project.audioVideoWaveForm, addSrc * videoPixelPerSecond, 0, range * videoPixelPerSecond, props.project.audioVideoWaveForm.height, addDest * pixelPerSecond, canvas.height - 46, range * pixelPerSecond, 14);

      addDest += range;
      addSrc += range;

      if (props.project.cuts[c].expand) {
        addDest += cutTo - cutFrom;
      } else {
        addSrc += cutTo - cutFrom;
        addDest += cutTo - cutFrom;
        offset += cutTo - cutFrom;
      }
    }

    let range = duration - addDest + offset;
    context.drawImage(props.project.audioVideoWaveForm, addSrc * videoPixelPerSecond, 0, range * videoPixelPerSecond, props.project.audioVideoWaveForm.height, addDest * pixelPerSecond, canvas.height - 46, range * pixelPerSecond, 14);
  }

  if (canvas.width < 10000 && props.project.audioFileBuffer && !props.project.audioFileMute) {
    context.globalAlpha = props.project.audioFileMute ? 0.2 : 0.7;
    let audioPixelPerSecond = props.project.audioFileWaveForm.width / props.project.audioFileBuffer.duration;

    let addDest = 0;
    let offset = 0;
    let addSrc = props.project.offsetAudioFile;

    for (let c = 0; c < props.project.cuts.length; c++) {
      let cutFrom = props.project.cuts[c].time_start + offset;
      let cutTo = props.project.cuts[c].time_end + offset;
      let range = cutFrom - addDest;

      context.drawImage(props.project.audioFileWaveForm, addSrc * audioPixelPerSecond, 0, range * audioPixelPerSecond, props.project.audioFileWaveForm.height, addDest * pixelPerSecond, canvas.height - 30, range * pixelPerSecond, 14);

      addDest += range;
      addSrc += range;

      if (!props.project.cuts[c].expand) {
        addDest += cutTo - cutFrom;
        offset += cutTo - cutFrom;
      }
    }

    let range = duration - addSrc + offset;
    context.drawImage(props.project.audioFileWaveForm, addSrc * audioPixelPerSecond, 0, range * audioPixelPerSecond, props.project.audioFileWaveForm.height, addDest * pixelPerSecond, canvas.height - 30, range * pixelPerSecond, 14);
  }

  context.globalAlpha = 1;
  let seconds = 0;
  let left = 0;
  let top = 10;

  let step = (context.measureText("00:00").width / pixelPerSecond) * 2;
  const steps = [1, 2, 5, 10, 20, 30, 60, 120, 240, 480];
  for (let i = 0; i < steps.length; i++) {
    if (step < steps[i]) {
      step = steps[i];
      break;
    }
  }

  if (!pixelPerSecond) return;

  context.beginPath();

  context.moveTo(Math.floor(0), Math.floor(top));
  context.lineTo(Math.floor(canvas.width), Math.floor(top));

  let cut = 0;
  let text;
  let metrics;
  expandTime = 0;
  while (left <= canvas.width) {
    context.moveTo(Math.floor(left), Math.floor(top));
    context.lineTo(Math.floor(left), Math.floor(top + 5));

    text =
      Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (seconds % 60).toString().padStart(2, "0");
    metrics = context.measureText(text);
    context.fillText(text, Math.floor(left - metrics.width / 2), Math.floor(top + 13));

    left += pixelPerSecond * step;
    seconds += step;

    if (cut < props.project.cuts.length) {
      if (left > (props.project.cuts[cut].time_start + expandTime) * pixelPerSecond) {
        let range = props.project.cuts[cut].time_end - props.project.cuts[cut].time_start;
        if (!props.project.cuts[cut].expand) {
          left += range * pixelPerSecond;
          expandTime += range;
        }
        cut++;
      }
    }
  }

  context.moveTo(Math.floor(canvas.width), Math.floor(top));
  context.lineTo(Math.floor(canvas.width), Math.floor(top + 5));

  expandTime = 0;
  for (let i = 0; i < props.project.cuts.length; i++) {
    if (currentTime < props.project.cuts[i].time_start) break;
    if (props.project.cuts[i].expand) {
    } else {
      expandTime += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
    }
  }

  left = currentTime * pixelPerSecond + expandTime * pixelPerSecond;
  if (props.currentTime.playing) {
    if (left > props.blockZoneRef.current.scrollLeft + props.blockZoneRef.current.clientWidth || left < props.blockZoneRef.current.scrollLeft) props.blockZoneRef.current.scrollLeft = left;
  }

  context.stroke();

  context.fillStyle = "#fff";
  context.fillRect(left, 0, 1, canvas.height - 15);

  context.font = "10px Arial";
  seconds = Math.floor(currentTime);
  text =
    Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    (seconds % 60).toString().padStart(2, "0");

  seconds = Math.floor(props.project.duration);
  text +=
    " | " +
    Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    (seconds % 60).toString().padStart(2, "0");

  metrics = context.measureText(text);
  context.fillText(text, Math.floor(left - metrics.width / 2 + 1), canvas.height - 5);
}

function CanvasBlock(props) {
  const canvasRef = useRef(null);

  const [mouseState] = useState({
    mouseDown: -1,
    mouseMoved: false,
    cutDown: 0,
    cutChanged: false,
    ofssetAudioFileDown: false,
  });

  useEffect(() => {
    draw(canvasRef.current, props);
  }, [props]);

  useEffect(() => {
    const element = canvasRef.current;

    const handleCanvasMouseDown = (e) => {
      if (props.project.duration) {
        let duration = props.project.duration;
        props.project.cuts.forEach((cut) => {
          if (!cut.expand) duration += cut.time_end - cut.time_start;
        });

        let pixelPerSecond = canvasRef.current.width / duration;
        let currentTime = e.offsetX / pixelPerSecond;

        if (!mouseState.ofssetAudioFileDown && props.project.audioFileBuffer && !props.project.audioFileMute && e.offsetY >= element.height - 30 && e.offsetY <= element.height - 16) {
          mouseState.ofssetAudioFileDown = true;
        }
        if (mouseState.mouseDown < 0) {
          if (e.offsetY >= element.height - 68 && e.offsetY <= element.height - 48) {
            const margeTime = 8 / pixelPerSecond;
            let expandTime = 0;
            for (let i = 0; i < props.project.cuts.length; i++) {
              if (currentTime >= props.project.cuts[i].time_start + expandTime && currentTime < props.project.cuts[i].time_end + expandTime) {
                mouseState.cutDown = i;
                mouseState.mouseDownOffsetX = e.offsetX;
                mouseState.mouseDownStart = props.project.cuts[i].time_start;
                mouseState.mouseDownEnd = props.project.cuts[i].time_end;
                mouseState.mouseDown = 1;

                if (currentTime < props.project.cuts[i].time_start + expandTime + margeTime) mouseState.mouseDown = 0;

                if (currentTime > props.project.cuts[i].time_end + expandTime - margeTime) mouseState.mouseDown = 2;

                break;
              }

              if (props.project.cuts[i].expand) {
              } else {
                expandTime += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
              }
            }
          }

          if (mouseState.mouseDown < 0) {
            for (let i = 0; i < props.project.cuts.length; i++) {
              if (currentTime < props.project.cuts[i].time_start) break;

              let range = props.project.cuts[i].time_end - props.project.cuts[i].time_start;

              if (!props.project.cuts[i].expand && currentTime < props.project.cuts[i].time_end) {
                currentTime = props.project.cuts[i].time_end - range;
                break;
              }

              if (props.project.cuts[i].expand) {
              } else {
                currentTime -= range;
              }
            }

            props.currentTime.time = currentTime;
            props.currentTimeChange(currentTime);
            draw(canvasRef.current, props);
          }
        } else {
          let cut = -1;
          let expandTime = 0;
          const margeTime = 8 / pixelPerSecond;
          for (let i = 0; i < props.project.cuts.length; i++) {
            if (currentTime >= props.project.cuts[i].time_start + expandTime && currentTime < props.project.cuts[i].time_end + expandTime) {
              cut = i;
              if (currentTime < props.project.cuts[i].time_start + expandTime + margeTime || currentTime > props.project.cuts[i].time_end + expandTime - margeTime) {
                element.style.cursor = "ew-resize";
              } else {
                element.style.cursor = "pointer";
              }

              break;
            }
            if (props.project.cuts[i].expand) {
            } else {
              expandTime += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
            }
          }

          if (cut < 0) element.style.cursor = "default";
        }
      }
    };

    element.addEventListener("mousedown", handleCanvasMouseDown);
    return () => {
      element.removeEventListener("mousedown", handleCanvasMouseDown);
    };
  }, [props, mouseState]);

  useEffect(() => {
    const element = canvasRef.current;

    const handleCanvasDoucbleClick = (e) => {
      let duration = props.project.duration;
      props.project.cuts.forEach((cut) => {
        if (!cut.expand) duration += cut.time_end - cut.time_start;
      });

      let pixelPerSecond = canvasRef.current.width / duration;
      let currentTime = e.offsetX / pixelPerSecond;

      let expandTime = 0;

      for (let i = 0; i < props.project.cuts.length; i++) {
        if (currentTime >= props.project.cuts[i].time_start + expandTime && currentTime < props.project.cuts[i].time_end + expandTime) {
          props.deleteCut(i);
          break;
        }
        if (props.project.cuts[i].expand) {
        } else {
          expandTime += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
        }
      }
    };

    element.addEventListener("dblclick", handleCanvasDoucbleClick);
    return () => {
      element.removeEventListener("dblclick", handleCanvasDoucbleClick);
    };
  }, [props]);

  useEffect(() => {
    const element = canvasRef.current;

    const handleCanvasMouseUp = (event) => {
      mouseState.mouseDown = -1;

      if (mouseState.ofssetAudioFileDown) {
        mouseState.ofssetAudioFileDown = false;
      }

      if (mouseState.cutChanged) props.updateAudio();
      mouseState.cutChanged = false;
    };

    element.addEventListener("mouseup", handleCanvasMouseUp);
    return () => {
      element.removeEventListener("mouseup", handleCanvasMouseUp);
    };
  }, [props, mouseState]);

  useEffect(() => {
    const element = canvasRef.current;
    const handleCanvasMouseMove = (e) => {
      if (props.project.duration) {
        let duration = props.project.duration;
        props.project.cuts.forEach((cut) => {
          if (!cut.expand) duration += cut.time_end - cut.time_start;
        });

        let pixelPerSecond = canvasRef.current.width / duration;
        let currentTime = e.offsetX / pixelPerSecond;
        let movement = e.movementX / pixelPerSecond;

        if (mouseState.ofssetAudioFileDown) {
          mouseState.cutChanged = true;
          props.project.offsetAudioFile -= movement;
          draw(canvasRef.current, props);
        } else if (mouseState.mouseDown >= 0) {
          mouseState.cutChanged = true;

          let cut = props.project.cuts[mouseState.cutDown];

          if (mouseState.mouseDown === 0) {
            if (mouseState.cutDown > 0) {
              let timeStart = props.project.cuts[mouseState.cutDown - 1].time_end;
              if (!props.project.cuts[mouseState.cutDown - 1].expand) timeStart -= props.project.cuts[mouseState.cutDown - 1].time_end - props.project.cuts[mouseState.cutDown - 1].time_start;
              if (props.project.cuts[mouseState.cutDown].time_start + movement < timeStart) movement = timeStart - props.project.cuts[mouseState.cutDown].time_start;
            }

            if (cut.time_start + movement > cut.time_end - 0.5) movement = cut.time_end - 0.5 - cut.time_start;

            cut.time_start += movement;

            if (cut.expand) {
              for (let i = mouseState.cutDown + 1; i < props.project.cuts.length; i++) {
                props.project.cuts[i].time_start -= movement;
                props.project.cuts[i].time_end -= movement;
              }

              let offset = 0;
              for (let i = 0; i < props.project.cuts.length; i++) {
                if (props.project.cuts[i].time_start >= cut.time_start) break;
                if (!props.project.cuts[i].expand) offset += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
              }

              for (let i = 0; i < props.project.blocks.length; i++) {
                if (props.project.blocks[i].time_start > cut.time_start + offset) {
                  props.project.blocks[i].time_start -= movement;
                  props.project.blocks[i].time_end -= movement;
                }
              }
            } else {
              for (let i = mouseState.cutDown + 1; i < props.project.cuts.length; i++) {
                props.project.cuts[i].time_start += movement;
                props.project.cuts[i].time_end += movement;
              }
            }
          } else if (mouseState.mouseDown === 1) {
            if (mouseState.cutDown > 0) {
              let timeStart = props.project.cuts[mouseState.cutDown - 1].time_end;
              if (!props.project.cuts[mouseState.cutDown - 1].expand) timeStart -= props.project.cuts[mouseState.cutDown - 1].time_end - props.project.cuts[mouseState.cutDown - 1].time_start;
              if (props.project.cuts[mouseState.cutDown].time_start + movement < timeStart) movement = timeStart - props.project.cuts[mouseState.cutDown].time_start;
            }

            if (mouseState.cutDown < props.project.cuts.length - 1) {
              let timeEnd = props.project.cuts[mouseState.cutDown + 1].time_start;
              if (!props.project.cuts[mouseState.cutDown].expand) timeEnd += props.project.cuts[mouseState.cutDown].time_end - props.project.cuts[mouseState.cutDown].time_start;
              if (props.project.cuts[mouseState.cutDown].time_end + movement > timeEnd) movement = timeEnd - props.project.cuts[mouseState.cutDown].time_end;
            }

            cut.time_start += movement;
            cut.time_end += movement;
          } else {
            if (mouseState.cutDown < props.project.cuts.length - 1) {
              let timeEnd = props.project.cuts[mouseState.cutDown + 1].time_start;
              if (!props.project.cuts[mouseState.cutDown].expand) timeEnd += props.project.cuts[mouseState.cutDown].time_end - props.project.cuts[mouseState.cutDown].time_start;
              if (props.project.cuts[mouseState.cutDown].time_end + movement > timeEnd) movement = timeEnd - props.project.cuts[mouseState.cutDown].time_end;
            }

            if (cut.time_end + movement < cut.time_start + 0.5) movement = cut.time_start + 0.5 - cut.time_end;

            cut.time_end += movement;

            if (cut.expand) {
              let offset = 0;
              for (let i = 0; i < props.project.cuts.length; i++) {
                if (props.project.cuts[i].time_start >= cut.time_start) break;
                if (!props.project.cuts[i].expand) offset += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
              }

              for (let i = mouseState.cutDown + 1; i < props.project.cuts.length; i++) {
                props.project.cuts[i].time_start += movement;
                props.project.cuts[i].time_end += movement;
              }

              for (let i = 0; i < props.project.blocks.length; i++) {
                if (props.project.blocks[i].time_start > cut.time_start + offset) {
                  props.project.blocks[i].time_start += movement;
                  props.project.blocks[i].time_end += movement;
                }
              }
            } else {
              for (let i = mouseState.cutDown + 1; i < props.project.cuts.length; i++) {
                props.project.cuts[i].time_start -= movement;
                props.project.cuts[i].time_end -= movement;
              }
            }
          }

          props.currentTime.time = props.project.cuts[mouseState.cutDown].time_start;
          props.currentTimeChange(props.currentTime.time);
          draw(canvasRef.current, props, mouseState.cutDown);
        } else {
          let cut = -1;
          if (e.offsetY >= element.height - 68 && e.offsetY <= element.height - 48) {
            let expandTime = 0;
            const margeTime = 8 / pixelPerSecond;
            for (let i = 0; i < props.project.cuts.length; i++) {
              if (currentTime >= props.project.cuts[i].time_start + expandTime && currentTime < props.project.cuts[i].time_end + expandTime) {
                cut = i;
                if (currentTime < props.project.cuts[i].time_start + expandTime + margeTime || currentTime > props.project.cuts[i].time_end + expandTime - margeTime) {
                  element.style.cursor = "ew-resize";
                } else {
                  element.style.cursor = "pointer";
                }

                break;
              }
              if (props.project.cuts[i].expand) {
              } else {
                expandTime += props.project.cuts[i].time_end - props.project.cuts[i].time_start;
              }
            }
          }

          if (cut < 0) element.style.cursor = "default";

          if (props.project.audioFileBuffer && !props.project.audioFileMute && e.offsetY >= element.height - 30 && e.offsetY <= element.height - 16) {
            element.style.cursor = "ew-resize";
          }
        }
      }
    };

    element.addEventListener("mousemove", handleCanvasMouseMove);
    return () => {
      element.removeEventListener("mousemove", handleCanvasMouseMove);
    };
  }, [props, mouseState]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: (props.zoom * 100).toString() + "%",
        height: "250px",
        touchAction: "none",
      }}
    />
  );
}

export default CanvasBlock;
