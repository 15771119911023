import React, { useEffect, useState, useRef } from "react";
import SelectLanguage from "./SelectLanguage";
import { translate } from "../js/Translation";

const SelectVoice = ({ updateAllMode, totalCharNumber, block, isOpen, onGenerate, token, closeHandler, handleVoiceChange }) => {
  const apiAddress = "https://imea-studio.com/";
  const [languages, setLanguages] = useState();
  const [voices, setVoices] = useState();
  const [languageSelected, setLanguageSelected] = useState();
  const [voiceSelected, setVoiceSelected] = useState("");
  const [voicePlaying, setVoicePlaying] = useState();
  const audioRef = useRef();

  useEffect(() => {
    async function getLanguages() {
      const data = await (
        await fetch(apiAddress + "vo-get-languages.php?token=8gs34lxp6w9m082H4q721-45sx", {
          method: "GET",
        })
      ).json();

      setLanguages(data["languages"]);
    }

    getLanguages();
  }, []);

  useEffect(() => {
    async function getVoices(language) {
      if (languageSelected && languages) {
        const lang = languages.find((lang) => {
          return lang.language === languageSelected.language;
        });

        const data = await (await fetch(apiAddress + "vo-get-voices.php?token=8gs34lxp6w9m082H4q721-45sx&language=" + lang.code, { method: "GET" })).json();

        setVoices(data["voices"]);
      }
    }

    getVoices();
  }, [block, languageSelected, languages]);

  useEffect(() => {
    if (block && languages) {
      setVoiceSelected(block.voice_public_name);
      setLanguageSelected(
        languages.find((lang) => {
          return lang.language === block.language_name;
        })
      );
    }
  }, [block, languages]);

  if (!isOpen) return null;

  const playVoiceFile = async (voice) => {
    if (voicePlaying && voicePlaying !== voice) voicePlaying.audioPlaying = false;

    if (voice.audioPlaying) {
      audioRef.current.pause();
      voice.audioPlaying = false;
      voicePlaying.audioPlaying = false;
      setVoicePlaying(null);
    } else {
      await fetch(apiAddress + "vo-download-voice-file.php?token=8gs34lxp6w9m082H4q721-45sx&file_name=" + voice.file_name)
        .then((response) => response.blob())
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          audioRef.current.src = objectURL;
          audioRef.current.play();
          voice.audioPlaying = true;

          setVoicePlaying(voice);
        });
    }
  };

  return (
    <div className="selectvoice">
      <audio
        ref={audioRef}
        onEnded={() => {
          voicePlaying.audioPlaying = false;
          setVoicePlaying(null);
        }}
      />

      <div className="modal-container" onClick={closeHandler}>
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div className="close-btn" onClick={closeHandler}>
            <img alt="close" style={{ width: "30px" }} src="./img/close_white.png" />
          </div>
          <div style={{ height: "20px" }}></div>
          {/* <div className="info-char">{translate("SelectTheVoiceYouWantToUpdateWith")}</div> */}
          <SelectLanguage
            languages={languages}
            languageSelected={languageSelected}
            languageSelectedChange={(e) => {
              if (block) block.language_name = e.language;
              setLanguageSelected(
                languages.find((lang) => {
                  return lang.language === e.language;
                })
              );
            }}
          />

          <div className={voices ? "div-voice" : "hidden"}>
            <div className="div-gender">
              <img alt="female" src="./img/female.png" style={{ width: "80px" }} />
              {voices
                ?.filter((v) => v.gender === "female")
                .map((voice, i) => (
                  <div
                    className="item-voice"
                    key={i}
                    style={{
                      border: voiceSelected === voice.public_name ? "1px solid #fff" : "1px solid transparent",
                    }}
                  >
                    <div
                      onClick={() => {
                        if (block) block.voice_public_name = voice.public_name;
                        setVoiceSelected(voice.public_name);
                        if (handleVoiceChange) handleVoiceChange(voice.public_name, languageSelected);
                        if (!updateAllMode) closeHandler();
                      }}
                    >
                      {voice.public_name}
                    </div>
                    <img
                      alt="male"
                      src={voice.audioPlaying ? "./img/stop_white.png" : "./img/play_white.png"}
                      width={32}
                      onClick={() => {
                        playVoiceFile(voice);
                      }}
                    />
                  </div>
                ))}
            </div>

            <div className="div-gender">
              <img alt="male" src="./img/male.png" width={80} />
              {voices
                ?.filter((v) => v.gender === "male")
                .map((voice, i) => (
                  <div
                    className="item-voice"
                    key={i}
                    style={{
                      border: voiceSelected === voice.public_name ? "1px solid #fff" : "1px solid transparent",
                    }}
                  >
                    <div
                      onClick={() => {
                        if (block) block.voice_public_name = voice.public_name;
                        setVoiceSelected(voice.public_name);
                        if (handleVoiceChange) handleVoiceChange(voice.public_name, languageSelected);
                        if (!updateAllMode) closeHandler();
                      }}
                    >
                      {voice.public_name}
                    </div>
                    <img
                      alt="male"
                      src={voice.audioPlaying ? "./img/stop_white.png" : "./img/play_white.png"}
                      width={32}
                      onClick={() => {
                        playVoiceFile(voice);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className={updateAllMode && voiceSelected !== "" ? "info" : "hidden"}>
            <div className="info-char">{translate("SaveTheProjectBeforeGeneratingTheVoices")}</div>
            <div className="info-char">
              {translate("CharsAvailable")} <span style={{ color: "#fff", fontWeight: "bold" }}>{token?.voiceover_char_credit}</span>
            </div>
            <div className="info-char">
              {translate("AboutChars")} <span style={{ color: "#fff", fontWeight: "bold" }}>{totalCharNumber}</span> {translate("CharsWillBeDeductedAfterTheVoiceoverHasBeenGenerated")}
            </div>
            <button
              className="btn-ok"
              onClick={(e) => {
                onGenerate(voiceSelected, languageSelected);
              }}
            >
              {translate("GENERATEALLVOICES")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectVoice;
